/* eslint-disable import/prefer-default-export */
import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/storage";

require("firebase/auth");
require("firebase/firestore");

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const hostname = window.location.hostname;

hostname === "localhost"
  ? firebase.initializeApp(firebaseConfig)
  : firebase.initializeApp(firebaseConfig);

// var db = firebase.firestore();
// var auth = firebase.auth();
// var storage = firebase.storage();

// if (hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
//   auth.useEmulator("http://localhost:9099");
//   storage.useEmulator("localhost", 9199);
// }

export default firebase;
