import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";

const RequestUpload = () => {
  return (
    <div>
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h5 className="text-color-4 margin-0 weight-normal">
              Capture evidence of competitor products in our store
            </h5>
            <div className="padding-top-6 margin-top-6 border-top-2px">
              <div className="upload">
                <div className="in-upload">You can preview before sending</div>
              </div>
            </div>
          </div>
          <div className="padding-top-6 border-top-2px">
            <Link
              to="/requestUploaded"
              className="button background-color-brand w-button"
            >
              Upload video
            </Link>
            <p className="text-color-2 margin-top-4 margin-bottom-0 text-small">
              Only <strong>mp4</strong> &amp; <strong>mov</strong> files
              supported. File must not be larger than 30mb.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestUpload;
