import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { logOut } from "../../services/auth";
import { clearUserData } from "../../services/Storage";

const Header = ({ fetchScout }) => {
  const [dropdown, setDropdown] = useState(false);

  let history = useHistory();

  async function handleLogout() {
    try {
      await logOut();
      clearUserData();
      history.push("/home");
      window.location.reload();
    } catch (error) {
      toast.error(error.message, {
        autoClose: 10000,
      });
    }
  }

  return (
    <div>
      <ToastContainer />
      <div
        data-collapse="none"
        data-animation="default"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 portrait-padding-x-3 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <Link to="/dashboard">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </Link>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-center w-nav-menu"
            >
              <Link
                to="/cashout/bank-details"
                className="button-secondary button-small margin-right-2 w-button"
              >
                Cash out
              </Link>
              <div
                data-hover="false"
                data-delay="0"
                className="w-dropdown"
                style={dropdown ? { maxWidth: "1280px", zIndex: 901 } : null}
                onClick={() => setDropdown((prevState) => !prevState)}
              >
                <div
                  className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle w--open"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="true"
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon text-color-4"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>

                {/* Mobile Navigation */}
                <nav
                  className={`dropdown box-shadow-medium w-dropdown-list ${
                    dropdown ? "w--open" : null
                  }`}
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <Link
                    to={{
                      pathname: "/profile",
                      state: JSON.stringify(fetchScout),
                    }}
                    className="w-dropdown-link"
                  >
                    Profile
                  </Link>
                  <Link to="/settings" className="w-dropdown-link">
                    Settings
                  </Link>
                  <a
                    href="https://www.versus.africa/scouts-faqs"
                    target="_blank"
                    rel="noreferrer"
                    className="w-dropdown-link"
                  >
                    FAQs
                  </a>
                  <a
                    href="#!"
                    className="w-dropdown-link"
                    onClick={handleLogout}
                  >
                    Log out
                  </a>
                </nav>
              </div>
            </nav>

            <div
              className="margin-left-auto w-nav-button"
              style={{ WebkitUserSelect: "text" }}
              aria-label="menu"
              role="button"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      </div>
    </div>
  );
};

export default Header;
