import { createSlice } from "@reduxjs/toolkit";
import {
  childrenInHousehold,
  houseHoldMonthlyIncome,
  religion,
  employmentStatus,
  industryAffiliation,
  personalMonthlyIncome,
  peopleInHousehold,
  dateOfBirth,
  tribe,
  highestEducationLevel,
  gender,
  maritalStatus,
  sportsFan,
  soccerFan,
  favoriteTeam,
} from "../actions/demographicActions";

export const createChildrenInHousehold = createSlice({
  name: "houseHoldChildren",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [childrenInHousehold.pending]: (state, action) => {
      state.loading = true;
    },
    [childrenInHousehold.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [childrenInHousehold.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});
export const createHouseHoldIncome = createSlice({
  name: "totalHouseHoldIncome",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [houseHoldMonthlyIncome.pending]: (state, action) => {
      state.loading = true;
    },
    [houseHoldMonthlyIncome.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [houseHoldMonthlyIncome.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createReligion = createSlice({
  name: "religiousChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [religion.pending]: (state, action) => {
      state.loading = true;
    },
    [religion.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [religion.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createEmploymentStatus = createSlice({
  name: "employmentStatusChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [employmentStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [employmentStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [employmentStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createIndustryAffiliation = createSlice({
  name: "industryAffiliationChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [industryAffiliation.pending]: (state, action) => {
      state.loading = true;
    },
    [industryAffiliation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [industryAffiliation.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createPersonalMonthlyIncome = createSlice({
  name: "householdPersonalMonthlyIncome",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [personalMonthlyIncome.pending]: (state, action) => {
      state.loading = true;
    },
    [personalMonthlyIncome.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [personalMonthlyIncome.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createPeopleInHousehold = createSlice({
  name: "totalNumberOfPeopleInHouseHold",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [peopleInHousehold.pending]: (state, action) => {
      state.loading = true;
    },
    [peopleInHousehold.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [peopleInHousehold.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createDateOfBirth = createSlice({
  name: "birthDay",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [dateOfBirth.pending]: (state, action) => {
      state.loading = true;
    },
    [dateOfBirth.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [dateOfBirth.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createTribe = createSlice({
  name: "tribeSelection",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [tribe.pending]: (state, action) => {
      state.loading = true;
    },
    [tribe.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [tribe.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createHighestEducationLevel = createSlice({
  name: "educationLevel",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [highestEducationLevel.pending]: (state, action) => {
      state.loading = true;
    },
    [highestEducationLevel.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [highestEducationLevel.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createGender = createSlice({
  name: "genderChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [gender.pending]: (state, action) => {
      state.loading = true;
    },
    [gender.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [gender.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createMaritalStatus = createSlice({
  name: "maritalChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [maritalStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [maritalStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [maritalStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createSportsFan = createSlice({
  name: "sportsFanChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [sportsFan.pending]: (state, action) => {
      state.loading = true;
    },
    [sportsFan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [sportsFan.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createSoccerFan = createSlice({
  name: "soccerFanChoice",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [soccerFan.pending]: (state, action) => {
      state.loading = true;
    },
    [soccerFan.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [soccerFan.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createFavoriteTeam = createSlice({
  name: "selectFavoriteTeam",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [favoriteTeam.pending]: (state, action) => {
      state.loading = true;
    },
    [favoriteTeam.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [favoriteTeam.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});
