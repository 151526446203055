import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";

const PendingResponse = ({ location }) => {
  const { x: res } = location.state || {};
  const [mediaUrls] = useState(JSON.parse(res.mediaUrls));

  return (
    <div>
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <div className="flex-row margin-bottom-6">
              <p className="pill">
                {res.status === "pending"
                  ? "PENDING"
                  : res.status === "rejected"
                  ? "REJECTED"
                  : res.status === "approved"
                  ? "Approved"
                  : ""}
              </p>
            </div>
            <h5 className="text-color-4 margin-0 weight-normal">
              <strong>{res?.requestName}</strong>
            </h5>
            <p className="margin-top-2 text-color-2">
              Worth{" "}
              <span className="text-color-1">{res?.versusCreditsAccruing}</span>{" "}
              credits
            </p>

            {res?.mediaType === "photo" && (
              <div>
                <div className="grid-3-columns margin-top-6">
                  {mediaUrls.map((x) => (
                    <img
                      src={x}
                      alt="Media"
                      key={x}
                      style={{
                        height: "160px",
                        objectFit: "cover",
                        width: "160px",
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {res?.mediaType === "audio" && (
              <div>
                <div
                  style={{ marginLeft: "21%" }}
                  className="grid-3-columns margin-top-6"
                >
                  {mediaUrls.map((x) => (
                    <audio key={x} controls>
                      <source src={x} />
                      Your browser does not support the audio element.
                    </audio>
                  ))}
                </div>
              </div>
            )}

            {res?.mediaType === "video" && (
              <div>
                <div className="padding-top-6 margin-top-6 border-top-2px">
                  {mediaUrls.map((x) => (
                    <video
                      key={x}
                      className="videoPreview"
                      src={x}
                      controls
                      autoPlay
                      loop
                      muted
                      style={{
                        objectFit: "cover",
                        height: "600px",
                        width: "100%",
                      }}
                    ></video>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingResponse;
