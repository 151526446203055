import React, { useState, useEffect } from "react";
import {
  getBanks,
  verifyAccountNumber,
  getScouts,
} from "../../actions/surveyActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../Loader/loader";
import CashOutError from "./CashOutError";

const CashOutBank = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [bankDetails, setBankDetails] = useState("");
  const [query, setQuery] = useState("");
  const [accountNumber, setAccountNumber] = useState([]);
  const [error, setError] = useState("");
  const [, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onClickDropDown = () => setShowDropdown(!showDropdown);
  const fetchBanks = useSelector((state) => state.fetchBanks);
  const fetchScout = useSelector((state) => state.fetchScout);
  const {
    data: { data: bankList },
  } = fetchBanks;

  const { data: scoutData } = fetchScout;

  let history = useHistory();

  //Bank Search Filter
  const filterBank = bankList?.banks?.filter((bank) => {
    return bank.name
      .toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase());
  });

  const accountNumberValue = (e) => {
    const limit = 10;
    setAccountNumber(e.target.value.slice(0, limit));

    if (bankDetails?.name !== undefined && e.target.value.length === 10) {
    }
  };

  useEffect(() => {
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    dispatch(getScouts());

    dispatch(getBanks({ authstring: string }));
  }, [dispatch]);

  const confirmAccount = () => {
    setLoading(true);
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
    dispatch(
      verifyAccountNumber({
        phoneNumber: scoutData?.data?.phoneNumber,
        accountNumber: accountNumber,
        bankCode: bankDetails?.code,
        bankName: bankDetails?.name,
        authstring: string,
      })
    )
      .unwrap()
      .then((payload) => {
        setLoading(true);
        setSuccess(payload);
        if (payload) {
          history.push({
            pathname: "/cashout/select-amount",
            state: JSON.stringify(scoutData),
            accountDetails: accountNumber,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <div>
      {scoutData?.data?.versusCredits_amount >= 33 ? (
        <>
          <div
            data-collapse="small"
            data-animation="default"
            data-duration="400"
            role="banner"
            className="background-color-3 padding-top-4 w-nav"
          >
            <div className="container-1280 w-container">
              <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
                <a href="!#" className="w-nav-brand">
                  <img
                    src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                    loading="lazy"
                    alt=""
                    className="versus-logo"
                  />
                </a>
                <nav
                  role="navigation"
                  className="margin-left-auto flex-align-center w-nav-menu"
                >
                  <a
                    href="/sign-up"
                    className="button-secondary button-small disabled margin-right-2 w-button"
                  >
                    Cash out
                  </a>
                  <div
                    data-hover=""
                    data-delay="0"
                    className="w-dropdown"
                    style={{ maxWidth: "1280px" }}
                  >
                    <div
                      className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle"
                      id="w-dropdown-toggle-0"
                      aria-controls="w-dropdown-list-0"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user icon text-color-4"
                        src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                        loading="lazy"
                        alt=""
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </div>
                    <nav
                      className="dropdown right box-shadow-medium w-dropdown-list"
                      id="w-dropdown-list-0"
                      aria-labelledby="w-dropdown-toggle-0"
                    >
                      <a href="/" className="w-dropdown-link">
                        Settings
                      </a>
                      <a href="/" className="w-dropdown-link">
                        Log out
                      </a>
                    </nav>
                  </div>
                </nav>
                <div
                  className="margin-left-auto w-nav-button"
                  style={{ WebkitUserSelect: "text" }}
                  aria-label="menu"
                  role="button"
                  aria-controls="w-nav-overlay-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                >
                  <div className="w-icon-nav-menu"></div>
                </div>
              </div>
            </div>
            <div
              className="w-nav-overlay"
              data-wf-ignore=""
              id="w-nav-overlay-0"
            ></div>
          </div>
          <div className="section-in-base background-color-3">
            <div className="container-1280">
              <p className="text-2xl weight-bold">
                0{" "}
                <span className="text-medium weight-normal">scout credits</span>
              </p>
            </div>
          </div>
          <div className="background-color-white">
            <div className="container-1280">
              <div
                data-duration-in="300"
                data-duration-out="100"
                className="w-tabs"
              >
                <div
                  className="page-tabs margin-bottom-6 w-tab-menu"
                  role="tablist"
                >
                  <a
                    data-w-tab="Tab 1"
                    className="tab w-inline-block w-tab-link w--current"
                    id="w-tabs-0-data-w-tab-0"
                    href="#w-tabs-0-data-w-pane-0"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-0"
                    aria-selected="true"
                  >
                    <div className="text-color-4">1 Survey</div>
                  </a>
                  <a
                    data-w-tab="Tab 2"
                    className="tab w-inline-block w-tab-link"
                    tabIndex="-1"
                    id="w-tabs-0-data-w-tab-1"
                    href="#w-tabs-0-data-w-pane-1"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-1"
                    aria-selected="false"
                  >
                    <div>
                      <span>0</span> Requests
                    </div>
                  </a>
                </div>
                <div className="tabs-content w-tab-content">
                  <div
                    data-w-tab="Tab 1"
                    className="grid-3-columns w-tab-pane w--tab-active"
                    id="w-tabs-0-data-w-pane-0"
                    role="tabpanel"
                    aria-labelledby="w-tabs-0-data-w-tab-0"
                  >
                    <div className="radius-large background-color-white box-shadow-large padding-6 border-1px">
                      <h4>
                        Earn 9 starting credits with the demographics survey!
                      </h4>
                      <div className="flex-align-center margin-top-6 flex-space-between">
                        <div className="flex-align-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-message-square icon text-color-3 margin-right-1"
                            src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                            loading="lazy"
                            alt=""
                          >
                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                          </svg>
                          <div className="text-color-2">9</div>
                        </div>
                        <a
                          href="/sign-up"
                          className="button button-small w-button"
                        >
                          Start
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right icon margin-left-1"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 2"
                    className="w-tab-pane"
                    id="w-tabs-0-data-w-pane-1"
                    role="tabpanel"
                    aria-labelledby="w-tabs-0-data-w-tab-1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal">
            <Link
              to="/dashboard"
              className="modal-bg w-inline-block"
              style={{ backdropFilter: "blur(16px)" }}
            ></Link>
            <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
              <div className="modal-section">
                <div>
                  <h3 className="text-color-4 margin-0">Cash out</h3>
                </div>
                <div className="margin-top-6 w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                  >
                    <label htmlFor="name">What are your bank details?</label>
                    <div
                      data-hover=""
                      data-delay="0"
                      className="form-input width-full w-dropdown"
                      style={{ zIndex: "901" }}
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle w--open"
                        id="w-dropdown-toggle-1"
                        aria-controls="w-dropdown-list-1"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        onClick={onClickDropDown}
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div>
                          {bankDetails?.name === undefined
                            ? "Select bank"
                            : bankDetails?.name}
                        </div>
                      </div>
                      {showDropdown ? (
                        <nav
                          className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w--open"
                          id="w-dropdown-list-1"
                          aria-labelledby="w-dropdown-toggle-1"
                        >
                          <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                            <div className="relative">
                              <input
                                className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                                maxLength="256"
                                name="Phone-2"
                                data-name="Phone 2"
                                placeholder="Search banks"
                                id="Phone-2"
                                required=""
                                value={query}
                                style={{ color: "black" }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-search absolute-top-left icon margin-4"
                                src="https://uploads-ssl.webflow.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                            </div>
                          </div>
                          {filterBank?.map((bank) => (
                            <a
                              href="#!"
                              className="padding-4 w-dropdown-link"
                              key={bank.id}
                              onClick={() => {
                                setBankDetails(bank);
                                setShowDropdown(false);
                              }}
                            >
                              {bank.name}
                            </a>
                          ))}
                        </nav>
                      ) : null}
                    </div>

                    <input
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="number"
                      className="form-input margin-top-2 w-input"
                      name="Bank"
                      data-name="Bank"
                      placeholder="Account number"
                      id="Bank"
                      maxLength="10"
                      onChange={accountNumberValue}
                    />
                    <p>
                      Don’t have a bank account?
                      <Link
                        className="text-color-1 underline"
                        to={{
                          pathname: "/cashout/paga",
                          state: JSON.stringify(scoutData),
                        }}
                      >
                        &nbsp;Skip
                      </Link>
                    </p>
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="margin-top-6 padding-top-6 border-top-2px flex-space-between flex-align-baseline">
                  <p>
                    <span className="text-color-1">
                      {scoutData?.data?.versusCredits_amount}
                    </span>{" "}
                    <span className="text-color-2">Credits Available</span>
                  </p>
                  <button
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                    onClick={confirmAccount}
                  >
                    {(loading && <Loader />) || (
                      <span style={{ marginLeft: "5px" }}>Next</span>
                    )}
                  </button>
                </div>
              </div>
              <span
                style={{ color: "#FFCC00", float: "right" }}
                className=" margin-top-6"
              >
                {error}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <CashOutError />
        </>
      )}
    </div>
  );
};

export default CashOutBank;
