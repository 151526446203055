import React from "react";
import { Link } from "react-router-dom";

const SurveyEnds = () => {
  return (
    <div>
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <a href="#!" className="w-nav-brand">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-center w-nav-menu"
            >
              <a
                href="/sign-up"
                className="button-secondary button-small disabled margin-right-2 w-button"
              >
                Cash out
              </a>
              <div
                data-hover=""
                data-delay="0"
                className="w-dropdown"
                style={{ maxWidth: "1280px" }}
              >
                <div
                  className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon text-color-4"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <nav
                  className="dropdown right box-shadow-medium w-dropdown-list"
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Settings
                  </a>
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Log out
                  </a>
                </nav>
              </div>
            </nav>
            <div
              className="margin-left-auto w-nav-button"
              style={{ WebkitUserSelect: "text" }}
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      </div>
      <div className="section-in-base background-color-3">
        <div className="container-1280">
          <p className="text-2xl weight-bold">
            0 <span className="text-medium weight-normal">scout credits</span>
          </p>
        </div>
      </div>
      <div className="background-color-white">
        <div className="container-1280">
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="w-tabs"
          >
            <div
              className="page-tabs margin-bottom-6 w-tab-menu"
              role="tablist"
            >
              <a
                data-w-tab="Tab 1"
                className="tab w-inline-block w-tab-link w--current"
                id="w-tabs-0-data-w-tab-0"
                href="#w-tabs-0-data-w-pane-0"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-0"
                aria-selected="true"
              >
                <div className="text-color-4">1 Survey</div>
              </a>
              <a
                data-w-tab="Tab 2"
                className="tab w-inline-block w-tab-link"
                tabIndex="-1"
                id="w-tabs-0-data-w-tab-1"
                href="#w-tabs-0-data-w-pane-1"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-1"
                aria-selected="false"
              >
                <div>
                  <span>0</span> Requests
                </div>
              </a>
            </div>
            <div className="tabs-content w-tab-content">
              <div
                data-w-tab="Tab 1"
                className="grid-3-columns w-tab-pane w--tab-active"
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
              >
                <div className="radius-large background-color-white box-shadow-large padding-6 border-1px">
                  <h4>Earn 9 starting credits with the demographics survey!</h4>
                  <div className="flex-align-center margin-top-6 flex-space-between">
                    <div className="flex-align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-message-square icon text-color-3 margin-right-1"
                        src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                        loading="lazy"
                        alt=""
                      >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      <div className="text-color-2">9</div>
                    </div>
                    <a href="/sign-up" className="button button-small w-button">
                      Start{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right icon margin-left-1"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-w-tab="Tab 2"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h5 className="text-color-4 margin-0 weight-normal">
              Demographics Survey
            </h5>
            <div className="flex-justify-center neg-margin margin-top-3">
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
              <div className="progress-bar expand active"></div>
            </div>
            <div className="margin-top-12">
              <h3 className="text-color-4">
                🎉
                <br />
                You’ve completed the Demographics Survey!
              </h3>
              <p className="text-medium">
                You’ve earned{" "}
                <strong className="text-color-1">12 Scout Credits!</strong>
              </p>
            </div>
            <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
              <Link
                to="/dashboard-demographic-notification"
                className="button-secondary w-button"
              >
                Finish
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyEnds;
