import React, { useState, useEffect } from "react";
import Honour from "./FormSteps/Honour";
import Location from "./FormSteps/Location";
import Orientation from "./FormSteps/Orientation";
import Name from "./FormSteps/Name";
import options from "../../countries";
import axios from "../../services/axios";
import { APIS } from "../../services/config";
import BankDetails from "./FormSteps/BankDetails";

const FormStepsLogic = () => {
  // Steps
  const [activeStep, setActiveStep] = useState(0);

  //partners
  const [partners, setPartners] = useState([]);

  // Form Values
  const [onboardingSteps, setOnboardingSteps] = useState({
    firstName: "",
    lastName: "",
  });

  const [value, setValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [accountNumberValue, setAccountNumberValue] = useState("");
  const [bankNameValue, setBankNameValue] = useState("");
  const [localGovernmentValue, setLocalGovernmentValue] = useState("");
  const [referrerValue, setReferrerValue] = useState("");

  // Navigates to the Next Step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Navigates to the Previous Step
  const handleBack = () => {
    window.location.reload();
  };

  //Handle Form Value State As It Changes
  const handleChange = (input) => (e) => {
    setOnboardingSteps({ ...onboardingSteps, [input]: e.target.value });
  };

  //Get Partners
  useEffect(() => {
    const {
      baseUrl,
      getPartners: { path },
    } = APIS;
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    const payload = { authstring: string };
    async function fetchPartners() {
      const request = await axios.post(baseUrl + path, payload);
      setPartners(request);
    }
    fetchPartners();
  }, []);

  return (
    <>
      {activeStep === 0 && (
        <Name
          handleNext={handleNext}
          activeStep={activeStep}
          values={onboardingSteps}
          handleChange={handleChange}
        />
      )}
      {activeStep === 1 && (
        <BankDetails
          partners={partners}
          handleNext={handleNext}
          handleBack={handleBack}
          options={options}
          values={onboardingSteps}
          prompt="Country"
          statePrompt="State"
          localGovtPrompt="Local Government Area"
          referrerPrompt="Referrer"
          handleChange={handleChange}
          value={value}
          localGovernmentValue={localGovernmentValue}
          bankNameValue={bankNameValue}
          accountNumberValue={accountNumberValue}
          stateValue={stateValue}
          referrerValue={referrerValue}
          onChange={(val) => setValue(val)}
          onStateChange={(val) => setStateValue(val)}
          onBankValueChange={(val) => setBankNameValue(val)}
          onAccountNumberChange={(val) => setAccountNumberValue(val)}
          onReferrerChange={(val) => setReferrerValue(val)}
        />
      )}
      {activeStep === 2 && (
        <Location
          partners={partners}
          handleNext={handleNext}
          handleBack={handleBack}
          options={options}
          values={onboardingSteps}
          prompt="Country"
          statePrompt="State"
          localGovtPrompt="Local Government Area"
          referrerPrompt="Referrer"
          handleChange={handleChange}
          value={value}
          localGovernmentValue={localGovernmentValue}
          bankNameValue={bankNameValue}
          accountNumberValue={accountNumberValue}
          stateValue={stateValue}
          referrerValue={referrerValue}
          onChange={(val) => setValue(val)}
          onStateChange={(val) => setStateValue(val)}
          onLocalGovernmentChange={(val) => setLocalGovernmentValue(val)}
          onBankValueChange={(val) => setBankNameValue(val)}
          onAccountNumberChange={(val) => setAccountNumberValue(val)}
          onReferrerChange={(val) => setReferrerValue(val)}
        />
      )}
      {activeStep === 3 && (
        <Honour
          handleNext={handleNext}
          handleBack={handleBack}
          options={options}
          values={onboardingSteps}
          prompt="Country"
          statePrompt="State"
          localGovtPrompt="Local Government Area"
          referrerPrompt="Referrer"
          handleChange={handleChange}
          value={value}
          localGovernmentValue={localGovernmentValue}
          setBankNameValue={setBankNameValue}
          setAccountNumberValue={setAccountNumberValue}
          stateValue={stateValue}
          referrerValue={referrerValue}
          onChange={(val) => setValue(val)}
          onStateChange={(val) => setStateValue(val)}
          onLocalGovernmentChange={(val) => setLocalGovernmentValue(val)}
          onBankValueChange={(val) => setBankNameValue(val)}
          onAccountNumberChange={(val) => setAccountNumberValue(val)}
          onReferrerChange={(val) => setReferrerValue(val)}
        />
      )}
      {activeStep === 4 && <Orientation values={onboardingSteps} />}
    </>
  );
};

export default FormStepsLogic;
