import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard";
import firebase from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import "firebase/storage";
import { addMultiMediaRequest, getScouts } from "../../actions/surveyActions";
import { useDispatch, useSelector } from "react-redux";

const RequestAudio = ({ location }) => {
  const [file, setFile] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const fetchScout = useSelector((state) => state.fetchScout);
  const [audioDuration, setAudioDuration] = useState();
  const [error, setError] = useState("");

  let history = useHistory();

  const { data: scoutData } = fetchScout;

  const dispatch = useDispatch();
  const { request } = location.state || {};

  //Audio Duration Helper Function
  async function getDuration(file) {
    const url = URL.createObjectURL(file);
    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload = "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function () {
        resolve(audio.duration);
      };
    });
  }

  const uploadAudio = async (e) => {
    let src = URL.createObjectURL(e.target.files[0]);
    const duration = await getDuration(e.target.files[0]);
    setAudioDuration(duration / 60);
    setFile(src);

    //Upload Audio To Firebase Storage
    if (duration / 60 <= 0.5) {
      const id = uuidv4();
      const audioRef = firebase
        .storage()
        .ref("requests/requestAudio")
        .child(id);
      await audioRef.put(e.target.files[0]);
      audioRef.getDownloadURL().then((url) => {
        setAudioUrl({ id, url });
      });
    }
  };

  const resetFile = async (e) => {
    e.preventDefault();
    if (audioDuration <= 0.5) {
      const audioRef = await firebase
        .storage()
        .ref("requests/requestAudio")
        .child(audioUrl.id);
      audioRef.delete();
    }
    setFile("");
    setAudioUrl("");
  };

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch]);

  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      addMultiMediaRequest({
        requestRef: request?.requestRef,
        scoutRef: scoutData?.data?.phoneNumber,
        mediaType: request?.mediaType,
        mediaUrls: [audioUrl.url],
        authstring:
          window.location.hostname ===
            "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
          window.location.hostname === "localhost"
            ? "55ad4986-b519-4e44-ab0b-890527299af6"
            : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
      })
    )
      .unwrap()
      .then((payload) => {
        if (payload.status === 200) {
          history.push({
            pathname: "/request-submit-success",
            state: {
              request,
            },
          });
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div>
      <Dashboard />
      {file === "" ? (
        <div className="modal">
          <Link
            to="/dashboard"
            className="modal-bg w-inline-block"
            style={{ backdropFilter: "blur(16px)" }}
          ></Link>
          <div className="w-embed"></div>
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                <strong> {request?.requestName}</strong>
              </h5>
              <div className="padding-top-6 margin-top-6 border-top-2px">
                <div className="upload audio">
                  <div className="in-upload audio">
                    You can preview before sending
                  </div>
                </div>
              </div>
            </div>

            <div className="padding-top-6 border-top-2px">
              <label
                htmlFor="formId"
                className="button background-color-brand w-button"
              >
                Upload Audio
                <input
                  name=""
                  type="file"
                  id="formId"
                  hidden
                  accept=".mp3,audio/*"
                  onChange={uploadAudio}
                />
              </label>
              <p className="text-color-2 margin-top-4 margin-bottom-0 text-small">
                Only <strong>mp3</strong>
                <strong></strong> files supported. File must not be longer than
                30 seconds.
                <br />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal">
          <Link
            to="/dashboard"
            className="modal-bg w-inline-block"
            style={{ backdropFilter: "blur(16px)" }}
          ></Link>
          <div className="w-embed"></div>
          <form>
            <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
              <div className="modal-section">
                <h5 className="text-color-4 margin-0 weight-normal">
                  <strong>Arsenal Fan Chant in your Local Language</strong>
                </h5>
                <div className="padding-top-6 margin-top-6 border-top-2px">
                  <div className="upload recording audio done">
                    <div className="w-embed">
                      <audio controls>
                        <source src={file} />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                    <button
                      className="button button-small background-color-red w-button"
                      onClick={resetFile}
                      disabled={audioUrl === "" && audioDuration <= 0.5}
                      style={{ marginLeft: "30px" }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div className="padding-top-6 border-top-2px flex-space-between margin-top-6 flex-align-center portrait-flex-column portrait-flex-align-start">
                <p className="text-color-2 margin-top-4">
                  Only <strong>mp3</strong>
                  <strong></strong> files supported <br />
                  {audioDuration > 0.5 ? (
                    <span style={{ color: " #FFCC00" }}>
                      Audio must not be longer than 30 seconds, please upload
                      another Audio.
                    </span>
                  ) : null}
                  <span style={{ color: " #FFCC00" }}>{error}</span>
                </p>
                <button
                  className="button-secondary w-button"
                  disabled={audioDuration > 0.5 || audioUrl === ""}
                  onClick={onSubmit}
                >
                  {audioUrl !== "" ? "Submit" : "Uploading"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default RequestAudio;
