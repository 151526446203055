import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIS } from "../services/config";
// import { getUser } from "../services/Storage";

export const childrenInHousehold = createAsyncThunk(
  "childrenInHousehold",
  async (
    { authtoken, scoutRef, children_in_household },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateChildrenInHouseHold: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        children_in_household,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const houseHoldMonthlyIncome = createAsyncThunk(
  "houseHoldMonthlyIncome",
  async (
    { authtoken, scoutRef, household_monthly_income },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateHouseHoldMonthlyIncome: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        household_monthly_income,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const religion = createAsyncThunk(
  "religion",
  async ({ authtoken, scoutRef, religion }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateReligion: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        religion,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const employmentStatus = createAsyncThunk(
  "employmentStatus",
  async (
    { authtoken, scoutRef, employment_status },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateEmploymentStatus: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        employment_status,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const industryAffiliation = createAsyncThunk(
  "industryAffiliation",
  async ({ authtoken, scoutRef, interests }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateIndustryAffiliation: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        interests,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const personalMonthlyIncome = createAsyncThunk(
  "personalMonthlyIncome",
  async (
    { authtoken, scoutRef, personal_monthly_income },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updatePersonalMonthlyIncome: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        personal_monthly_income,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const peopleInHousehold = createAsyncThunk(
  "peopleInHousehold",
  async (
    { authtoken, scoutRef, people_in_household },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updatePeopleInHousehold: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        people_in_household,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const dateOfBirth = createAsyncThunk(
  "dateOfBirth",
  async ({ authtoken, scoutRef, dob }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateDob: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        dob,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const tribe = createAsyncThunk(
  "tribe",
  async ({ authtoken, scoutRef, tribe }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateTribe: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        tribe,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const highestEducationLevel = createAsyncThunk(
  "highestEducationLevel",
  async (
    { authtoken, scoutRef, highest_education_level },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateHighestEducationLevel: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        highest_education_level,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const gender = createAsyncThunk(
  "gender",
  async ({ authtoken, scoutRef, gender }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateGender: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        gender,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const maritalStatus = createAsyncThunk(
  "maritalStatus",
  async (
    { authtoken, scoutRef, marital_status },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateMaritalStatus: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        marital_status,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sportsFan = createAsyncThunk(
  "sportsFan",
  async ({ authtoken, scoutRef, sportsFan }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateSportsFan: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        sportsFan,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const soccerFan = createAsyncThunk(
  "soccerFan",
  async ({ authtoken, scoutRef, soccerFan }, { getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateSoccerFan: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        soccerFan,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const favoriteTeam = createAsyncThunk(
  "favoriteTeam",
  async (
    { authtoken, scoutRef, favorite_team_id },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        updateFavoriteTeam: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        scoutRef,
        favorite_team_id,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
