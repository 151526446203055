import React from "react";
import { Link } from "react-router-dom";

const CashOutError = () => {
  return (
    <div className="modal">
      <Link to="/dashboard" className="modal-bg w-inline-block"></Link>
      <div className="w-embed"></div>
      <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl padding-12">
        <div className="modal-section flex-column flex-align-center">
          <div>
            <h3 className="text-color-4 margin-0 text-align-center">
              🚨🚨🚨
              <br />
              Not enough credits!
            </h3>
            <p className="text-medium text-align-center margin-6">
              You need at least 33 credits to cash out. Complete more surveys to
              earn more credits. <br />
              <br />
              Be sure to turn on notifications to be alerted when a new survey
              is available for you.
            </p>
          </div>
          <Link to="/dashboard" className="button-secondary w-button">
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CashOutError;
