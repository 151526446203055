import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import { useIntercom } from "react-use-intercom";

const Settings = () => {
  const [checked, setChecked] = useState(
    localStorage.getItem("notify") === "true"
  );

  const { show } = useIntercom();

  // const [languageDropdown, setLanguageDropdown] = useState(false);

  // const onClickLanguage = () => setLanguageDropdown(!languageDropdown);

  // Set toggle state to local storage
  localStorage.setItem("notify", checked);

  return (
    <div>
      <Dashboard />

      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <div>
              <h3 className="text-color-4 margin-0">Settings</h3>
            </div>
            <div className="margin-top-6 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                aria-label="Email Form"
              >
                <div className="margin-top-6 padding-top-6 border-top-2px">
                  <h6 className="text-color-2">Permissions</h6>
                  <label className="w-checkbox flex-space-between padding-0 flex-row-reversed flex-align-center">
                    <div
                      className={
                        checked === false
                          ? "w-checkbox-input w-checkbox-input--inputType-custom toggle"
                          : "w-checkbox-input w-checkbox-input--inputType-custom toggle w--redirected-checked"
                      }
                      onClick={() => setChecked(!checked)}
                    ></div>
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      data-name="Checkbox"
                      style={{
                        opacity: "0",
                        position: "absolute",
                        zIndex: "-1",
                      }}
                    />
                    <span className="expand w-form-label">Notifications</span>
                  </label>
                </div>

                {/* <div className="margin-top-6 padding-top-6 border-top-2px"><h6 className="text-color-2">Language</h6>
                  <div
                    data-hover="false"
                    data-delay="0"
                    className="form-input width-full w-dropdown">
                    <div
                      className="text-color-4 padding-0 width-full w-dropdown-toggle" id="w-dropdown-toggle-1"
                      aria-controls="w-dropdown-list-1"
                      aria-haspopup="menu"
                      aria-expanded="false"
                      role="button"
                      onClick={onClickLanguage}
                    >
                      <div className="margin-0 margin-top-1 w-icon-dropdown-toggle" aria-hidden="true">
                      </div>
                      <div>English</div>
                    </div>
                    {languageDropdown ? (
                      <nav
                        className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w-dropdown-list w--open" id="w-dropdown-list-1"
                        aria-labelledby="w-dropdown-toggle-1">
                        <div
                          className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                          <div className="relative">
                            <input
                              type="tel"
                              className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input" maxlength="256"
                              name="Phone-2"
                              data-name="Phone 2"
                              placeholder="Search languages"
                              id="Phone-2"
                              required="" />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-search absolute-top-left icon margin-4"
                              src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                              loading="lazy"
                              alt="">
                              <circle cx="11" cy="11" r="8">
                              </circle>
                              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                          </div>
                        </div>
                        <a href="#!" className="padding-4 w-dropdown-link">English</a>
                        <a href="#!" className="padding-4 w-dropdown-link">Pidgin</a>
                        <a href="#!" className="padding-4 w-dropdown-link">Yoruba</a>
                        <a href="#!" className="padding-4 w-dropdown-link">Hausa</a>
                      </nav>
                    ) : null}

                  </div>
                </div> */}
                <div className="margin-top-6 padding-top-6 border-top-2px">
                  <h6 className="text-color-2">MISC</h6>
                  <a
                    href="https://doc.versus.africa/versus/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="form-button w-button"
                  >
                    Read Privacy Policy
                  </a>
                  <a
                    href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
                    target="_blank"
                    rel="noreferrer"
                    className="form-button w-button"
                  >
                    Terms and Conditions
                  </a>
                  <a href="#!" onClick={show} className="form-button w-button">
                    Send feedback, ask for help
                  </a>
                </div>
              </form>
              <div
                className="w-form-done"
                role="region"
                aria-label="Email Form success"
              >
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div
                className="w-form-fail"
                role="region"
                aria-label="Email Form failure"
              >
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="margin-top-6 padding-top-6 border-top-2px flex-justify-end flex-space-between">
              <Link
                to="/dashboard"
                className="button-secondary subtle w-button"
              >
                Cancel
              </Link>
              <a href="/dashboard" className="button-secondary w-button">
                Save settings
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
