import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";

const RequestUploaded = () => {
  return (
    <div>
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>

        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h5 className="text-color-4 margin-0 weight-normal">
              Capture evidence of competitor products in our store
            </h5>
            <div className="padding-top-6 margin-top-6 border-top-2px">
              <div className="upload recording">
                <a
                  href="#!"
                  className="button background-color-brand absolute-bottom-left margin-6 padding-4 background-color-red box-shadow-medium w-button"
                >
                  <span data-feater="trash-2" className="icon">
                    Delete
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="padding-top-6 border-top-2px flex-space-between margin-top-6 flex-align-center portrait-flex-column portrait-flex-align-start">
            <p className="text-color-2 margin-top-4">
              Only <strong>mp4</strong> &amp; <strong>mov</strong> files
              supported <br />
              File must not be larger than 30mb
            </p>
            <Link
              to="/request-submit-success"
              className="button-secondary w-button"
            >
              Submit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestUploaded;
