import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "../../services/firebase";
import Dashboard from "./Dashboard";
import { useHistory } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { addMultiMediaRequest, getScouts } from "../../actions/surveyActions";

const RequestPhoto = ({ location }) => {
  const { request } = location.state || {};
  const [active, setActive] = useState(false);
  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState([]);
  const [photoIndex, setCurrentPhotoIndex] = useState();
  const [urls, setUrls] = useState([]);
  const [error, setError] = useState("");

  let history = useHistory();

  const fetchScout = useSelector((state) => state.fetchScout);

  const { data: scoutData } = fetchScout;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch, preview]);

  const handleChange = (e) => {
    const MAX_LENGTH = 6;

    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }

    if (images.length > 5) {
      e.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }

    if (images.length + Array.from(e.target.files).length > 6) {
      e.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }

    if (!e.target.files || e.target.files.length === 0) {
      setImages([]);
    }

    for (let i = 0; i < e.target.files.length; i++) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      imageCompression(e.target.files[i], options)
        .then(function (compressedFile) {
          const newImage = compressedFile;
          let src = URL.createObjectURL(e.target.files[i]);
          newImage["id"] = Math.random();

          setImages((prevState) => [...prevState, newImage]);
          setPreview((prevState) => [...prevState, src]);
        })
        .catch(function (error) {
          console.log(error.message);
        });
    }
  };
  function setClassClicked(image, index) {
    setCurrentPhotoIndex(index);
    setActive(image);
  }

  //Delete Selected Images
  function deSelectImages() {
    setImages(images.filter((e, i) => i !== photoIndex));
    setPreview(preview.filter((e, i) => i !== photoIndex));

    setClassClicked();
    setActive(false);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const promises = [];
    images.map((image) => {
      const photoRef = firebase
        .storage()
        .ref(`requests/${image.name}`)
        .put(image);
      promises.push(photoRef);
      photoRef.on(
        "state_changed",
        (snapshot) => {
          return snapshot;
        },
        (error) => {
          console.log(error);
        },
        async () => {
          const storage = firebase.storage();
          await storage
            .ref("requests")
            .child(image.name)
            .getDownloadURL()
            .then((result) => {
              setUrls((prevState) => [...prevState, result]);
            });
        }
      );
      return image;
    });

    Promise.all(promises)
      .then(() => {
        console.log(urls);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (urls.length > 0 && images.length > 0 && urls.length === images.length) {
    dispatch(
      addMultiMediaRequest({
        requestRef: request?.requestRef,
        scoutRef: scoutData?.data?.phoneNumber,
        mediaType: request?.mediaType,
        mediaUrls: urls,
        authstring:
          window.location.hostname ===
            "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
          window.location.hostname === "localhost"
            ? "55ad4986-b519-4e44-ab0b-890527299af6"
            : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
      })
    )
      .unwrap()
      .then((payload) => {
        if (payload.status === 200) {
          history.push({
            pathname: "/request-submit-success",
            state: {
              request,
            },
          });
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  return (
    <div>
      <Dashboard />

      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h5 className="text-color-4 margin-0 weight-normal">
              <strong>{request?.requestName}</strong>
            </h5>
            <div className="padding-top-6 margin-top-6 border-top-2px">
              <div
                className="upload recording"
                role="img"
                style={{
                  backgroundImage: `url(${
                    active === false ? preview[0] : active
                  }`,
                  objectFit: "contain",
                }}
              >
                {/* <button
                  className="button background-color-brand absolute-bottom-left margin-6 padding-4 background-color-red box-shadow-medium w-button"

                >
                  <span data-feater="trash-2" className="icon">
                    Delete
                  </span>
                </button> */}
                {/*
                <label
                  htmlFor="formId"
                  className="button background-color-brand absolute-bottom-right margin-6 background-color-white box-shadow-medium w-button"
                >
                  Uploader
                  <input
                    name=""
                    type="file"
                    id="formId"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={handleChange}
                  />
                </label> */}
              </div>
              <div className="media-actions margin-top-6">
                <button
                  onClick={() => deSelectImages(active)}
                  className="button background-color-brand absolute-bottom-right margin-6 background-color-red box-shadow-medium w-button"
                >
                  Delete
                </button>
                <label
                  htmlFor="formId"
                  className="button background-color-brand absolute-bottom-right margin-6 background-color-white box-shadow-medium w-button"
                >
                  Upload
                  <input
                    className="button background-color-brand absolute-bottom-right margin-6 background-color-white box-shadow-medium w-button"
                    name=""
                    type="file"
                    id="formId"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="grid-3-columns margin-top-6">
                {preview &&
                  preview?.map((image, index) => (
                    <>
                      <img
                        key={index}
                        src={image}
                        alt="Images not displaying due to bad network."
                        onClick={() => setClassClicked(image, index)}
                        style={{
                          height: "168px",
                          objectFit: "contain",
                          width: "168px",
                        }}
                        className={`thumbnail radius-large w-inline-block${
                          active === image ? " active" : ""
                        }`}
                      />
                    </>
                  ))}
              </div>
            </div>
          </div>

          <div className="padding-top-6 border-top-2px flex-space-between margin-top-6 flex-align-baseline portrait-flex-column">
            <p className="text-color-2 margin-top-4">
              Submit 1-6 photos. Preview before sending
              <br />
              <span style={{ color: " #FFCC00" }}>{error}</span>
            </p>
            <button
              type="submit"
              className="button-secondary w-button"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPhoto;
