import React from "react";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";

const DisputeResponse = () => {
  return (
    <div>
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl padding-12">
          <div className="modal-section flex-column flex-align-center">
            <div>
              <h3 className="text-color-4 margin-0 text-align-center">
                💌
                <br />
                Submitted Response!
              </h3>
              <p className="text-medium text-align-center margin-6">
                Your response will have to be approved by
                <strong>&nbsp;the issuer</strong> before your{" "}
                <strong className="text-color-1">5</strong> credits are
                disbursed to you. The issuer reserves the right to refuse a
                submission.
              </p>
            </div>
            <Link to="/dashboard" className="button-secondary w-button">
              Done
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeResponse;
