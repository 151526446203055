import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DemographicData } from ".././../DemographicData";
import {
  childrenInHousehold,
  houseHoldMonthlyIncome,
  religion,
  employmentStatus,
  industryAffiliation,
  personalMonthlyIncome,
  peopleInHousehold,
  dateOfBirth,
  tribe,
  highestEducationLevel,
  gender,
  maritalStatus,
  sportsFan,
  soccerFan,
  favoriteTeam,
} from "../../actions/demographicActions";
import { useDispatch, useSelector } from "react-redux";
import { getScouts } from "../../actions/surveyActions";

const DemographicSurvey = ({ location }) => {
  const [demoData, setDemoData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [active, setActive] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const [selectMultipleOption, setMultipleSelectOption] = useState([]);
  const [date, setDate] = useState();
  // const [error, setError] = useState("");

  const [childrenInHouse, setChildrenInHouse] = useState("");
  const [houseMonthlyIncome, setHouseMonthlyIncome] = useState("");
  const [theReligion, setTheReligion] = useState("");
  const [theEmploymentStatus, setTheTheEmploymentStatus] = useState("");
  const [theIndustryAffiliation, setTheIndustryAffiliation] = useState("");
  const [thePersonalIncome, setThePersonalIncome] = useState("");
  const [thePeopleInHouseHold, setThePeopleInHouseHold] = useState("");
  const [theDateOfBirth, setTheDateOfBirth] = useState("");
  const [theTribe, setTheTribe] = useState("");
  const [theHighestEducation, setTheHighestEducation] = useState("");
  const [theGender, setTheGender] = useState("");
  const [theMaritalStatus, setTheMaritalStatus] = useState("");
  const [theSportsFan, setTheSportsFan] = useState("");
  const [theSoccerFan, setTheSoccerFan] = useState("");
  const [theFavoriteTeam, setTheFavoriteTeam] = useState("");

  const [indexTracker, setIndexTracker] = useState();
  const [demoError, seDemoError] = useState("");

  const string =
    window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
    window.location.hostname === "localhost"
      ? "55ad4986-b519-4e44-ab0b-890527299af6"
      : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

  const dispatch = useDispatch();
  const fetchScout = useSelector((state) => state.fetchScout);
  const { data } = fetchScout;
  let history = useHistory();

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch]);

  useEffect(() => {
    const filterDemoData = DemographicData.filter((x, i) => {
      return x.id === 1 && data?.data?.childrenInHousehold !== null
        ? x.id !== 1
        : x && x.id === 2 && data?.data?.householdMonthlyIncome !== null
        ? x.id !== 2
        : x && x.id === 3 && data?.data?.religion !== null
        ? x.id !== 3
        : x && x.id === 4 && data?.data?.employment !== null
        ? x.id !== 4
        : x && x.id === 5 && data?.data?.industryAffiliations !== null
        ? x.id !== 5
        : x && x.id === 6 && data?.data?.personalMonthlyIncome !== null
        ? x.id !== 6
        : x && x.id === 7 && data?.data?.peopleInHousehold !== null
        ? x.id !== 7
        : x && x.id === 8 && data?.data?.dob !== null
        ? x.id !== 8
        : x && x.id === 9 && data?.data?.tribe !== null
        ? x.id !== 9
        : x && x.id === 10 && data?.data?.education !== null
        ? x.id !== 10
        : x && x.id === 11 && data?.data?.gender !== null
        ? x.id !== 11
        : x && x.id === 12 && data?.data?.maritalStatus !== null
        ? x.id !== 12
        : x && x.id === 13 && data?.data?.sportsFan !== null
        ? x.id !== 13
        : x && x.id === 14 && data?.data?.soccerFan !== null
        ? x.id !== 14
        : x && x.id === 15 && data?.data?.favourite_team !== null
        ? x.id !== 15
        : x;
    });
    setDemoData(filterDemoData);
  }, [data]);

  const addOrRemoveInterests = (name) => {
    const newInterest = [...selectMultipleOption];
    const index = newInterest.indexOf(name);

    if (index === -1) {
      newInterest.push(name);
    } else {
      newInterest.splice(index, 1);
    }
    setMultipleSelectOption(newInterest);
  };

  const getDate = (date) => {
    setDate(date);
  };

  const handleAnswerButtonClick = (index) => {
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);
    setActive(false);
    setMultipleSelectOption([]);
    if (nextQuestion === demoData.length) {
      setShowEndScreen(true);
    }

    setIndexTracker(index.id);

    if (index.id === 1) {
      dispatch(
        childrenInHousehold({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          children_in_household: selectOption.payload,
        })
      )
        .unwrap()
        .then((childrenInHousehold) => {
          setChildrenInHouse(childrenInHousehold.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 2) {
      dispatch(
        houseHoldMonthlyIncome({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          household_monthly_income: selectOption.payload,
        })
      )
        .unwrap()
        .then((houseHoldMonthlyIncome) => {
          setHouseMonthlyIncome(houseHoldMonthlyIncome.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 3) {
      dispatch(
        religion({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          religion: selectOption.payload,
        })
      )
        .unwrap()
        .then((religion) => {
          setTheReligion(religion.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 4) {
      dispatch(
        employmentStatus({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          employment_status: selectOption.payload,
        })
      )
        .unwrap()
        .then((employmentStatus) => {
          setTheTheEmploymentStatus(employmentStatus.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 5) {
      dispatch(
        industryAffiliation({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          interests: selectMultipleOption,
        })
      )
        .unwrap()
        .then((industryAffiliation) => {
          setTheIndustryAffiliation(industryAffiliation.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 6) {
      dispatch(
        personalMonthlyIncome({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          personal_monthly_income: selectOption.payload,
        })
      )
        .unwrap()
        .then((personalMonthlyIncome) => {
          setThePersonalIncome(personalMonthlyIncome.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 7) {
      dispatch(
        peopleInHousehold({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          people_in_household: selectOption.payload,
        })
      )
        .unwrap()
        .then((peopleInHousehold) => {
          setThePeopleInHouseHold(peopleInHousehold.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 8) {
      dispatch(
        dateOfBirth({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          dob: date,
        })
      )
        .unwrap()
        .then((dateOfBirth) => {
          setTheDateOfBirth(dateOfBirth.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 9) {
      dispatch(
        tribe({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          tribe: selectOption.value,
        })
      )
        .unwrap()
        .then((tribe) => {
          setTheTribe(tribe.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 10) {
      dispatch(
        highestEducationLevel({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          highest_education_level: selectOption.value,
        })
      )
        .unwrap()
        .then((highestEducationLevel) => {
          setTheHighestEducation(highestEducationLevel.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 11) {
      dispatch(
        gender({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          gender: selectOption.value,
        })
      )
        .unwrap()
        .then((gender) => {
          setTheGender(gender.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 12) {
      dispatch(
        maritalStatus({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          marital_status: selectOption.payload,
        })
      )
        .unwrap()
        .then((maritalStatus) => {
          setTheMaritalStatus(maritalStatus.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 13) {
      dispatch(
        sportsFan({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          sportsFan: selectOption.value,
        })
      )
        .unwrap()
        .then((sportsFan) => {
          setTheSportsFan(sportsFan.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 14) {
      dispatch(
        soccerFan({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          soccerFan: selectOption.value,
        })
      )
        .unwrap()
        .then((soccerFan) => {
          setTheSoccerFan(soccerFan.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }

    if (index.id === 15) {
      dispatch(
        favoriteTeam({
          authtoken: string,
          scoutRef: data?.data?.phoneNumber,
          favorite_team_id: selectOption.payload,
        })
      )
        .unwrap()
        .then((favoriteTeam) => {
          setTheFavoriteTeam(favoriteTeam.status);
        })
        .catch((error) => {
          seDemoError(error);
        });
    }
  };

  function setClassClicked(name) {
    setActive(name.id);
  }
  function setDateClassClicked(name) {
    setActive(name);
  }

  const finish = () => {
    history.push({
      pathname: "/dashboard-demographic-notification",
      state: "Demographic",
      scoutRef: data?.data?.phoneNumber,
    });
  };

  if (demoError?.message?.length > 1) {
    window.location.reload();
  }

  return (
    <div>
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <a href="#!" className="w-nav-brand">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-center w-nav-menu"
            >
              <a
                href="/sign-up"
                className="button-secondary button-small disabled margin-right-2 w-button"
              >
                Cash out
              </a>
              <div
                data-hover=""
                data-delay="0"
                className="w-dropdown"
                style={{ maxWidth: "1280px" }}
              >
                <div
                  className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon text-color-4"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <nav
                  className="dropdown right box-shadow-medium w-dropdown-list"
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Settings
                  </a>
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Log out
                  </a>
                </nav>
              </div>
            </nav>
            <div
              className="margin-left-auto w-nav-button"
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      </div>
      <div className="section-in-base background-color-3">
        <div className="container-1280">
          <p className="text-2xl weight-bold">
            0 <span className="text-medium weight-normal">scout credits</span>
          </p>
        </div>
      </div>
      <div className="background-color-white">
        <div className="container-1280">
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="w-tabs"
          >
            <div
              className="page-tabs margin-bottom-6 w-tab-menu"
              role="tablist"
            >
              <a
                data-w-tab="Tab 1"
                className="tab w-inline-block w-tab-link w--current"
                id="w-tabs-0-data-w-tab-0"
                href="#w-tabs-0-data-w-pane-0"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-0"
                aria-selected="true"
              >
                <div className="text-color-4">1 Survey</div>
              </a>
              <a
                data-w-tab="Tab 2"
                className="tab w-inline-block w-tab-link"
                tabIndex="-1"
                id="w-tabs-0-data-w-tab-1"
                href="#w-tabs-0-data-w-pane-1"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-1"
                aria-selected="false"
              >
                <div>
                  <span>0</span> Requests
                </div>
              </a>
            </div>
            <div className="tabs-content w-tab-content">
              <div
                data-w-tab="Tab 1"
                className="grid-3-columns w-tab-pane w--tab-active"
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
              >
                <div className="radius-large background-color-white box-shadow-large padding-6 border-1px">
                  <h4>Earn 9 starting credits with the demographics survey!</h4>
                  <div className="flex-align-center margin-top-6 flex-space-between">
                    <div className="flex-align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-message-square icon text-color-3 margin-right-1"
                        src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                        loading="lazy"
                        alt=""
                      >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      <div className="text-color-2">9</div>
                    </div>
                    <a href="/sign-up" className="button button-small w-button">
                      Start{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right icon margin-left-1"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-w-tab="Tab 2"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        {showEndScreen === false && (
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                Demographics Survey
              </h5>
              <div className="flex-justify-center neg-margin margin-top-3">
                {demoData?.length > 0 ? (
                  demoData?.map((question, index) => (
                    <div
                      key={index}
                      className={`progress-bar expand ${
                        index === currentQuestion ? "active" : ""
                      }`}
                    ></div>
                  ))
                ) : (
                  <div className="progress-bar expand "></div>
                )}
              </div>

              <div className="margin-top-12">
                <h3 className="text-color-4">
                  {demoData[currentQuestion]?.question}
                  {}
                </h3>
                <div className="w-form">
                  {/* Use Ternary operator to display the options */}
                  {}
                  {/* Multiple Choice Number */}
                  {demoData[currentQuestion]?.unit === "NGN" && (
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                    >
                      <input
                        type="number"
                        className="form-input w-input"
                        maxLength="256"
                        data-name=""
                        placeholder="0"
                        id="node"
                      />
                    </form>
                  )}

                  {/* Date single select */}
                  {demoData[currentQuestion]?.unit === "date" && (
                    <>
                      {demoData[currentQuestion]?.options.map((x, i) => (
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          key={i}
                          onClick={() => setDateClassClicked(x)}
                        >
                          <input
                            type="date"
                            name="date"
                            className="form-input w-input"
                            maxLength="256"
                            placeholder="0"
                            onChange={(e) => getDate(e.target.value)}
                          />
                        </form>
                      ))}
                    </>
                  )}

                  {/* Multiple Choice Single Select */}
                  {demoData[currentQuestion]?.unit === "single_select" && (
                    <>
                      {demoData[currentQuestion]?.options.map((x) => (
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          key={x.id}
                          onClick={() => setClassClicked(x)}
                        >
                          <div onClick={() => setSelectOption(x)}>
                            <label className="radio-button-field w-radio">
                              <div
                                className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input  ${
                                  active === x.id
                                    ? "w--redirected-checked w--redirected-focus"
                                    : ""
                                }`}
                              ></div>
                              <input
                                type="radio"
                                data-name="Radio 3"
                                id="radio-3"
                                name="radio"
                                value="Radio"
                                style={{
                                  opacity: "0",
                                  position: "absolute",
                                  zIndex: "-1",
                                }}
                              />
                              <span className="radio-button-label w-form-label">
                                {x.value}
                              </span>
                            </label>
                          </div>
                        </form>
                      ))}
                    </>
                  )}

                  {/* Multiple Choice Multiple Select */}
                  {demoData[currentQuestion]?.unit === "multi_select" && (
                    <>
                      {demoData[currentQuestion]?.options.map((x) => (
                        <form key={x.id} onClick={() => setClassClicked(x)}>
                          <div>
                            <label className="w-checkbox checkbox-field">
                              <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                data-name="Checkbox"
                                className="w-checkbox-input checkbox-2"
                                value="Checkbox"
                                onChange={() => addOrRemoveInterests(x.value)}
                              />
                              <span
                                className="checkbox-label w-form-label"
                                htmlFor="checkbox"
                              >
                                {x.value}
                              </span>
                            </label>
                          </div>
                        </form>
                      ))}
                    </>
                  )}
                </div>
              </div>
              {/* <span style={{ color: "red" }}>
                {error}
              </span> */}
              <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
                {demoData[currentQuestion]?.button.map(
                  (answerOption, index) => (
                    <>
                      <button
                        key={index}
                        onClick={() =>
                          handleAnswerButtonClick(demoData[currentQuestion])
                        }
                        className="button-secondary w-button"
                        disabled={
                          !active ||
                          (indexTracker === 1 && childrenInHouse !== 200) ||
                          (indexTracker === 2 && houseMonthlyIncome !== 200) ||
                          (indexTracker === 3 && theReligion !== 200) ||
                          (indexTracker === 4 && theEmploymentStatus !== 200) ||
                          (indexTracker === 5 &&
                            theIndustryAffiliation !== 200) ||
                          (indexTracker === 6 && thePersonalIncome !== 200) ||
                          (indexTracker === 7 &&
                            thePeopleInHouseHold !== 200) ||
                          (indexTracker === 8 && theDateOfBirth !== 200) ||
                          (indexTracker === 9 && theTribe !== 200) ||
                          (indexTracker === 10 &&
                            theHighestEducation !== 200) ||
                          (indexTracker === 11 && theGender !== 200) ||
                          (indexTracker === 12 && theMaritalStatus !== 200) ||
                          (indexTracker === 13 && theSportsFan !== 200) ||
                          (indexTracker === 14 && theSoccerFan !== 200) ||
                          (indexTracker === 15 && theFavoriteTeam !== 200)
                        }
                      >
                        Confirm
                      </button>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {showEndScreen === true && (
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                Demographics Survey
              </h5>
              <div className="margin-top-12">
                <h3 className="text-color-4">
                  🎉
                  <br />
                  You’ve completed the Demographics Survey!
                </h3>
                <p className="text-medium">
                  You’ve earned{" "}
                  <strong className="text-color-1">
                    {location?.state} Scout Credits!
                  </strong>
                </p>
              </div>
              <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
                <button onClick={finish} className="button-secondary w-button">
                  Finish
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemographicSurvey;
