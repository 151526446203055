const options = [
  {
    countryName: "Nigeria",
    countryShortCode: "NG",
    states: [
      {
        name: "Abia",
        shortCode: "AB",
        lga: [
          {
            name: "Aba North",
            shortCode: "ABAN",
          },
          {
            name: "Arochukwu",
            shortCode: "ARO",
          },
          {
            name: "Aba South",
            shortCode: "ABAS",
          },
          {
            name: "Bende",
            shortCode: "BEN",
          },
          {
            name: "Isiala Ngwa North",
            shortCode: "ISI",
          },
          {
            name: "Ikwuano",
            shortCode: "IKW",
          },
          {
            name: "Isiala Ngwa South",
            shortCode: "ISIA",
          },
          {
            name: "Isuikwuato",
            shortCode: "ISUI",
          },
          {
            name: "Obi Ngwa",
            shortCode: "OBIN",
          },
          {
            name: "Ohafia",
            shortCode: "OHAF",
          },
          {
            name: "Osisioma",
            shortCode: "OSIS",
          },
          {
            name: "Ugwunagbo",
            shortCode: "UGWU",
          },
          {
            name: "Ukwa East",
            shortCode: "",
          },
          {
            name: "Ukwa West",
            shortCode: "UKWA",
          },
          {
            name: "Umuahia North",
            shortCode: "UMAH",
          },
          {
            name: "Umuahia South",
            shortCode: "UMUA",
          },
          {
            name: "Umu Nneochi",
            shortCode: "UMU",
          },
        ],
      },

      {
        name: "Adamawa",
        shortCode: "AD",
        lga: [
          {
            name: "Demsa",
            shortCode: "DEM",
          },
          {
            name: "Fufure",
            shortCode: "FUF",
          },
          {
            name: "Ganye",
            shortCode: "GAN",
          },
          {
            name: "Gayuk",
            shortCode: "GAY",
          },
          {
            name: "Gombi",
            shortCode: "GOM",
          },
          {
            name: "Grie",
            shortCode: "GRI",
          },
          {
            name: "Hong",
            shortCode: "HON",
          },
          {
            name: "Jada",
            shortCode: "JAD",
          },
          {
            name: "Larmurde",
            shortCode: "LAR",
          },
          {
            name: "Madagali",
            shortCode: "",
          },
          {
            name: "Maiha",
            shortCode: "MAI",
          },
          {
            name: "Mayo Belwa",
            shortCode: "MAY",
          },
          {
            name: "Michika",
            shortCode: "MIC",
          },
          {
            name: "Mubi North",
            shortCode: "MUBN",
          },
          {
            name: "Mubi South",
            shortCode: "MUBS",
          },
          {
            name: "Numan",
            shortCode: "NUM",
          },
          {
            name: "Shelleng",
            shortCode: "SHE",
          },
          {
            name: "Song",
            shortCode: "SON",
          },
          {
            name: "Toungo",
            shortCode: "TOU",
          },
          {
            name: "Yola North",
            shortCode: "YOLN",
          },
          {
            name: "Yola South",
            shortCode: "YOLS",
          },
        ],
      },

      {
        name: "Akwa Ibom",
        shortCode: "AK",
        lga: [
          {
            name: "Abak",
            shortCode: "ABA",
          },
          {
            name: "Eastern Obolo",
            shortCode: "EAO",
          },
          {
            name: "Eket",
            shortCode: "EKE",
          },
          {
            name: "Esit-Eket",
            shortCode: "ESE",
          },
          {
            name: "Essien Udim",
            shortCode: "ESU",
          },
          {
            name: "Etim-Ekpo",
            shortCode: "ETE",
          },
          {
            name: "Etinan",
            shortCode: "ETI",
          },
          {
            name: "Ibeno",
            shortCode: "IBE",
          },
          {
            name: "Ibesikpo-Asutan",
            shortCode: "IBA",
          },
          {
            name: "Ibiono-Ibom",
            shortCode: "IBI",
          },
          {
            name: "Ika",
            shortCode: "IK",
          },
          {
            name: "Ikono",
            shortCode: "IKO",
          },
          {
            name: "Ikot Abasi",
            shortCode: "IKA",
          },
          {
            name: "Ikot Ekpene",
            shortCode: "IKE",
          },
          {
            name: "Ini",
            shortCode: "IN",
          },
          {
            name: "Itu",
            shortCode: "IT",
          },
          {
            name: "Mbo",
            shortCode: "MB",
          },
          {
            name: "DemMkpat-Eninsa",
            shortCode: "DME",
          },
          {
            name: "Nsit-Atai",
            shortCode: "NSA",
          },
          {
            name: "Nsit-Ibom",
            shortCode: "NSI",
          },
          {
            name: "Nsit-Ubium",
            shortCode: "NSU",
          },
          {
            name: "Obot-Akara",
            shortCode: "OBA",
          },
          {
            name: "Okobo",
            shortCode: "OKO",
          },
          {
            name: "Onna",
            shortCode: "ONN",
          },
          {
            name: "Oron",
            shortCode: "ORO",
          },
          {
            name: "Oruk Anam",
            shortCode: "ORA",
          },
          {
            name: "Ukanafun",
            shortCode: "UKA",
          },
          {
            name: "Udung-Uko",
            shortCode: "UDU",
          },
          {
            name: "Uruan",
            shortCode: "URU",
          },
          {
            name: "Urue-Offong/Oruko",
            shortCode: "UOO",
          },
          {
            name: "Uyo",
            shortCode: "UY",
          },
        ],
      },

      {
        name: "Anambra",
        shortCode: "AN",
        lga: [
          {
            name: "Aguata",
            shortCode: "AGU",
          },
          {
            name: "Anambra West",
            shortCode: "ANW",
          },
          {
            name: "Anaocha",
            shortCode: "ANO",
          },
          {
            name: "Awka North",
            shortCode: "AKN",
          },
          {
            name: "Awka South",
            shortCode: "AKS",
          },
          {
            name: "Ayamelum",
            shortCode: "AYAM",
          },
          {
            name: "Idemili North",
            shortCode: "IDIN",
          },
          {
            name: "Idemili South",
            shortCode: "IDIS",
          },
          {
            name: "Ihiala",
            shortCode: "IHIA",
          },
          {
            name: "Njikoka",
            shortCode: "NJIK",
          },
          {
            name: "Nnewi North",
            shortCode: "NNEN",
          },
          {
            name: "Ogbaru",
            shortCode: "OGBA",
          },
          {
            name: "Onitsha North",
            shortCode: "ONIN",
          },
          {
            name: "Onitsha South",
            shortCode: "ONIS",
          },
          {
            name: "Orumba North",
            shortCode: "ORUN",
          },
          {
            name: "Orumba South",
            shortCode: "ORUS",
          },
          {
            name: "Oyi",
            shortCode: "OY",
          },
        ],
      },

      {
        name: "Bauchi",
        shortCode: "BA",
        lga: [
          {
            name: "Alkaleri",
            shortCode: "ALK",
          },
          {
            name: "Bauchi",
            shortCode: "BAUC",
          },
          {
            name: "Bogoro",
            shortCode: "BOGO",
          },
          {
            name: "Damban",
            shortCode: "DAMB",
          },
          {
            name: "Darazo",
            shortCode: "DARA",
          },
          {
            name: "Dass, Nigeria",
            shortCode: "DASS",
          },
          {
            name: "Gamawa",
            shortCode: "GAMA",
          },
          {
            name: "Ganjuwa",
            shortCode: "GANJ",
          },
          {
            name: "Giade",
            shortCode: "GIAD",
          },
          {
            name: "Itas/Gadau",
            shortCode: "IT/GA",
          },
          {
            name: "Jama'are",
            shortCode: "JAMA",
          },
          {
            name: "Katagum",
            shortCode: "KATA",
          },
          {
            name: "Kirfi",
            shortCode: "KIRF",
          },
          {
            name: "Misau",
            shortCode: "MISA",
          },
          {
            name: "Ningi, Nigeria",
            shortCode: "NING",
          },
          {
            name: "Shira, Nigeria",
            shortCode: "SHIR",
          },
          {
            name: " Tafawa Balewa, Bauchi",
            shortCode: "TAFA",
          },
          {
            name: " Toro, Nigeria",
            shortCode: "TORO",
          },
          {
            name: "Warji",
            shortCode: "WARJ",
          },
          {
            name: "Zaki, Nigeria",
            shortCode: "ZAKI",
          },
        ],
      },

      {
        name: "Bayelsa",
        shortCode: "BAY",
        lga: [
          {
            name: "Brass",
            shortCode: "BRA",
          },
          {
            name: "Ekeremor",
            shortCode: "EKER",
          },
          {
            name: "Kolokuma Opokuma",
            shortCode: "KOLO",
          },
          {
            name: "Nembe",
            shortCode: "NEMB",
          },
          {
            name: "Ogbia",
            shortCode: "OGBI",
          },
          {
            name: "Sagbama",
            shortCode: "SAGB",
          },
          {
            name: "Southern Ijaw",
            shortCode: "SOIJ",
          },
          {
            name: "Yenagoa",
            shortCode: "YENA",
          },
        ],
      },

      {
        name: "Benue",
        shortCode: "BE",
        lga: [
          {
            name: "Ado",
            shortCode: "ADO",
          },
          {
            name: "Agatu",
            shortCode: "AGA",
          },
          {
            name: "Apa",
            shortCode: "APA",
          },
          {
            name: "Buruku",
            shortCode: "BURU",
          },
          {
            name: "Gboko",
            shortCode: "GBOK",
          },
          {
            name: "Guma",
            shortCode: "GUMA",
          },
          {
            name: "Gwer East",
            shortCode: "GWEE",
          },
          {
            name: "Gwer West",
            shortCode: "GWEW",
          },
          {
            name: "Katsina-Ala",
            shortCode: "KATS",
          },
          {
            name: "Konshisha",
            shortCode: "KONS",
          },
          {
            name: "Kwande",
            shortCode: "KWAN",
          },
          {
            name: "Logo",
            shortCode: "LOGO",
          },
          {
            name: "Makurdi",
            shortCode: "MAKUR",
          },
          {
            name: "Obi",
            shortCode: "OBI",
          },
          {
            name: "Ogbadibo",
            shortCode: "OGBA",
          },
          {
            name: "Ohimini",
            shortCode: "OHIM",
          },
          {
            name: "Oju",
            shortCode: "OJU",
          },
          {
            name: "Okpokwu",
            shortCode: "OKPO",
          },
          {
            name: "Otukpo",
            shortCode: "OTUK",
          },
          {
            name: "Tarka",
            shortCode: "TARK",
          },
          {
            name: "Ukum",
            shortCode: "UKUM",
          },
          {
            name: "Ushongo",
            shortCode: "USHO",
          },
          {
            name: "Vandeikya",
            shortCode: "VAND",
          },
        ],
      },

      {
        name: "Borno",
        shortCode: "BO",
        lga: [
          {
            name: "Abadam",
            shortCode: "ABAD",
          },
          {
            name: "Askira/Uba",
            shortCode: "ASKI",
          },
          {
            name: "Bama, Borno",
            shortCode: "BAMA",
          },
          {
            name: "Bayo, Nigeria",
            shortCode: "BAYO",
          },
          {
            name: "Biu, Nigeria",
            shortCode: "BUIN",
          },
          {
            name: "Chibok",
            shortCode: "CHIB",
          },
          {
            name: "Damboa",
            shortCode: "DAMB",
          },
          {
            name: "Dikwa",
            shortCode: "DIKW",
          },
          {
            name: "Gubio",
            shortCode: "GUBI",
          },
          {
            name: "Guzamala",
            shortCode: "GUZA",
          },
          {
            name: "Gwoza",
            shortCode: "GWOZ",
          },
          {
            name: "Hawul",
            shortCode: "HAWU",
          },
          {
            name: "Jere, Borno",
            shortCode: "JERE",
          },
          {
            name: "Kaga, Nigeria",
            shortCode: "KAGA",
          },
          {
            name: "Kala/Balge",
            shortCode: "KALA",
          },
          {
            name: "Kukawa",
            shortCode: "KUKA",
          },
          {
            name: "Kwaya Kusar",
            shortCode: "KWAY",
          },
          {
            name: "Mafa",
            shortCode: "MAFA",
          },
          {
            name: "Magumeri",
            shortCode: "MAGU",
          },
          {
            name: "Maiduguri",
            shortCode: "MAID",
          },
          {
            name: "Marte, Borno",
            shortCode: "MART",
          },
          {
            name: "Mobbar",
            shortCode: "MOBB",
          },
          {
            name: "Monguno",
            shortCode: "MONG",
          },
          {
            name: "Ngala",
            shortCode: "NGAL",
          },
          {
            name: "Nganzai",
            shortCode: "NGAN",
          },
          {
            name: "Shani, Nigeria",
            shortCode: "SHAN",
          },
        ],
      },

      {
        name: "Cross River",
        shortCode: "CR",
        lga: [
          {
            name: "Abi",
            shortCode: "ABI",
          },
          {
            name: "Akamkpa",
            shortCode: "AKAM",
          },
          {
            name: "Akpabuyo",
            shortCode: "AKPA",
          },
          {
            name: "Bekwarra",
            shortCode: "BEKW",
          },
          {
            name: "Bakassi",
            shortCode: "BAKA",
          },
          {
            name: "Biase",
            shortCode: "BIAS",
          },
          {
            name: "Boki",
            shortCode: "BOKI",
          },
          {
            name: "Calabar Municipal",
            shortCode: "CALAM",
          },
          {
            name: "Calabar South",
            shortCode: "CALAS",
          },
          {
            name: "Etung",
            shortCode: "ETUN",
          },
          {
            name: "Ikom",
            shortCode: "IKOM",
          },
          {
            name: "Obanliku",
            shortCode: "OBAN",
          },
          {
            name: "Obubra",
            shortCode: "OBUB",
          },
          {
            name: "Obudu",
            shortCode: "OBUD",
          },
          {
            name: "Odukpani",
            shortCode: "ODUK",
          },
          {
            name: "Ogoja",
            shortCode: "OGOJ",
          },
          {
            name: "Yakuur",
            shortCode: "YAKU",
          },
          {
            name: "Yala",
            shortCode: "YALA",
          },
        ],
      },

      {
        name: "Delta",
        shortCode: "DE",
        lga: [
          {
            name: "Aniocha North",
            shortCode: "ANION",
          },
          {
            name: "Aniocha South",
            shortCode: "ANIOS",
          },
          {
            name: "Burutu",
            shortCode: "BURU",
          },
          {
            name: "Bomadi",
            shortCode: "BOMA",
          },
          {
            name: "Ethiope East",
            shortCode: "ETHIE",
          },
          {
            name: "Ethiope West",
            shortCode: "ETHIW",
          },
          {
            name: "Ika North",
            shortCode: "IKAN",
          },
          {
            name: "Ika South",
            shortCode: "IKAS",
          },
          {
            name: "Isoko North",
            shortCode: "ISON",
          },
          {
            name: "Isoko South",
            shortCode: "ISOS",
          },
          {
            name: "Ndokwa East",
            shortCode: "NDOE",
          },
          {
            name: "Ndokwa West",
            shortCode: "NDOW",
          },
          {
            name: "Oshimili North",
            shortCode: "OSHN",
          },
          {
            name: "Oshimili South",
            shortCode: "OSHS",
          },
          {
            name: "Okpe",
            shortCode: "OKPE",
          },
          {
            name: "Patani",
            shortCode: "PATA",
          },
          {
            name: "Sapele",
            shortCode: "SAPE",
          },
          {
            name: "Udu",
            shortCode: "UDU",
          },
          {
            name: "Ughelli North",
            shortCode: "UGHN",
          },
          {
            name: "Ughelli South",
            shortCode: "UGHS",
          },
          {
            name: "Ukwuani",
            shortCode: "UKWU",
          },
          {
            name: "Uvwie",
            shortCode: "UVWI",
          },
          {
            name: "Warri North",
            shortCode: "WARN",
          },
          {
            name: "Warri South",
            shortCode: "WARS",
          },
          {
            name: "Warri South West",
            shortCode: "WARSW",
          },
        ],
      },

      {
        name: "Ebonyi",
        shortCode: "EB",
        lga: [
          {
            name: "Abakaliki",
            shortCode: "ABAK",
          },
          {
            name: "Afikpo North",
            shortCode: "AFIKN",
          },
          {
            name: "Afikpo South",
            shortCode: "AFIKS",
          },
          {
            name: "Ebonyi, Ebonyi State",
            shortCode: "EBONY",
          },
          {
            name: "Ezza North",
            shortCode: "EZZAN",
          },
          {
            name: "Ezza South",
            shortCode: "EZZAS",
          },
          {
            name: "Ikwo",
            shortCode: "IKWO",
          },
          {
            name: "Ishielu",
            shortCode: "ISHI",
          },
          {
            name: "Ivo, Ebonyi",
            shortCode: "IVOE",
          },
          {
            name: "Izzi (Ebonyi)",
            shortCode: "IZZI",
          },
          {
            name: "Izzi people",
            shortCode: "IZZIP",
          },
          {
            name: "Ohaozara",
            shortCode: "OHAO",
          },
          {
            name: "Ohaukwu",
            shortCode: "OHAU",
          },
          {
            name: "Onicha",
            shortCode: "ONIC",
          },
        ],
      },

      {
        name: "Edo",
        shortCode: "ED",
        lga: [
          {
            name: "Akoko-Edo",
            shortCode: "AKOK",
          },
          {
            name: "Egor",
            shortCode: "EGOR",
          },
          {
            name: "Esan Central",
            shortCode: "ESANC",
          },
          {
            name: "Esan North-East",
            shortCode: "ESNE",
          },
          {
            name: "Esan South-East",
            shortCode: "ESSE",
          },
          {
            name: "Esan West",
            shortCode: "ESWE",
          },
          {
            name: "Etsako Central",
            shortCode: "ETSA",
          },
          {
            name: "Etsako East",
            shortCode: "ETSA",
          },
          {
            name: "Etsako West",
            shortCode: "ETSAW",
          },
          {
            name: "Igueben",
            shortCode: "IGUE",
          },
          {
            name: "Ikpoba-Okha",
            shortCode: "IKOK",
          },
          {
            name: "Oredo",
            shortCode: "ORED",
          },
          {
            name: "Orhionmwon",
            shortCode: "ORHI",
          },
          {
            name: "Ovia North-East",
            shortCode: "OVIAN",
          },
          {
            name: "Ovia South-West",
            shortCode: "OVISW",
          },
          {
            name: "Owan East",
            shortCode: "OWANE",
          },
          {
            name: "Owan West",
            shortCode: "OWANE",
          },
          {
            name: "Uhunmwonde",
            shortCode: "UHUNM",
          },
        ],
      },

      {
        name: "Ekiti",
        shortCode: "EK",
        lga: [
          {
            name: "Ado Ekiti",
            shortCode: "ADOE",
          },
          {
            name: "Ikere",
            shortCode: "IKER",
          },
          {
            name: "Oye",
            shortCode: "OY",
          },
          {
            name: "Aiyekire (Gbonyin)",
            shortCode: "AIYE",
          },
          {
            name: "Efon",
            shortCode: "EFON",
          },
          {
            name: "Ekiti East",
            shortCode: "EKITE",
          },
          {
            name: "Ekiti South-West",
            shortCode: "EKSW",
          },
          {
            name: "Ekiti West",
            shortCode: "EKIW",
          },
          {
            name: "Emure",
            shortCode: "EMUR",
          },
          {
            name: "Ido-Osi",
            shortCode: "IDOS",
          },
          {
            name: "Ijero",
            shortCode: "IJER",
          },
          {
            name: "Ikole",
            shortCode: "IKOL",
          },
          {
            name: "Ilejemeje",
            shortCode: "ILEJ",
          },
          {
            name: "Irepodun/Ifelodun",
            shortCode: "IRE/IFE",
          },
          {
            name: "Ise/Orun",
            shortCode: "ISE/ORU",
          },
          {
            name: "Moba",
            shortCode: "MOB",
          },
        ],
      },

      {
        name: "Enugu",
        shortCode: "EN",
        lga: [
          {
            name: "Awgu",
            shortCode: "AWGU",
          },
          {
            name: "Aninri",
            shortCode: "ANIN",
          },
          {
            name: "Edem",
            shortCode: "EDEM",
          },
          {
            name: "Enugu East",
            shortCode: "ENUE",
          },
          {
            name: "Enugu North",
            shortCode: "ENUN",
          },
          {
            name: "Enugu South",
            shortCode: "ENUS",
          },
          {
            name: "Ezeagu",
            shortCode: "EZEA",
          },
          {
            name: "Igbo Etiti",
            shortCode: "IGBOE",
          },
          {
            name: "Igbo Eze North",
            shortCode: "IGBOEN",
          },
          {
            name: "Igbo Eze South",
            shortCode: "IGBES",
          },
          {
            name: "Isi Uzo",
            shortCode: "ISIU",
          },
          {
            name: "Mmaku",
            shortCode: "MMAK",
          },
          {
            name: "Nkanu East",
            shortCode: "NKAE",
          },
          {
            name: "Nkanu West",
            shortCode: "NKANW",
          },
          {
            name: "Nsukka",
            shortCode: "NSUKK",
          },
          {
            name: "Oji River",
            shortCode: "OJIR",
          },
          {
            name: "Udenu",
            shortCode: "UDEN",
          },
          {
            name: "Udi, Enugu",
            shortCode: "UDIE",
          },
          {
            name: "Unadu",
            shortCode: "UNAD",
          },
          {
            name: "Uzo Uwani",
            shortCode: "UZOW",
          },
        ],
      },

      {
        name: "Abuja",
        shortCode: "AJ",
        lga: [
          {
            name: "Abaji",
            shortCode: "ABAJ",
          },
          {
            name: "Abuja",
            shortCode: "ABUJ",
          },
          {
            name: "Bwari",
            shortCode: "BWAR",
          },
          {
            name: "Gwagwalada",
            shortCode: "GWAG",
          },
          {
            name: "Kuje",
            shortCode: "KUJE",
          },
          {
            name: "Kwali",
            shortCode: "KWAL",
          },
        ],
      },

      {
        name: "Gombe",
        shortCode: "GO",
        lga: [
          {
            name: "Akko",
            shortCode: "AKK",
          },
          {
            name: "Balanga",
            shortCode: "BALA",
          },
          {
            name: "Billiri",
            shortCode: "BILL",
          },
          {
            name: "Dukku",
            shortCode: "DUKK",
          },
          {
            name: "Funakaye",
            shortCode: "FUNA",
          },
          {
            name: "Gombe",
            shortCode: "GOMB",
          },
          {
            name: "Kaltungo",
            shortCode: "KALT",
          },
          {
            name: "Kwami",
            shortCode: "KWAM",
          },
          {
            name: "Nafada",
            shortCode: "NAFA",
          },
          {
            name: "Shongom",
            shortCode: "SHON",
          },
          {
            name: "Yamaltu/Deba",
            shortCode: "YAMDEB",
          },
        ],
      },

      {
        name: "Imo",
        shortCode: "IM",
        lga: [
          {
            name: "Aboh Mbaise",
            shortCode: "ABOH",
          },
          {
            name: "Ahiazu Mbaise",
            shortCode: "AHIM",
          },
          {
            name: "Ehime Mbano",
            shortCode: "EHIM",
          },
          {
            name: "Ezinihitte Mbaise",
            shortCode: "EZINM",
          },
          {
            name: "Ideato North",
            shortCode: "IDEN",
          },
          {
            name: "Ideato South",
            shortCode: "IDEAS",
          },
          {
            name: "Ihitte/Uboma",
            shortCode: "IHINU",
          },
          {
            name: "IkedurU",
            shortCode: "IKED",
          },
          {
            name: "Isiala Mbano",
            shortCode: "ISIM",
          },
          {
            name: "Isu",
            shortCode: "IS",
          },
          {
            name: "Mbaitoli",
            shortCode: "MBAI",
          },
          {
            name: "Ngor Okpala",
            shortCode: "NGOO",
          },
          {
            name: "Njaba",
            shortCode: "NJAB",
          },
          {
            name: "Nkwerre",
            shortCode: "NKWE",
          },
          {
            name: "Nwangele",
            shortCode: "NWANG",
          },
          {
            name: "Obowo",
            shortCode: "OBOW",
          },
          {
            name: "Oguta",
            shortCode: "OGUT",
          },
          {
            name: "Ohaji/Egbema",
            shortCode: "OHAE",
          },
          {
            name: "Okigwe",
            shortCode: "OKIG",
          },
          {
            name: "Onuimo",
            shortCode: "ONUI",
          },
          {
            name: "Orlu",
            shortCode: "OR",
          },
          {
            name: "Orsu",
            shortCode: "ORS",
          },
          {
            name: "Oru East",
            shortCode: "ORUE",
          },
          {
            name: "Oru West",
            shortCode: "ORUW",
          },
          {
            name: "Owerri Municipal",
            shortCode: "OWEM",
          },
          {
            name: "Owerri North",
            shortCode: "OWEN",
          },
          {
            name: "Owerri West",
            shortCode: "OWEW",
          },
        ],
      },

      {
        name: "Jigawa",
        shortCode: "JI",
        lga: [
          {
            name: "Auyo",
            shortCode: "AUY",
          },
          {
            name: "Babura",
            shortCode: "BABU",
          },
          {
            name: "Biriniwa",
            shortCode: "BIRI",
          },
          {
            name: "Birnin Kudu",
            shortCode: "BIRK",
          },
          {
            name: "Buji",
            shortCode: "BUJ",
          },
          {
            name: "Dutse",
            shortCode: "DUTS",
          },
          {
            name: "Gagarawa",
            shortCode: "GAGA",
          },
          {
            name: "Garki",
            shortCode: "GARK",
          },
          {
            name: "Gumel",
            shortCode: "GUME",
          },
          {
            name: "Guri",
            shortCode: "GUR",
          },
          {
            name: "Gwaram",
            shortCode: "GWAR",
          },
          {
            name: "Gwiwa",
            shortCode: "GWIW",
          },
          {
            name: "Hadejia",
            shortCode: "HADE",
          },
          {
            name: "Jahun",
            shortCode: "JAH",
          },
          {
            name: "Kafin Hausa",
            shortCode: "KAFH",
          },
          {
            name: "Kaugama",
            shortCode: "KAUG",
          },
          {
            name: "Kazaure",
            shortCode: "KAZA",
          },
          {
            name: "Kiri Kasama",
            shortCode: "KIRK",
          },
          {
            name: "Kiyawa",
            shortCode: "KIYA",
          },
          {
            name: "Maigatari",
            shortCode: "MAIG",
          },
          {
            name: "Malam Madori",
            shortCode: "MALM",
          },
          {
            name: "Miga",
            shortCode: "MIG",
          },
          {
            name: "Ringim",
            shortCode: "RING",
          },
          {
            name: "Roni",
            shortCode: "RON",
          },
          {
            name: "Sule Tankarkar",
            shortCode: "SULT",
          },
          {
            name: "Taura",
            shortCode: "TAUR",
          },
          {
            name: "Yankwashi",
            shortCode: "YANK",
          },
        ],
      },

      {
        name: "Kaduna",
        shortCode: "KD",
        lga: [
          {
            name: "Birnin Gwari",
            shortCode: "BIRN",
          },
          {
            name: "Chikun",
            shortCode: "CHI",
          },
          {
            name: "Giwa",
            shortCode: "GIW",
          },
          {
            name: "Igabi",
            shortCode: "IGA",
          },
          {
            name: "Jaba, Kaduna State",
            shortCode: "JAB",
          },
          {
            name: "Jema'a",
            shortCode: "JEM",
          },
          {
            name: "Kachia",
            shortCode: "KACH",
          },
          {
            name: "Kaduna North",
            shortCode: "KADU",
          },
          {
            name: "Kaduna South",
            shortCode: "KASU",
          },
          {
            name: "Kagarko",
            shortCode: "KAGA",
          },
          {
            name: "Kajuru",
            shortCode: "KAJ",
          },
          {
            name: "Kaura, Kaduna State",
            shortCode: "KAUR",
          },
          {
            name: "Kauru",
            shortCode: "KAUR",
          },
          {
            name: "Kubau",
            shortCode: "KUB",
          },
          {
            name: "Kudan, Kaduna",
            shortCode: "KUDK",
          },
          {
            name: "Lere, Kaduna State",
            shortCode: "LERE",
          },
          {
            name: "Makarfi",
            shortCode: "MAKA",
          },
          {
            name: "Sabon Gari, Kaduna State",
            shortCode: "SABO",
          },
          {
            name: "Sanga, Kaduna State",
            shortCode: "SANG",
          },
          {
            name: "Soba, Kaduna State",
            shortCode: "SOBA",
          },
          {
            name: "Zangon Kataf",
            shortCode: "ZANG",
          },
          {
            name: "Zaria",
            shortCode: "ZARI",
          },
        ],
      },

      {
        name: "Kano",
        shortCode: "KN",
        lga: [
          {
            name: "Ajingi",
            shortCode: "AJIN",
          },
          {
            name: "Albasu",
            shortCode: "ALB",
          },
          {
            name: "Bagwai",
            shortCode: "BAGA",
          },
          {
            name: "Bebeji",
            shortCode: "BEBE",
          },
          {
            name: "Bichi",
            shortCode: "BICH",
          },
          {
            name: "Bunkure",
            shortCode: "BUNK",
          },
          {
            name: "Dala, Kano",
            shortCode: "DALAK",
          },
          {
            name: "Dambatta",
            shortCode: "DAMB",
          },
          {
            name: "Dawakin Kudu",
            shortCode: "DAWK",
          },
          {
            name: "Dawakin Tofa",
            shortCode: "DAWT",
          },
          {
            name: "Doguwa",
            shortCode: "DOGU",
          },
          {
            name: "Fagge",
            shortCode: "FAGG",
          },
          {
            name: "Gabasawa",
            shortCode: "GABAS",
          },
          {
            name: "Garun Mallam",
            shortCode: "GARM",
          },
          {
            name: "Gaya, Nigeria",
            shortCode: "GAYAN",
          },
          {
            name: "Gezawa",
            shortCode: "GEZA",
          },
          {
            name: "Gwale",
            shortCode: "GWAL",
          },
          {
            name: "Gwarzo",
            shortCode: "GWAR",
          },
          {
            name: "Kabo, Nigeria",
            shortCode: "KABN",
          },
          {
            name: "Kano Municipal",
            shortCode: "KANM",
          },
          {
            name: "Karaye",
            shortCode: "KARA",
          },
          {
            name: "Kibiya",
            shortCode: "KIBI",
          },
          {
            name: "Kiru, Nigeria",
            shortCode: "KIRU",
          },
          {
            name: "Kumbotso",
            shortCode: "KUMB",
          },
          {
            name: "Kunchi, Nigeria",
            shortCode: "KUNC",
          },
          {
            name: "Kura, Nigeria",
            shortCode: "KURAN",
          },
          {
            name: "Madobi",
            shortCode: "MADO",
          },
          {
            name: "Makoda",
            shortCode: "MAKO",
          },
          {
            name: "Minjibir",
            shortCode: "MINJ",
          },
          {
            name: "Nasarawa, Kano State",
            shortCode: "NASA",
          },
          {
            name: "Rano",
            shortCode: "RAN",
          },
          {
            name: "Rimin Gado",
            shortCode: "RIMIG",
          },
          {
            name: "Rogo",
            shortCode: "ROG",
          },
          {
            name: "Shanono",
            shortCode: "SHAN",
          },
          {
            name: "Sumaila",
            shortCode: "SUMA",
          },
          {
            name: "Takai",
            shortCode: "TAKA",
          },
          {
            name: "Tarauni",
            shortCode: "TARA",
          },
          {
            name: "Tofa, Nigeria",
            shortCode: "TOFA",
          },
          {
            name: "Tsanyawa",
            shortCode: "TSAN",
          },
          {
            name: "Tudun Wada",
            shortCode: "TUND",
          },
          {
            name: "Ungogo",
            shortCode: "UNG",
          },
          {
            name: "Warawa",
            shortCode: "WARA",
          },
          {
            name: "Wudil",
            shortCode: "WUD",
          },
        ],
      },

      {
        name: "Katsina",
        shortCode: "KT",
        lga: [
          {
            name: "Bakori",
            shortCode: "BAKO",
          },
          {
            name: "Batagarawa",
            shortCode: "BATA",
          },
          {
            name: "Batsari",
            shortCode: "BATS",
          },
          {
            name: "Baure",
            shortCode: "BAU",
          },
          {
            name: "Bindawa",
            shortCode: "BIND",
          },
          {
            name: "Charanchi",
            shortCode: "CHA",
          },
          {
            name: "Dan Musa",
            shortCode: "DANM",
          },
          {
            name: "Dandume",
            shortCode: "DAND",
          },
          {
            name: "Danja",
            shortCode: "DANJ",
          },
          {
            name: "Daura",
            shortCode: "DAU",
          },
          {
            name: "Dutsi",
            shortCode: "DUT",
          },
          {
            name: "Dutsin-Ma",
            shortCode: "DUTS",
          },
          {
            name: "Faskari",
            shortCode: "FASK",
          },
          {
            name: "Funtua",
            shortCode: "FUNT",
          },
          {
            name: "Ingawa",
            shortCode: "INGA",
          },
          {
            name: "Jibia",
            shortCode: "JIBI",
          },
          {
            name: "Kafur",
            shortCode: "KAF",
          },
          {
            name: "Kaita",
            shortCode: "KAIT",
          },
          {
            name: "Kankara",
            shortCode: "KANK",
          },
          {
            name: "Kankia",
            shortCode: "KANK",
          },
          {
            name: "Katsina",
            shortCode: "KATS",
          },
          {
            name: "Kurfi",
            shortCode: "KUR",
          },
          {
            name: "Kusada",
            shortCode: "KUSA",
          },
          {
            name: "Mai'Adua",
            shortCode: "MAI",
          },
          {
            name: "Malumfashi",
            shortCode: "MALU",
          },
          {
            name: "Mani",
            shortCode: "MAN",
          },
          {
            name: "Mashi",
            shortCode: "MASH",
          },
          {
            name: "Matazu",
            shortCode: "MATZ",
          },
          {
            name: "Musawa",
            shortCode: "MUSA",
          },
          {
            name: "Rimi",
            shortCode: "RIM",
          },
          {
            name: "Sabuwa",
            shortCode: "SABU",
          },
          {
            name: "Safana",
            shortCode: "SAFA",
          },
          {
            name: "Sandamu",
            shortCode: "SAND",
          },
          {
            name: "Zango",
            shortCode: "ZANG",
          },
        ],
      },

      {
        name: "Kebbi",
        shortCode: "KE",
        lga: [
          {
            name: "Aleiro",
            shortCode: "ALEI",
          },
          {
            name: "Arewa Dandi",
            shortCode: "AREWD",
          },
          {
            name: "Argungu",
            shortCode: "ARGUN",
          },
          {
            name: "Augie",
            shortCode: "AUGI",
          },
          {
            name: "Bagudo",
            shortCode: "BAGU",
          },
          {
            name: "Birnin Kebbi",
            shortCode: "BIRK",
          },
          {
            name: "Bunza",
            shortCode: "BUNZ",
          },
          {
            name: "Dandi",
            shortCode: "DAND",
          },
          {
            name: "Fakai",
            shortCode: "FAKA",
          },
          {
            name: "Gwandu",
            shortCode: "GWAN",
          },
          {
            name: "Jega",
            shortCode: "JEG",
          },
          {
            name: "Kalgo",
            shortCode: "KAL",
          },
          {
            name: "Koko/Besse",
            shortCode: "KOKO",
          },
          {
            name: "Maiyama",
            shortCode: "MAIY",
          },
          {
            name: "Ngaski",
            shortCode: "NGAS",
          },
          {
            name: "Sakaba",
            shortCode: "SAKB",
          },
          {
            name: "Shanga",
            shortCode: "SHAN",
          },
          {
            name: "Suru",
            shortCode: "SUR",
          },
          {
            name: "Wasagu",
            shortCode: "WASA",
          },
          {
            name: "Yauri",
            shortCode: "YAUR",
          },
          {
            name: "Zuru",
            shortCode: "ZUR",
          },
        ],
      },

      {
        name: "Kogi",
        shortCode: "KO",
        lga: [
          {
            name: "Ajaokuta",
            shortCode: "AJAO",
          },
          {
            name: "Adavi",
            shortCode: "ADAV",
          },
          {
            name: "Ankpa",
            shortCode: "ANK",
          },
          {
            name: "Bassa",
            shortCode: "BASS",
          },
          {
            name: "Dekina",
            shortCode: "DEK",
          },
          {
            name: "Ibaji",
            shortCode: "IBAJ",
          },
          {
            name: "Idah",
            shortCode: "ID",
          },
          {
            name: "Igalamela-Odolu",
            shortCode: "IGALO",
          },
          {
            name: "Ijumu",
            shortCode: "IJU",
          },
          {
            name: "Kabba/Bunu",
            shortCode: "KABB",
          },
          {
            name: "Koton Karfe",
            shortCode: "KOTO",
          },
          {
            name: "Lokoja",
            shortCode: "LOKO",
          },
          {
            name: "Mopa-Muro",
            shortCode: "MOPA",
          },
          {
            name: "Ofu",
            shortCode: "OF",
          },
          {
            name: "Ogori/Magongo",
            shortCode: "OGOR",
          },
          {
            name: "Okehi",
            shortCode: "OKE",
          },
          {
            name: "Okene",
            shortCode: "AJAO",
          },
          {
            name: "Olamaboro",
            shortCode: "OLAM",
          },
          {
            name: "Omala",
            shortCode: "OMA",
          },
          {
            name: "Yagba East",
            shortCode: "YAGBE",
          },
          {
            name: "Yagba West",
            shortCode: "YAGBW",
          },
        ],
      },

      {
        name: "Kwara",
        shortCode: "KW",
        lga: [
          {
            name: "Asa",
            shortCode: "ASA",
          },
          {
            name: "Baruten",
            shortCode: "BART",
          },
          {
            name: "Edu",
            shortCode: "ED",
          },
          {
            name: "Ekiti",
            shortCode: "EKI",
          },
          {
            name: "Ifelodun",
            shortCode: "IFEL",
          },
          {
            name: "Ilorin East",
            shortCode: "ILORE",
          },
          {
            name: "Ilorin South	",
            shortCode: "ILORS",
          },
          {
            name: "Ilorin West",
            shortCode: "ILORW",
          },
          {
            name: "Isin",
            shortCode: "IS",
          },
          {
            name: "Irepodun",
            shortCode: "IREP",
          },
          {
            name: "Kaiama",
            shortCode: "KAIA",
          },
          {
            name: "Moro",
            shortCode: "MOR",
          },
          {
            name: "Offa",
            shortCode: "OFF",
          },
          {
            name: "Oke Ero",
            shortCode: "PKE",
          },
          {
            name: "Oyun",
            shortCode: "OYU",
          },
          {
            name: "Pategi",
            shortCode: "PATE",
          },
        ],
      },

      {
        name: "Lagos",
        shortCode: "LA",
        lga: [
          {
            name: "Agege",
            shortCode: "AGEG",
          },
          {
            name: "Alimosho",
            shortCode: "ALIM",
          },
          {
            name: "Ajeromi-Ifelodun",
            shortCode: "AJEI",
          },
          {
            name: "Kosofe",
            shortCode: "KOSO",
          },
          {
            name: "Mushin",
            shortCode: "MUSH",
          },
          {
            name: "Oshodi-Isolo",
            shortCode: "OSHOD",
          },
          {
            name: "Ojo",
            shortCode: "OJ",
          },
          {
            name: "Ikorodu",
            shortCode: "IKOR",
          },
          {
            name: "Surulere",
            shortCode: "SURU",
          },
          {
            name: "Ifako-Ijaiye",
            shortCode: "IFAI",
          },
          {
            name: "Somolu",
            shortCode: "SOMO",
          },
          {
            name: "Amuwo-Odofin",
            shortCode: "AMUWO",
          },
          {
            name: "Lagos Mainland",
            shortCode: "LAGM",
          },
          {
            name: "Ikeja",
            shortCode: "IKE",
          },
          {
            name: "Eti-Osa",
            shortCode: "ETIO",
          },
          {
            name: "Badagry",
            shortCode: "BADA",
          },
          {
            name: "Apapa",
            shortCode: "APAP",
          },
          {
            name: "Lagos Island",
            shortCode: "LAGI",
          },
          {
            name: "Epe",
            shortCode: "EP",
          },
          {
            name: "Ibeju-Lekki",
            shortCode: "IBEL",
          },
        ],
      },

      {
        name: "Nasarawa",
        shortCode: "NA",
        lga: [
          {
            name: "Akwanga",
            shortCode: "AKWA",
          },
          {
            name: "Doma, Nigeria",
            shortCode: "DOMAN",
          },
          {
            name: "Eggon LGA",
            shortCode: "EGG",
          },
          {
            name: "Egon, Nigeria",
            shortCode: "EGN",
          },
          {
            name: "Karu LGA",
            shortCode: "KAR",
          },
          {
            name: "Keana",
            shortCode: "KEA",
          },
          {
            name: "Keffi",
            shortCode: "KEFF",
          },
          {
            name: "Kokona",
            shortCode: "KOKO",
          },
          {
            name: "Lafia",
            shortCode: "LAF",
          },
          {
            name: "Nasarawa, Nasarawa State",
            shortCode: "NASS",
          },
          {
            name: "Obi, Nasarawa State",
            shortCode: "OBI",
          },
          {
            name: "Toto, Nigeria",
            shortCode: "TOT",
          },
          {
            name: "Wamba, Nigeria",
            shortCode: "WAMB",
          },
        ],
      },

      {
        name: "Niger",
        shortCode: "NI",
        lga: [
          {
            name: "Agaie",
            shortCode: "AGAI",
          },
          {
            name: "Agwara",
            shortCode: "AGWA",
          },
          {
            name: "Bida",
            shortCode: "BID",
          },
          {
            name: "Borgu",
            shortCode: "BORG",
          },
          {
            name: "Bosso",
            shortCode: "BOSS",
          },
          {
            name: "Chanchaga",
            shortCode: "CHAN",
          },
          {
            name: "Edati",
            shortCode: "EDA",
          },
          {
            name: "Gbako",
            shortCode: "GBAK",
          },
          {
            name: "Gurara",
            shortCode: "GURA",
          },
          {
            name: "Katcha",
            shortCode: "KATC",
          },
          {
            name: "Kontagora",
            shortCode: "KOTAN",
          },
          {
            name: "Lapai",
            shortCode: "LAPA",
          },
          {
            name: "Lavun",
            shortCode: "LAV",
          },
          {
            name: "Magama",
            shortCode: "MAGA",
          },
          {
            name: "Mariga",
            shortCode: "MARG",
          },
          {
            name: "Mashegu",
            shortCode: "MASH",
          },
          {
            name: "Mokwa",
            shortCode: "MOK",
          },
          {
            name: "Munya",
            shortCode: "MUN",
          },
          {
            name: "Paikoro",
            shortCode: "PAIK",
          },
          {
            name: "Rafi",
            shortCode: "RAFI",
          },
          {
            name: "Rijau",
            shortCode: "RIJA",
          },
          {
            name: "Shiroro",
            shortCode: "SHIR",
          },
          {
            name: "Suleja",
            shortCode: "SUL",
          },
          {
            name: "Tafa",
            shortCode: "TAF",
          },
          {
            name: "Wushishi",
            shortCode: "WUSH",
          },
        ],
      },

      {
        name: "Ogun",
        shortCode: "OG",
        lga: [
          {
            name: "Abeokuta North",
            shortCode: "ABEON",
          },
          {
            name: "Abeokuta South",
            shortCode: "ABEOS",
          },
          {
            name: "Ado-Odo/Ota",
            shortCode: "ADO",
          },
          {
            name: "Ewekoro",
            shortCode: "EWEK",
          },
          {
            name: "Ifo",
            shortCode: "IF",
          },
          {
            name: "Ijebu East",
            shortCode: "IJEE",
          },
          {
            name: "Ijebu North",
            shortCode: "IJEN",
          },
          {
            name: "Ijebu North East",
            shortCode: "IJENE",
          },
          {
            name: "Ijebu Ode",
            shortCode: "IJEO",
          },
          {
            name: "Ikenne",
            shortCode: "IKEN",
          },
          {
            name: "Imeko Afon",
            shortCode: "IMEK",
          },
          {
            name: "Ipokia",
            shortCode: "IPOK",
          },
          {
            name: "Obafemi Owode",
            shortCode: "OBAO",
          },
          {
            name: "Odogbolu",
            shortCode: "ODOG",
          },
          {
            name: "Odeda",
            shortCode: "ODED",
          },
          {
            name: "Ogun Waterside",
            shortCode: "OGUW",
          },
          {
            name: "Remo North",
            shortCode: "REMN",
          },
          {
            name: "Sagamu",
            shortCode: "SAG",
          },
          {
            name: "Yewa North",
            shortCode: "YEW",
          },
          {
            name: "Yewa South",
            shortCode: "YEW",
          },
        ],
      },

      {
        name: "Ondo",
        shortCode: "ON",
        lga: [
          {
            name: "Akoko North-East ",
            shortCode: "AKON",
          },
          {
            name: "Akoko North-West",
            shortCode: "AKNW",
          },
          {
            name: "Akoko South-East ",
            shortCode: "AKSE",
          },
          {
            name: "Akoko South-West",
            shortCode: "AKSW",
          },
          {
            name: "Akure North",
            shortCode: "AKN",
          },
          {
            name: "Akure South",
            shortCode: "AKS",
          },
          {
            name: "Ese Odo",
            shortCode: "ESO",
          },
          {
            name: "Idanre",
            shortCode: "IDAN",
          },
          {
            name: "Ifedore",
            shortCode: "IFED",
          },
          {
            name: "Ilaje",
            shortCode: "ILAJ",
          },
          {
            name: "AKIle Oluji/OkeigboOK",
            shortCode: "AKIO",
          },
          {
            name: "Irele ",
            shortCode: "IRE",
          },
          {
            name: "Odigbo",
            shortCode: "ODIG",
          },
          {
            name: "Okitipupa ",
            shortCode: "OKIT",
          },
          {
            name: "Ondo East ",
            shortCode: "ONDE",
          },
          {
            name: "Ondo West ",
            shortCode: "ONDW",
          },
          {
            name: "Ose ",
            shortCode: "OS",
          },
          {
            name: "Owo",
            shortCode: "OW",
          },
        ],
      },

      {
        name: "Osun",
        shortCode: "OS",
        lga: [
          {
            name: "Aiyedire",
            shortCode: "AIYE",
          },
          {
            name: "Aiyedaade",
            shortCode: "AIYD",
          },
          {
            name: "Atakunmosa East",
            shortCode: "ATAKE",
          },
          {
            name: "Atakunmosa West",
            shortCode: "ATAKW",
          },
          {
            name: "Boluwaduro",
            shortCode: "BOLU",
          },
          {
            name: "Boripe",
            shortCode: "BOR",
          },
          {
            name: "Ede North",
            shortCode: "EDEN",
          },
          {
            name: "Ede South",
            shortCode: "EDES",
          },
          {
            name: "Egbedore",
            shortCode: "EGBE",
          },
          {
            name: "Ejigbo",
            shortCode: "EJIG",
          },
          {
            name: "Ife Central",
            shortCode: "IFEC",
          },
          {
            name: "Ife East",
            shortCode: "IFEE",
          },
          {
            name: "Ife North",
            shortCode: "IFEN",
          },
          {
            name: "Ife South",
            shortCode: "IFES",
          },
          {
            name: "Ifedayo",
            shortCode: "IFED",
          },
          {
            name: "Ifelodun, Osun State",
            shortCode: "IFEL",
          },
          {
            name: "Ila, Osun",
            shortCode: "ILAO",
          },
          {
            name: "Ilesa East",
            shortCode: "ILEE",
          },
          {
            name: "Ilesa West",
            shortCode: "ILEW",
          },
          {
            name: "Irepodun, Osun",
            shortCode: "IREPO",
          },
          {
            name: "Irewole",
            shortCode: "IREW",
          },
          {
            name: "Isokan",
            shortCode: "ISOK",
          },
          {
            name: "Iwo, Osun",
            shortCode: "IWOO",
          },
          {
            name: "Obokun",
            shortCode: "OBOK",
          },
          {
            name: "Odo Otin",
            shortCode: "ODOO",
          },
          {
            name: "Ola Oluwa",
            shortCode: "OLAO",
          },
          {
            name: "Olorunda",
            shortCode: "OLOR",
          },
          {
            name: "Oriade",
            shortCode: "ORIA",
          },
          {
            name: "Orolu",
            shortCode: "OROL",
          },
          {
            name: "Osogbo",
            shortCode: "OSOG",
          },
        ],
      },

      {
        name: "Oyo",
        shortCode: "OY",
        lga: [
          {
            name: "Afijio",
            shortCode: "AFIJ",
          },
          {
            name: "Akinyele",
            shortCode: "AKIN",
          },
          {
            name: "Atiba",
            shortCode: "ATI",
          },
          {
            name: "Atisbo",
            shortCode: "ATIS",
          },
          {
            name: "Egbeda",
            shortCode: "EGBE",
          },
          {
            name: "Ibadan North",
            shortCode: "IBN",
          },
          {
            name: "Ibadan North-East",
            shortCode: "IBNE",
          },
          {
            name: "Ibadan North-West",
            shortCode: "IBNW",
          },
          {
            name: "Ibadan South-East",
            shortCode: "IBSE",
          },
          {
            name: "Ibadan South-West",
            shortCode: "IBSW",
          },
          {
            name: "Ibarapa Central",
            shortCode: "IBAC",
          },
          {
            name: "Ibarapa East",
            shortCode: "IBE",
          },
          {
            name: "Ibarapa North",
            shortCode: "IBAN",
          },
          {
            name: "Ido, Nigeria",
            shortCode: "IDON",
          },
          {
            name: "Irepo",
            shortCode: "IRE",
          },
          {
            name: "Iseyin",
            shortCode: "ISEY",
          },
          {
            name: "Itesiwaju",
            shortCode: "ITES",
          },
          {
            name: "Iwajowa",
            shortCode: "IWAJ",
          },
          {
            name: "Kajola",
            shortCode: "KAJO",
          },
          {
            name: "Lagelu, Oyo",
            shortCode: "LAGO",
          },
          {
            name: "Ogbomosho North",
            shortCode: "OGBN",
          },
          {
            name: "Ogbomosho South",
            shortCode: "OGBS",
          },
          {
            name: "Ogo Oluwa",
            shortCode: "OGOO",
          },
          {
            name: "Olorunsogo",
            shortCode: "OLOR",
          },
          {
            name: "Oluyole, Nigeria",
            shortCode: "OLUN",
          },
          {
            name: "Ona Ara",
            shortCode: "ONAA",
          },
          {
            name: "Orelope",
            shortCode: "OREL",
          },
          {
            name: "Ori Ire",
            shortCode: "ORII",
          },
          {
            name: "Oyo East",
            shortCode: "OYOE",
          },
          {
            name: "Oyo West",
            shortCode: "OYOW",
          },
          {
            name: "Saki East",
            shortCode: "SAKE",
          },
          {
            name: "Saki West",
            shortCode: "SAKW",
          },
          {
            name: "Surulere, Oyo State",
            shortCode: "SURUL",
          },
        ],
      },

      {
        name: "Plateau",
        shortCode: "PL",
        lga: [
          {
            name: "Barkin Ladi",
            shortCode: "BARL",
          },
          {
            name: "Bassa",
            shortCode: "BASS",
          },
          {
            name: "Bokkos",
            shortCode: "BOKK",
          },
          {
            name: "Jos East",
            shortCode: "JOSE",
          },
          {
            name: "Jos North",
            shortCode: "JOSN",
          },
          {
            name: "Jos South",
            shortCode: "JOSS",
          },
          {
            name: "Kanam",
            shortCode: "KANA",
          },
          {
            name: "Kanke",
            shortCode: "KAN",
          },
          {
            name: "Langtang North",
            shortCode: "LANN",
          },
          {
            name: "Langtang South",
            shortCode: "LANS",
          },
          {
            name: "Mangu",
            shortCode: "MAN",
          },
          {
            name: "Mikang",
            shortCode: "MIK",
          },
          {
            name: "Pankshin",
            shortCode: "PANK",
          },
          {
            name: "Qua'an Pan",
            shortCode: "QUAN",
          },
          {
            name: "Riyom",
            shortCode: "RIY",
          },
          {
            name: "Shendam",
            shortCode: "SHE",
          },
          {
            name: "Wase",
            shortCode: "WAS",
          },
        ],
      },

      {
        name: "Rivers",
        shortCode: "RI",
        lga: [
          {
            name: "Abua/Odual",
            shortCode: "ABUA",
          },
          {
            name: "Ahoada East",
            shortCode: "AHOE",
          },
          {
            name: "Ahoada West",
            shortCode: "AHOW",
          },
          {
            name: "Akuku-Toru",
            shortCode: "AKUK",
          },
          {
            name: "Andoni",
            shortCode: "ANDO",
          },
          {
            name: "Asari-Toru",
            shortCode: "ASAT",
          },
          {
            name: "Bonny, Rivers",
            shortCode: "BONR",
          },
          {
            name: "Degema, Rivers",
            shortCode: "DEGR",
          },
          {
            name: "Eleme, Rivers",
            shortCode: "ELER",
          },
          {
            name: "Emohua",
            shortCode: "EMOH",
          },
          {
            name: "Etche",
            shortCode: "ETCH",
          },
          {
            name: "Gokana, Rivers",
            shortCode: "GOKR",
          },
          {
            name: "Ikwerre, Rivers",
            shortCode: "IKWR",
          },
          {
            name: "Khana, Rivers",
            shortCode: "KHAR",
          },
          {
            name: "Obio-Akpor",
            shortCode: "OBOA",
          },
          {
            name: "Ogba–Egbema–Ndoni",
            shortCode: "OGBN",
          },
          {
            name: "Ogu–Bolo",
            shortCode: "OGUB",
          },
          {
            name: "Okrika",
            shortCode: "OKR",
          },
          {
            name: "Omuma",
            shortCode: "OMU",
          },
          {
            name: "Opobo–Nkoro",
            shortCode: "OPON",
          },
          {
            name: "Oyigbo",
            shortCode: "OYI",
          },
          {
            name: "Port Harcourt (local government area)",
            shortCode: "PHLG",
          },
          {
            name: "Tai, Rivers",
            shortCode: "TAIR",
          },
        ],
      },

      {
        name: "Sokoto",
        shortCode: "SO",
        lga: [
          {
            name: "Binji",
            shortCode: "BIN",
          },
          {
            name: "Bodinga",
            shortCode: "BOD",
          },
          {
            name: "Dange Shuni",
            shortCode: "DANG",
          },
          {
            name: "Gada",
            shortCode: "GAD",
          },
          {
            name: "Goronyo",
            shortCode: "GORO",
          },
          {
            name: "Gudu",
            shortCode: "GUD",
          },
          {
            name: "Gwadabawa",
            shortCode: "GWAD",
          },
          {
            name: "Illela",
            shortCode: "ILL",
          },
          {
            name: "Isa",
            shortCode: "IS",
          },
          {
            name: "Kebbe",
            shortCode: "KEBB",
          },
          {
            name: "Kware",
            shortCode: "KWA",
          },
          {
            name: "Rabah",
            shortCode: "RAB",
          },
          {
            name: "Sabon Birni",
            shortCode: "SABB",
          },
          {
            name: "Shagari",
            shortCode: "SHAG",
          },
          {
            name: "Silame",
            shortCode: "SIL",
          },
          {
            name: "Sokoto North",
            shortCode: "SOKN",
          },
          {
            name: "Sokoto South",
            shortCode: "SOKS",
          },
          {
            name: "Tambuwal",
            shortCode: "TAMB",
          },
          {
            name: "Tangaza",
            shortCode: "TANG",
          },
          {
            name: "Tureta",
            shortCode: "TUR",
          },
          {
            name: "Wamako",
            shortCode: "WAMA",
          },
          {
            name: "Wurno",
            shortCode: "WUR",
          },
          {
            name: "Yabo",
            shortCode: "YAB",
          },
        ],
      },

      {
        name: "Taraba",
        shortCode: "TR",
        lga: [
          {
            name: "Ardo Kola",
            shortCode: "ARDO",
          },
          {
            name: "Bali",
            shortCode: "BAL",
          },
          {
            name: "Donga",
            shortCode: "DON",
          },
          {
            name: "Gashaka",
            shortCode: "GASH",
          },
          {
            name: "Gassol",
            shortCode: "GAS",
          },
          {
            name: "Ibi",
            shortCode: "IB",
          },
          {
            name: "Jalingo",
            shortCode: "JAL",
          },
          {
            name: "Karim Lamido",
            shortCode: "KARL",
          },
          {
            name: "Kurmi",
            shortCode: "KUR",
          },
          {
            name: "Lau",
            shortCode: "LA",
          },
          {
            name: "Sardauna",
            shortCode: "SARD",
          },
          {
            name: "Takum",
            shortCode: "TAK",
          },
          {
            name: "Ussa",
            shortCode: "US",
          },
          {
            name: "Wukari",
            shortCode: "WUK",
          },
          {
            name: "Yorro",
            shortCode: "YOR",
          },
          {
            name: "Zing",
            shortCode: "ZIN",
          },
        ],
      },
      {
        name: "Yobe",
        shortCode: "YB",
        lga: [
          {
            name: "Bade",
            shortCode: "BAD",
          },
          {
            name: "Bursari",
            shortCode: "BUR",
          },
          {
            name: "Damaturu",
            shortCode: "DAMA",
          },
          {
            name: "Geidam",
            shortCode: "GEID",
          },
          {
            name: "Gujba",
            shortCode: "GUJ",
          },
          {
            name: "Gulani",
            shortCode: "GULA",
          },
          {
            name: "Fika",
            shortCode: "FIK",
          },
          {
            name: "Fune",
            shortCode: "FUN",
          },
          {
            name: "Jakusko",
            shortCode: "JAKU",
          },
          {
            name: "Karasuwa",
            shortCode: "KARA",
          },
          {
            name: "Machina",
            shortCode: "MACH",
          },
          {
            name: "Nangere",
            shortCode: "NANG",
          },
          {
            name: "Nguru",
            shortCode: "NGU",
          },
          {
            name: "Potiskum",
            shortCode: "POT",
          },
          {
            name: "Tarmuwa",
            shortCode: "TARM",
          },
          {
            name: "Yunusari",
            shortCode: "YUN",
          },
          {
            name: "Yusufari",
            shortCode: "YUS",
          },
        ],
      },
      {
        name: "Zamfara",
        shortCode: "ZA",
        lga: [
          {
            name: "Anka",
            shortCode: "ANK",
          },
          {
            name: "Bakura",
            shortCode: "BAK",
          },
          {
            name: "Birnin",
            shortCode: "BIRN",
          },
          {
            name: "Bukkuyum",
            shortCode: "BUKK",
          },
          {
            name: "Bungudu",
            shortCode: "BUNG",
          },
          {
            name: "Chafe",
            shortCode: "CHA",
          },
          {
            name: "Gummi",
            shortCode: "GUM",
          },
          {
            name: "Gusau",
            shortCode: "GUS",
          },
          {
            name: "Kaura Namoda",
            shortCode: "KAUN",
          },
          {
            name: "Maradun",
            shortCode: "MAR",
          },
          {
            name: "Maru",
            shortCode: "MAR",
          },
          {
            name: "Shinkafi",
            shortCode: "SHIN",
          },
          {
            name: "Talata Mafara",
            shortCode: "TALA",
          },
          {
            name: "Zurmi",
            shortCode: "ZUR",
          },
        ],
      },
    ],
  },
  {
    countryName: "Kenya",
    countryShortCode: "KE",
    states: [
      {
        name: "Nairobi County",
        shortCode: "NAI",
        lga: [
          {
            name: "Nairobi city",
            shortCode: "NAIRC",
          },
        ],
      },

      {
        name: "Kiambu County",
        shortCode: "NEA",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kirinyaga County",
        shortCode: "KIRC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Muranga District",
        shortCode: "MURD",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Nyandarua District",
        shortCode: "NYAD",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Nyeri County",
        shortCode: "NYEC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Murang'a County",
        shortCode: "MURC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kilifi County",
        shortCode: "KILC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kwale County",
        shortCode: "KWAC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Lamu County",
        shortCode: "LAMC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Mombasa County",
        shortCode: "MOMC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Taita-Taveta District",
        shortCode: "TAID",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Tana River County",
        shortCode: "TANAC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Embu County",
        shortCode: "EMBC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Isiolo County",
        shortCode: "ISIC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kitui County",
        shortCode: "KITC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Makueni County",
        shortCode: "MAKC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Machakos County",
        shortCode: "MACC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Marsabit County",
        shortCode: "MARC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Meru County",
        shortCode: "MERC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Tharaka-Nithi County",
        shortCode: "THC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Moyale County",
        shortCode: "MOYC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Garissa County",
        shortCode: "GARC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Mandera County",
        shortCode: "MANC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Wajir County",
        shortCode: "WAJC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Homa Bay County",
        shortCode: "HBC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kisii County",
        shortCode: "KIC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Nyamira County",
        shortCode: "NYAC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kisumu County",
        shortCode: "KISC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Migori County",
        shortCode: "MIGC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Siaya County",
        shortCode: "SIAC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Rift Valley Province",
        shortCode: "RVP",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Bomet County",
        shortCode: "BOMC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Elgeyo Marakwet County",
        shortCode: "EMC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kajiado Count",
        shortCode: "KAJC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kericho County",
        shortCode: "KERC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Laikipia County",
        shortCode: "LAIC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Nakuru County",
        shortCode: "NAKC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Nandi Count",
        shortCode: "NANC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Narok County",
        shortCode: "NARC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Samburu County",
        shortCode: "SAMC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Trans-Nzoia County",
        shortCode: "TNC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Turkana County",
        shortCode: "TUC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Uasin Gishu County",
        shortCode: "UGC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "West Pokot County",
        shortCode: "WPC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Bungoma District",
        shortCode: "BUD",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Busia County",
        shortCode: "BUC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Kakamega County",
        shortCode: "KAC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
      {
        name: "Vihiga County",
        shortCode: "VIHC",
        lga: [
          {
            name: "Fafi",
            shortCode: "FAF",
          },
        ],
      },
    ],
  },
  {
    countryName: "Ghana",
    countryShortCode: "GH",
    states: [
      {
        name: "Accra",
        shortCode: "ACC",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },

      {
        name: "Kumasi",
        shortCode: "KUM",
        lga: [
          {
            name: "	Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Tamale",
        shortCode: "TAM",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Sekondi-Takoradi",
        shortCode: "SET",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Ashaiman",
        shortCode: "ASHA",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Sunyani",
        shortCode: "SUN",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Cape Coast",
        shortCode: "CAP",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Obuasi",
        shortCode: "OBU",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Teshie",
        shortCode: "TES",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Tema",
        shortCode: "TEM",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Madina",
        shortCode: "MAD",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Koforidua",
        shortCode: "KOF",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Wa",
        shortCode: "WA",
        lga: [
          {
            name: "Upper West",
            shortCode: "UPW",
          },
        ],
      },
      {
        name: "Techiman",
        shortCode: "TEC",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Ho",
        shortCode: "HO",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Nungua",
        shortCode: "NUN",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Lashibi",
        shortCode: "LAS",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Dome",
        shortCode: "DOM",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Tema New Town",
        shortCode: "TNT",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Gbawe",
        shortCode: "GBA",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Oduponkpehe",
        shortCode: "ODU",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Ejura",
        shortCode: "EJU",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Taifa",
        shortCode: "TAI",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "GRA",
          },
        ],
      },
      {
        name: "Bawku",
        shortCode: "BAW",
        lga: [
          {
            name: "Upper East",
            shortCode: "UPE",
          },
        ],
      },
      {
        name: "Aflao",
        shortCode: "AFL",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Agona Swedru",
        shortCode: "AGS",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Bolgatanga",
        shortCode: "BOL",
        lga: [
          {
            name: "Upper East",
            shortCode: "UPE",
          },
        ],
      },
      {
        name: "Tafo",
        shortCode: "TAF",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Berekum",
        shortCode: "BER",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Nkawkaw",
        shortCode: "NKA",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Akim Oda",
        shortCode: "AKO",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Winneba",
        shortCode: "WIN",
        lga: [
          {
            name: "	Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Hohoe",
        shortCode: "HOH",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Yendi",
        shortCode: "YEN",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Suhum",
        shortCode: "SUH",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Kintampo",
        shortCode: "KINT",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Adenta East",
        shortCode: "ADE",
        lga: [
          {
            name: "Greater Accra",
            shortCode: "REA",
          },
        ],
      },
      {
        name: "Nsawam",
        shortCode: "NSA",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Mampong",
        shortCode: "MAM",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Konongo",
        shortCode: "KON",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Asamankese",
        shortCode: "ASA",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Wenchi",
        shortCode: "WEN",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Savelugu",
        shortCode: "SAV",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Agogo",
        shortCode: "AGO",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Anloga",
        shortCode: "ANL",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Prestea",
        shortCode: "PRE",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Effiakuma",
        shortCode: "EFF",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Tarkwa",
        shortCode: "TAR",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Elmina",
        shortCode: "ELM",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Dunkwa-on-Offin",
        shortCode: "DOO",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Begoro",
        shortCode: "BEG",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Kpandu",
        shortCode: "KPA",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Kintampo",
        shortCode: "KIN",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Navrongo",
        shortCode: "NAV",
        lga: [
          {
            name: "Upper East",
            shortCode: "UPE",
          },
        ],
      },
      {
        name: "Axim",
        shortCode: "AXI",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Apam",
        shortCode: "AP",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Salaga",
        shortCode: "SAL",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Saltpond",
        shortCode: "SALT",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Akwatia",
        shortCode: "AKW",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Shama",
        shortCode: "SHA",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Keta",
        shortCode: "KET",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Nyakrom",
        shortCode: "NYA",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Bibiani",
        shortCode: "BIB",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Somanya",
        shortCode: "SOM",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Foso",
        shortCode: "FOS",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Nyankpala",
        shortCode: "NYAN",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Aburi",
        shortCode: "ABU",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Mumford",
        shortCode: "MUM",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Bechem",
        shortCode: "BEC",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Duayaw Nkwanta",
        shortCode: "DUN",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Kade",
        shortCode: "KAD",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Anomabu",
        shortCode: "ANO",
        lga: [
          {
            name: "Central",
            shortCode: "CEN",
          },
        ],
      },
      {
        name: "Akropong",
        shortCode: "AKR",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Kete-Krachi",
        shortCode: "KEK",
        lga: [
          {
            name: "Volta",
            shortCode: "VOL",
          },
        ],
      },
      {
        name: "Kibi",
        shortCode: "KIB",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Kpandae",
        shortCode: "KPAN",
        lga: [
          {
            name: "Northern",
            shortCode: "NOR",
          },
        ],
      },
      {
        name: "Mpraeso",
        shortCode: "MPR",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Akim Swedru",
        shortCode: "AKS",
        lga: [
          {
            name: "Eastern",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Aboso",
        shortCode: "ABO",
        lga: [
          {
            name: "Western",
            shortCode: "WES",
          },
        ],
      },
      {
        name: "Bekwai",
        shortCode: "BEK",
        lga: [
          {
            name: "Ashanti",
            shortCode: "ASH",
          },
        ],
      },
      {
        name: "Drobo",
        shortCode: "DRO",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
      {
        name: "Banda Ahenkro",
        shortCode: "BAA",
        lga: [
          {
            name: "Brong-Ahafo",
            shortCode: "BRA",
          },
        ],
      },
    ],
  },
  {
    countryName: "South Africa",
    countryShortCode: "SA",
    states: [
      {
        name: "Johannesburg",
        shortCode: "JHB",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Vereeniging",
        shortCode: "VER",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GUA",
          },
        ],
      },
      {
        name: "Pietermaritzburg",
        shortCode: "PIT",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWN",
          },
        ],
      },
      {
        name: "Pretoria",
        shortCode: "PRE",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Durban",
        shortCode: "DUR",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWN",
          },
        ],
      },
      {
        name: "Cape Town",
        shortCode: "CAT",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Welkom",
        shortCode: "WEL",
        lga: [
          {
            name: "Free State",
            shortCode: "FRS",
          },
        ],
      },
      {
        name: "East London",
        shortCode: "EAL",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Randburg",
        shortCode: "RAN",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Roodepoort",
        shortCode: "ROO",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Port Elizabeth",
        shortCode: "POE",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Bloemfontein",
        shortCode: "BLOE",
        lga: [
          {
            name: "Free State",
            shortCode: "FRE",
          },
        ],
      },
      {
        name: "Centurion",
        shortCode: "CEN",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Springs",
        shortCode: "SPR",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Sandton",
        shortCode: "SAN",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Polokwane",
        shortCode: "POL",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Klerksdorp",
        shortCode: "KLE",
        lga: [
          {
            name: "North West",
            shortCode: "NRW",
          },
        ],
      },
      {
        name: "Rustenburg",
        shortCode: "RUS",
        lga: [
          {
            name: "North West",
            shortCode: "NRW",
          },
        ],
      },
      {
        name: "Kimberley",
        shortCode: "KIM",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NRC",
          },
        ],
      },
      {
        name: "Bhisho",
        shortCode: "BHI",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Benoni",
        shortCode: "BEN",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "George",
        shortCode: "GEO",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Middelburg",
        shortCode: "MIDD",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
      {
        name: "Vryheid",
        shortCode: "VRY",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWA",
          },
        ],
      },
      {
        name: "Potchefstroom",
        shortCode: "POT",
        lga: [
          {
            name: "North West",
            shortCode: "NRW",
          },
        ],
      },
      {
        name: "Umtata",
        shortCode: "UMT",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Brits",
        shortCode: "BRI",
        lga: [
          {
            name: "North West",
            shortCode: "NRW",
          },
        ],
      },
      {
        name: "Alberton",
        shortCode: "ALB",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Upington",
        shortCode: "UPI",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NRC",
          },
        ],
      },
      {
        name: "Paarl",
        shortCode: "PAA",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Queenstown",
        shortCode: "QUE",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAS",
          },
        ],
      },
      {
        name: "Mmabatho",
        shortCode: "MMA",
        lga: [
          {
            name: "North West",
            shortCode: "NRW",
          },
        ],
      },
      {
        name: "Kroonstad",
        shortCode: "KRO",
        lga: [
          {
            name: "Free State",
            shortCode: "FRE",
          },
        ],
      },
      {
        name: "Uitenhage",
        shortCode: "UIT",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "ESC",
          },
        ],
      },
      {
        name: "Bethal",
        shortCode: "BET",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
      {
        name: "Worcester",
        shortCode: "WOR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Vanderbijlpark",
        shortCode: "VAN",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Grahamstown",
        shortCode: "GRA",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Standerton",
        shortCode: "STA",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
      {
        name: "Brakpan",
        shortCode: "BRAK",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Thohoyandou",
        shortCode: "THO",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Saldanha",
        shortCode: "SAL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Tzaneen",
        shortCode: "TZA",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Graaff-Reinet",
        shortCode: "GRR",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Oudtshoorn",
        shortCode: "OUD",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Mossel Bay",
        shortCode: "MOB",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Port Shepstone",
        shortCode: "POS",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWN",
          },
        ],
      },
      {
        name: "Knysna",
        shortCode: "KNY",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Vryburg",
        shortCode: "VRYB",
        lga: [
          {
            name: "North West",
            shortCode: "NOW",
          },
        ],
      },
      {
        name: "Ladysmith",
        shortCode: "LAS",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWN",
          },
        ],
      },
      {
        name: "Beaufort West",
        shortCode: "BEW",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Aliwal North",
        shortCode: "ALN",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Volksrust",
        shortCode: "EC",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
      {
        name: "Musina",
        shortCode: "MUS",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Vredenburg",
        shortCode: "VRE",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Malmesbury",
        shortCode: "MAL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Lebowakgomo",
        shortCode: "LEB",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Cradock",
        shortCode: "CRA",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "De Aar",
        shortCode: "DEA",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Ulundi",
        shortCode: "ULU",
        lga: [
          {
            name: "KwaZulu-Natal",
            shortCode: "KWN",
          },
        ],
      },
      {
        name: "Jeffrey’s Bay",
        shortCode: "JEB",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Lichtenburg",
        shortCode: "LIC",
        lga: [
          {
            name: "North West",
            shortCode: "NOW",
          },
        ],
      },
      {
        name: "Hermanus",
        shortCode: "HER",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Carletonville",
        shortCode: "CARL",
        lga: [
          {
            name: "Gauteng",
            shortCode: "GAU",
          },
        ],
      },
      {
        name: "Komatipoort",
        shortCode: "KOM",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
      {
        name: "Middelburg",
        shortCode: "MID",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Port Alfred",
        shortCode: "POA",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Swellendam",
        shortCode: "SWE",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Bloemhof",
        shortCode: "BLO",
        lga: [
          {
            name: "North West",
            shortCode: "NOW",
          },
        ],
      },
      {
        name: "Bethlehem",
        shortCode: "BETH",
        lga: [
          {
            name: "Free State",
            shortCode: "FRS",
          },
        ],
      },
      {
        name: "Montagu",
        shortCode: "MON",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Mahikeng",
        shortCode: "MAH",
        lga: [
          {
            name: "North West",
            shortCode: "NOW",
          },
        ],
      },
      {
        name: "Bredasdorp",
        shortCode: "BRE",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Phalaborwa",
        shortCode: "PHA",
        lga: [
          {
            name: "Limpopo",
            shortCode: "LIM",
          },
        ],
      },
      {
        name: "Caledon",
        shortCode: "CAL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Moorreesburg",
        shortCode: "MOO",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Colesberg",
        shortCode: "COL",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Brandfort",
        shortCode: "BRA",
        lga: [
          {
            name: "Free State",
            shortCode: "FRS",
          },
        ],
      },
      {
        name: "Piketberg",
        shortCode: "PIK",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Saint Helena Bay",
        shortCode: "SHB",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Prieska",
        shortCode: "PRI",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Velddrif",
        shortCode: "VEL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Springbok",
        shortCode: "SPRI",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Darling",
        shortCode: "DAR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Kuruman",
        shortCode: "KUR",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Villiersdorp",
        shortCode: "VIL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Tulbagh",
        shortCode: "TUL",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Saron",
        shortCode: "SAR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Clanwilliam",
        shortCode: "CLA",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Citrusdal",
        shortCode: "CIT",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Porterville",
        shortCode: "POR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Kleinmond",
        shortCode: "KEI",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Hopefield",
        shortCode: "HOP",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Vanrhynsdorp",
        shortCode: "VANR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Klawer",
        shortCode: "KLA",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Lambert’s Bay",
        shortCode: "LAB",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Port Saint John’s",
        shortCode: "PSJ",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Carnarvon",
        shortCode: "CAR",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Genadendal",
        shortCode: "GEN",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Riviersonderend",
        shortCode: "RIV",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Onrus",
        shortCode: "ONR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Pofadder",
        shortCode: "POF",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Barrydale",
        shortCode: "BAR",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Steytlerville",
        shortCode: "STE",
        lga: [
          {
            name: "Eastern Cape",
            shortCode: "EAC",
          },
        ],
      },
      {
        name: "Rawsonville",
        shortCode: "RAW",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Fraserburg",
        shortCode: "FRA",
        lga: [
          {
            name: "Northern Cape",
            shortCode: "NOC",
          },
        ],
      },
      {
        name: "Suurbraak",
        shortCode: "SUU",
        lga: [
          {
            name: "Western Cape",
            shortCode: "WEC",
          },
        ],
      },
      {
        name: "Nelspruit",
        shortCode: "NEL",
        lga: [
          {
            name: "Mpumalanga",
            shortCode: "MPU",
          },
        ],
      },
    ],
  },
];

export default options;
