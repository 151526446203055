import React, { useState, useEffect } from "react";
import firebase from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import "firebase/storage";
import { Link, useHistory } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { addMultiMediaRequest, getScouts } from "../../actions/surveyActions";

const RequestVideo = ({ location }) => {
  const { request } = location.state || {};
  const [file, setFile] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoDuration, setVideoDuration] = useState();
  const [error, setError] = useState("");
  let history = useHistory();

  const fetchScout = useSelector((state) => state.fetchScout);

  const { data: scoutData } = fetchScout;

  const dispatch = useDispatch();

  //Video Duration Helper Function
  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const uploadVideo = async (e) => {
    let src = URL.createObjectURL(e.target.files[0]);
    const video = await loadVideo(e.currentTarget.files[0]);
    setVideoDuration(video.duration / 60);
    setFile(src);
    //Upload Image To Firebase Storage
    if (video.duration / 60 <= 0.5) {
      const id = uuidv4();
      const videoRef = firebase
        .storage()
        .ref("requests/requestVideos")
        .child(id);
      await videoRef.put(e.target.files[0]);
      videoRef.getDownloadURL().then((url) => {
        setVideoUrl({ id, url });
      });
    }
  };

  const resetFile = async (e) => {
    e.preventDefault();
    if (videoDuration <= 0.5) {
      const videoRef = await firebase
        ?.storage()
        ?.ref("requests/requestVideos")
        ?.child(videoUrl.id);
      videoRef?.delete();
    }
    setFile("");
    setVideoUrl("");
  };

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch]);

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      addMultiMediaRequest({
        requestRef: request?.requestRef,
        scoutRef: scoutData?.data?.phoneNumber,
        mediaType: request?.mediaType,
        mediaUrls: [videoUrl.url],
        authstring:
          window.location.hostname ===
            "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
          window.location.hostname === "localhost"
            ? "55ad4986-b519-4e44-ab0b-890527299af6"
            : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
      })
    )
      .unwrap()
      .then((payload) => {
        if (payload.status === 200) {
          history.push({
            pathname: "/request-submit-success",
            state: {
              request,
            },
          });
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div>
      <Dashboard />
      {file === "" ? (
        <div className="modal">
          <Link
            to="/dashboard"
            className="modal-bg w-inline-block"
            style={{ backdropFilter: "blur(16px)" }}
          ></Link>
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                <strong>{request?.requestName}</strong>
              </h5>
              <div className="padding-top-6 margin-top-6 border-top-2px">
                <div className="upload">
                  <div className="in-upload">
                    You can preview before sending
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-top-6 border-top-2px">
              <label
                htmlFor="formId"
                className="button background-color-brand w-button"
              >
                Upload Video
                <input
                  name=""
                  type="file"
                  id="formId"
                  hidden
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={uploadVideo}
                />
              </label>
              <p className="text-color-2 margin-top-4 margin-bottom-0 text-small">
                Only &nbsp;
                <strong>mp4 &nbsp;</strong>
                &amp;
                <strong>&nbsp; mov &nbsp;</strong>
                files supported. Video must not be longer than 30 seconds.
                <br />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal">
          <Link
            to="/dashboard"
            className="modal-bg w-inline-block"
            style={{ backdropFilter: "blur(16px)" }}
          ></Link>
          <div className="w-embed"></div>

          <form>
            <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
              <div className="modal-section">
                <h5 className="text-color-4 margin-0 weight-normal">
                  <strong>{request?.requestName}</strong>
                </h5>
                <div className="padding-top-6 margin-top-6 border-top-2px">
                  {/* <div className="upload recording"

                > */}
                  <video
                    className="videoPreview"
                    src={file}
                    controls
                    autoPlay
                    loop
                    muted
                  ></video>
                  {/* <button
                    style={{ position: "relative" }}
                    className="button background-color-brand absolute-bottom-left margin-6 padding-4 background-color-red box-shadow-medium w-button"
                    onClick={resetFile}
                    disabled={videoUrl === "" && videoDuration <= 0.5}
                  >
                    <span data-feater="trash-2" className="icon">
                      Delete
                    </span>
                    </button> */}
                  <button
                    className="button-secondary w-button box-shadow-medium"
                    style={{
                      backgroundColor: "#d32062",
                      color: "white",
                      marginTop: "15px",
                    }}
                    onClick={resetFile}
                    disabled={videoUrl === "" && videoDuration <= 0.5}
                  >
                    Delete
                  </button>
                  {/* </div> */}
                </div>
              </div>
              <div className="padding-top-6 border-top-2px flex-space-between margin-top-6 flex-align-center portrait-flex-column portrait-flex-align-start">
                <p className="text-color-2 margin-top-4">
                  Only &nbsp;<strong>mp4&nbsp;</strong>
                  &amp; <strong>mov</strong> files supported
                  <br />
                  {videoDuration > 0.5 ? (
                    <span style={{ color: " #FFCC00" }}>
                      Video must not be longer than 30 seconds, please upload
                      another video.
                    </span>
                  ) : null}
                  <span style={{ color: " #FFCC00" }}>{error}</span>
                </p>
                <button
                  type="submit"
                  className="button-secondary w-button"
                  disabled={videoDuration > 0.5 || videoUrl === ""}
                  onClick={onSubmit}
                >
                  {videoUrl !== "" ? "Submit" : "Uploading"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default RequestVideo;
