import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaign,
  getQuestion,
  addResponse,
  getScouts,
  setEndCampaign,
} from "../../actions/surveyActions";
import Loader from "../Loader/loader";

const Survey = ({ location }) => {
  const history = useHistory();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [, setSelectOption] = useState("");
  const [active, setActive] = useState(false);
  const [selectMultipleOption, setMultipleSelectOption] = useState([]);
  const [random, setRandom] = useState(0);
  const [, setAcquiredCredits] = useState(0);
  const [error, setError] = useState();
  const [otherValue, setOtherValue] = useState();

  const { campaigns } = location.state || {};
  const fetchScout = useSelector((state) => state.fetchScout);
  const fetchQuestion = useSelector((state) => state.fetchQuestion);

  const { data: questions } = fetchQuestion;
  const { data: scoutData } = fetchScout;

  const dispatch = useDispatch();

  // let campaignRef = [];
  useEffect(() => {
    dispatch(getCampaign());
    dispatch(getScouts());
    dispatch(getQuestion({ docRef: campaigns?.campaignRef }));
  }, [dispatch, campaigns?.campaignRef]);

  //Function for multiple select options
  const addOrRemoveInterests = (name) => {
    const newInterest = [...selectMultipleOption];
    const index = newInterest.indexOf(name);

    if (index === -1) {
      newInterest.push(name);
    } else {
      newInterest.splice(index, 1);
    }

    setMultipleSelectOption(newInterest);
    setActive(false);
  };

  const skip = () => {
    setOtherValue();
    setMultipleSelectOption([]);
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);

    if (nextQuestion === questions?.data?.questions?.length) {
      setShowEndScreen(true);
    }
  };

  //Submit Response
  const handleAnswerButtonClick = (index) => {
    const nextQuestion = currentQuestion + 1;
    setAcquiredCredits((prevCount) => prevCount + 1);
    setActive(false);
    setOtherValue();
    setMultipleSelectOption([]);

    if (nextQuestion === questions?.data?.questions?.length) {
      setShowEndScreen(true);
      // var min = 1;
      // var max = 20;
      // and the formula is:
      // var random = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandom(10);
    }

    //Call Endpoint
    if (nextQuestion <= questions?.data?.questions?.length) {
      const selectOptionPayload = [
        {
          option:
            active?.option_type === "unspecified" ? otherValue : active?.option,
          option_type: active?.option_type,
          id: active?.id,
        },
      ];
      const selectCheckedOptionPayload = [
        {
          option:
            active?.option_type === "unspecified" ? otherValue : active?.option,
          option_type: active?.option_type,
          id: active?.id,
        },
      ];

      const unspecified =
        selectCheckedOptionPayload[0]?.option_type === "unspecified"
          ? selectCheckedOptionPayload
          : [];

      const mergeOthersAndMultipleSelectArrays =
        selectMultipleOption.concat(unspecified);

      const freeFormStatus =
        questions?.data?.questions[currentQuestion]?.response_type ===
        "freeform";

      console.log(freeFormStatus);

      dispatch(
        addResponse({
          authtoken:
            window.location.hostname ===
              "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
            window.location.hostname === "localhost"
              ? "55ad4986-b519-4e44-ab0b-890527299af6"
              : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
          cRef: campaigns?.campaignRef,
          qRef: questions?.data?.questions[currentQuestion]?.questionRef,
          sRef: scoutData?.data?.phoneNumber,
          questionType:
            questions?.data?.questions[currentQuestion]?.response_type,
          freeform_response:
            questions?.data?.questions[currentQuestion]?.response_type ===
            "freeform"
              ? otherValue
              : null,
          ...(freeFormStatus === false && {
            responseOptions:
              mergeOthersAndMultipleSelectArrays.length > 0
                ? mergeOthersAndMultipleSelectArrays
                : selectOptionPayload !== ""
                ? selectOptionPayload
                : null,
          }),
          clientRef: campaigns?.clientRef,
        })
      );
    }

    //Skip Logic for "JUMP"
    if (active?.skip_logic?.action_type === "JUMP") {
      let jumpQuestionRef = active?.skip_logic?.jump_questionRef;

      let question = questions?.data?.questions.find(
        (x) => x.questionRef === jumpQuestionRef
      );
      setCurrentQuestion(question.sequence - 1);
    }

    //Skip Logic for "NONE  || NULL" Option

    if (
      active?.skip_logic?.action_type === "NONE" ||
      active?.skip_logic?.action_type === null
    ) {
      const nextQuestion = currentQuestion + 1;
      setCurrentQuestion(nextQuestion);
    }

    //Skip Logic for "END_SURVEY" Option
    if (active?.skip_logic?.action_type === "END_SURVEY") {
      setShowEndScreen(true);
    }
  };

  //Function to track active class
  function setClassClicked(name) {
    setActive(name);
  }

  //End Campaign Function
  const handleCampaignEnd = () => {
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    dispatch(
      setEndCampaign({
        phoneNumber: scoutData?.data?.phoneNumber,
        campaignRef: campaigns?.campaignRef,
        authstring: string,
      })
    )
      .unwrap()
      .then((campaign) => {
        if (campaign.status === 200) {
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  //End Default Campaign Function
  const handleCampaignEndDefault = () => {
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    dispatch(
      setEndCampaign({
        phoneNumber: scoutData?.data?.phoneNumber,
        campaignRef: campaigns?.campaignRef,
        authstring: string,
      })
    )
      .unwrap()
      .then((campaign) => {
        if (campaign.status === 200) {
          history.push({
            pathname: "/dashboard-demographic-notification",
            state: "survey",
            scoutRef: scoutData?.data?.phoneNumber,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const otherChangeHandler = (event) => {
    setOtherValue(event.target.value);
  };

  return (
    <div>
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <a href="#!" className="w-nav-brand">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-center w-nav-menu"
            >
              <a
                href="/sign-up"
                className="button-secondary button-small disabled margin-right-2 w-button"
              >
                Cash out
              </a>
              <div
                data-hover=""
                data-delay="0"
                className="w-dropdown"
                style={{ maxWidth: "1280px" }}
              >
                <div
                  className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon text-color-4"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <nav
                  className="dropdown right box-shadow-medium w-dropdown-list"
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Settings
                  </a>
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Log out
                  </a>
                </nav>
              </div>
            </nav>
            <div
              className="margin-left-auto w-nav-button"
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      </div>
      <div className="section-in-base background-color-3">
        <div className="container-1280">
          <p className="text-2xl weight-bold">
            0 <span className="text-medium weight-normal">scout credits</span>
          </p>
        </div>
      </div>
      <div className="background-color-white">
        <div className="container-1280">
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="w-tabs"
          >
            <div
              className="page-tabs margin-bottom-6 w-tab-menu"
              role="tablist"
            >
              <a
                data-w-tab="Tab 1"
                className="tab w-inline-block w-tab-link w--current"
                id="w-tabs-0-data-w-tab-0"
                href="#w-tabs-0-data-w-pane-0"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-0"
                aria-selected="true"
              >
                <div className="text-color-4">1 Survey</div>
              </a>
              <a
                data-w-tab="Tab 2"
                className="tab w-inline-block w-tab-link"
                tabIndex="-1"
                id="w-tabs-0-data-w-tab-1"
                href="#w-tabs-0-data-w-pane-1"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-1"
                aria-selected="false"
              >
                <div>
                  <span>0</span> Requests
                </div>
              </a>
            </div>
            <div className="tabs-content w-tab-content">
              <div
                data-w-tab="Tab 1"
                className="grid-3-columns w-tab-pane w--tab-active"
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
              >
                <div className="radius-large background-color-white box-shadow-large padding-6 border-1px">
                  <h4>Earn 9 starting credits with the demographics survey!</h4>
                  <div className="flex-align-center margin-top-6 flex-space-between">
                    <div className="flex-align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-message-square icon text-color-3 margin-right-1"
                        src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                        loading="lazy"
                        alt=""
                      >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      <div className="text-color-2">9</div>
                    </div>
                    <a href="/sign-up" className="button button-small w-button">
                      Start{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right icon margin-left-1"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-w-tab="Tab 2"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        {showEndScreen === false && (
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                {campaigns?.campaignName}{" "}
                {fetchQuestion?.loading === true && <Loader />}
              </h5>

              <div className="flex-justify-center neg-margin margin-top-3">
                {questions?.data?.questions?.length > 0 ? (
                  questions?.data?.questions?.map((question, index) => (
                    <div
                      key={index}
                      className={`progress-bar expand ${
                        index === currentQuestion ? "active" : ""
                      }`}
                    ></div>
                  ))
                ) : (
                  <div className="progress-bar expand "></div>
                )}
              </div>
              <div className="margin-top-12">
                {questions?.data?.questions[currentQuestion].media_type ===
                  "photo" && (
                  <img
                    src={questions?.data?.questions[currentQuestion].media_link}
                    loading="lazy"
                    sizes="(max-width: 767px) 92vw, (max-width: 991px) 69vw, 528.0034790039062px"
                    alt=""
                    className="image-8"
                  />
                )}
                {questions?.data?.questions[currentQuestion].media_type ===
                  "video" && (
                  <iframe
                    width="528"
                    height="300"
                    src={questions?.data?.questions[currentQuestion].media_link}
                    title="YouTube video player"
                    frameBorder="0"
                  ></iframe>
                )}
                <h3 className="text-color-4" style={{ marginTop: "20px" }}>
                  {questions?.data?.questions[currentQuestion]?.question}
                </h3>
                <div className="w-form">
                  {/* NPS Range */}
                  {questions?.data?.questions[currentQuestion]?.options
                    ?.type === "nps" && (
                    <>
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-2"
                        aria-label="Email Form"
                      >
                        {questions?.data?.questions[
                          currentQuestion
                        ]?.options?.options_list?.map((x) => (
                          <div key={x?.id} onClick={() => setClassClicked(x)}>
                            <label className="radio-button-field nps-score w-radio">
                              <div
                                className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button nps w-radio-input${
                                  active === x
                                    ? "w--redirected-checked w--redirected-focus"
                                    : ""
                                }`}
                              ></div>
                              <input
                                onClick={() => setSelectOption(x)}
                                type="radio"
                                name="radio"
                                value="Radio"
                                style={{
                                  opacity: "0",
                                  position: "absolute",
                                  zIndex: "-1",
                                }}
                              />

                              <span
                                className="radio-button-label nps w-form-label"
                                htmlFor="radio-3"
                              >
                                {x?.option}
                              </span>
                            </label>
                          </div>
                        ))}
                      </form>

                      <div className="div-block">
                        <p className="nps-key">
                          Extremely unlikely
                          <span className="text-color-1"></span>
                        </p>
                        <p
                          className="paragraph nps-key"
                          style={{ paddingLeft: "161px" }}
                        >
                          Likely
                          <span className="text-color-1"></span>
                        </p>
                        <p className="nps-key" style={{ paddingLeft: "144px" }}>
                          Extremely likely<span className="text-color-1"></span>
                        </p>
                      </div>
                    </>
                  )}

                  {/* Free Form Question */}
                  {questions?.data?.questions[currentQuestion]?.options
                    ?.type === "freeform" && (
                    <>
                      {questions?.data?.questions[
                        currentQuestion
                      ]?.options?.options_list?.map((x) => (
                        <div className="w-form">
                          <form key={x?.id} onClick={() => setClassClicked(x)}>
                            <textarea
                              maxLength="5000"
                              placeholder="Enter text..."
                              className="form-input width-full w-input"
                              onChange={otherChangeHandler}
                              value={otherValue === undefined ? "" : otherValue}
                            ></textarea>
                          </form>
                        </div>
                      ))}
                    </>
                  )}

                  {/* Multiple Choice Single Select */}
                  {questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "single_select" && (
                    <>
                      {/* Removed Other Option From the available Options */}
                      {questions?.data?.questions[currentQuestion]?.options
                        ?.options_list?.length === 0
                        ? ""
                        : questions?.data?.questions[
                            currentQuestion
                          ]?.options?.options_list
                            .filter(
                              ({ option_type }) =>
                                !option_type.includes("unspecified")
                            )
                            ?.map((x, i) => (
                              <>
                                <form
                                  id="email-form"
                                  name="email-form"
                                  data-name="Email Form"
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div
                                    onClick={() => setSelectOption(x?.option)}
                                  >
                                    <label className="radio-button-field w-radio">
                                      <div
                                        className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button _w-img w-radio-input  ${
                                          active === x
                                            ? "w--redirected-checked w--redirected-focus"
                                            : ""
                                        }`}
                                      ></div>
                                      <input
                                        type="radio"
                                        data-name="Radio 3"
                                        id="radio-3"
                                        name="radio"
                                        value="Radio"
                                        style={{
                                          opacity: "0",
                                          position: "absolute",
                                          zIndex: "-1",
                                        }}
                                      />

                                      {x?.image_link && (
                                        <div className="radio-btn-img">
                                          <img
                                            src={x?.image_link}
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}

                                      <span className="radio-button-label w-form-label">
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              </>
                            ))}

                      {questions?.data?.questions[currentQuestion]?.options
                        ?.options_list[
                        questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length - 1
                      ]?.option_type === "unspecified" ? (
                        <div>
                          <label
                            className=""
                            onClick={() =>
                              setClassClicked(
                                questions?.data?.questions[currentQuestion]
                                  ?.options?.options_list[
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list?.length - 1
                                ]
                              )
                            }
                          >
                            <input
                              type="text"
                              placeholder={
                                questions?.data?.questions[currentQuestion]
                                  ?.options?.options_list[
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list?.length - 1
                                ]?.option
                              }
                              style={{
                                color: `${
                                  active ===
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                    ? "white"
                                    : "white"
                                }`,
                                outline: "none",
                                position: "relative",
                                width: "100%",
                                marginBottom: "4px",
                                padding: "12px",
                                borderRadius: "0.5rem",
                                height: "48px",
                                backgroundColor: `${
                                  active ===
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                    ? "#5428b0"
                                    : "rgba(84, 40, 176, 0.44)"
                                }`,
                                border: `${
                                  active ===
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                    ? "#3898ec"
                                    : "none"
                                }`,
                              }}
                              onChange={otherChangeHandler}
                              value={otherValue === undefined ? "" : otherValue}
                            />
                          </label>
                        </div>
                      ) : null}
                    </>
                  )}

                  {/* Single Select "Yes / No" */}
                  {questions?.data?.questions[currentQuestion]?.options
                    ?.options_list?.length === 2 &&
                  questions?.data?.questions[currentQuestion]?.options?.unit ===
                    null ? (
                    <>
                      {questions?.data?.questions[
                        currentQuestion
                      ]?.options?.options_list?.map((x, i) => (
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          key={x?.id}
                          onClick={() => setClassClicked(x)}
                        >
                          <div onClick={() => setSelectOption(x)}>
                            <label className="radio-button-field w-radio">
                              <div
                                className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input  ${
                                  active === x
                                    ? "w--redirected-checked w--redirected-focus"
                                    : ""
                                }`}
                              ></div>
                              <input
                                type="radio"
                                data-name="Radio 3"
                                id="radio-3"
                                name="radio"
                                value="Radio"
                                style={{
                                  opacity: "0",
                                  position: "absolute",
                                  zIndex: "-1",
                                }}
                              />
                              <span className="radio-button-label w-form-label">
                                {x?.option}
                              </span>
                            </label>
                          </div>
                        </form>
                      ))}
                    </>
                  ) : null}

                  {/* Single Select "Yes / No /Maybe" */}
                  {questions?.data?.questions[currentQuestion]?.options
                    ?.options_list?.length === 3 &&
                  questions?.data?.questions[currentQuestion]?.options?.unit ===
                    null ? (
                    <>
                      {questions?.data?.questions[
                        currentQuestion
                      ]?.options?.options_list?.map((x, i) => (
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          key={x?.id}
                          onClick={() => setClassClicked(x)}
                        >
                          <div onClick={() => setSelectOption(x)}>
                            <label className="radio-button-field w-radio">
                              <div
                                className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input  ${
                                  active === x
                                    ? "w--redirected-checked w--redirected-focus"
                                    : ""
                                }`}
                              ></div>
                              <input
                                type="radio"
                                data-name="Radio 3"
                                id="radio-3"
                                name="radio"
                                value="Radio"
                                style={{
                                  opacity: "0",
                                  position: "absolute",
                                  zIndex: "-1",
                                }}
                              />
                              <span className="radio-button-label w-form-label">
                                {x?.option}
                              </span>
                            </label>
                          </div>
                        </form>
                      ))}
                    </>
                  ) : null}

                  {/* Multiple Choice Multiple Select Changed Backend Condition*/}

                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      1 && (
                      <>
                        {/* Removed Other Option From the available Options */}

                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}

                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}

                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      2 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}

                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      3 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      4 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}

                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      5 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      6 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      7 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}
                  {(questions?.data?.questions[currentQuestion]?.options
                    ?.unit === "multi_select" ||
                    questions?.data?.questions[currentQuestion]?.options
                      ?.unit === null) &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.options_list[0]?.option !== "Yes" &&
                    questions?.data?.questions[currentQuestion]?.options
                      ?.type === "multiplechoice" &&
                    questions?.data?.questions[currentQuestion].sequence ===
                      8 && (
                      <>
                        {/* Removed Other Option From the available Options */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list?.length === 0
                          ? ""
                          : questions?.data?.questions[
                              currentQuestion
                            ]?.options?.options_list
                              .filter(
                                ({ option_type }) =>
                                  !option_type.includes("unspecified")
                              )
                              ?.map((x, i) => (
                                <form
                                  key={x?.id}
                                  onClick={() => setClassClicked(x)}
                                >
                                  <div>
                                    <label className="w-checkbox checkbox-field">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        name="checkbox"
                                        data-name="Checkbox"
                                        className="w-checkbox-input checkbox-2"
                                        value="Checkbox"
                                        onClick={() => addOrRemoveInterests(x)}
                                      />

                                      {x?.image_link && (
                                        <div className="checkbox-img">
                                          <img
                                            src={x?.image_link}
                                            loading="lazy"
                                            sizes="(max-width: 479px) 34vw, 100px"
                                            alt=""
                                            className="image-7"
                                          />
                                        </div>
                                      )}
                                      <span
                                        className="checkbox-label w-form-label"
                                        htmlFor="checkbox"
                                      >
                                        {x?.option}
                                      </span>
                                    </label>
                                  </div>
                                </form>
                              ))}

                        {/* Other Option */}
                        {questions?.data?.questions[currentQuestion]?.options
                          ?.options_list[
                          questions?.data?.questions[currentQuestion]?.options
                            ?.options_list?.length - 1
                        ]?.option_type === "unspecified" ? (
                          <div>
                            <label
                              className=""
                              onClick={() =>
                                setClassClicked(
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]
                                )
                              }
                            >
                              <input
                                type="text"
                                placeholder={
                                  questions?.data?.questions[currentQuestion]
                                    ?.options?.options_list[
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list?.length - 1
                                  ]?.option
                                }
                                style={{
                                  color: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? "black"
                                      : "white"
                                  }`,
                                  outline: "none",
                                  position: "relative",
                                  width: "100%",
                                  marginBottom: "4px",
                                  padding: "12px",
                                  borderRadius: "0.5rem",
                                  height: "48px",
                                  backgroundColor: `${
                                    active ===
                                    questions?.data?.questions[currentQuestion]
                                      ?.options?.options_list[
                                      questions?.data?.questions[
                                        currentQuestion
                                      ]?.options?.options_list?.length - 1
                                    ]
                                      ? ""
                                      : "rgba(84, 40, 176, 0.44)"
                                  }`,
                                  border: "none",
                                }}
                                onChange={otherChangeHandler}
                                value={
                                  otherValue === undefined ? "" : otherValue
                                }
                              />
                            </label>
                          </div>
                        ) : null}
                      </>
                    )}

                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
                <button
                  onClick={() => skip(currentQuestion)}
                  className="button-secondary subtle margin-right-2 w-button"
                >
                  Skip
                </button>
                <button
                  className="button-secondary w-button"
                  onClick={() => {
                    handleAnswerButtonClick(currentQuestion);
                  }}
                  disabled={!active}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {showEndScreen === true && (
          <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
            <div className="modal-section">
              <h5 className="text-color-4 margin-0 weight-normal">
                {campaigns?.campaignName}
              </h5>
              <div className="margin-top-12">
                <h3 className="text-color-4">
                  🎉
                  <br />
                  You’ve completed the {campaigns?.campaignName} Survey!
                </h3>
              </div>

              <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
                {Notification.permission === "default" && random > 15 ? (
                  <>
                    <button
                      onClick={handleCampaignEndDefault}
                      className="button-secondary w-button"
                    >
                      Finish
                    </button>
                  </>
                ) : Notification.permission === "denied" ? (
                  <>
                    <i style={{ float: "right", color: "#FFCC00" }}>
                      Network Error Network Error
                    </i>
                    <button
                      className="button-secondary w-button"
                      onClick={handleCampaignEnd}
                    >
                      Finish
                    </button>
                  </>
                ) : (
                  <>
                    <i style={{ marginRight: "40%", color: "#FFCC00" }}>
                      {error
                        ? "An error has occurred, please try again later"
                        : ""}
                    </i>
                    <button
                      disabled={error}
                      className="button-secondary w-button"
                      onClick={handleCampaignEnd}
                    >
                      Finish
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey;
