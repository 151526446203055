import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import { DemographicData } from "../../DemographicData";
import { useDispatch, useSelector } from "react-redux";
import { getBanks, getStates, getStatesLGA } from "../../actions/surveyActions";
import {
  tribe,
  employmentStatus,
  personalMonthlyIncome,
  houseHoldMonthlyIncome,
  peopleInHousehold,
  childrenInHousehold,
  favoriteTeam,
  soccerFan,
  sportsFan,
  industryAffiliation,
} from "../../actions/demographicActions";
import { APIS } from "../../services/config";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Loader from "../Loader/loader";
import DeleteAccountModal from "./DeleteAccountModal";

const Profile = ({ location }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [stateID, setStateID] = useState();
  const [lgaID, setLGAID] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [authString] = useState(
    window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
      ? "55ad4986-b519-4e44-ab0b-890527299af6"
      : "b8ba31ef-4570-44ff-95af-e80eddf98f82"
  );

  const [industryAffiliationModal, setIndustryAffiliationModal] =
    useState(false);

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const [selectMultipleOption, setMultipleSelectOption] = useState([]);

  const [demoData, setDemoData] = useState([]);

  const [bankDetails, setBankDetails] = useState("");
  //Demographic Survey Variables
  const scoutData = JSON.parse(location?.state);

  const [query, setQuery] = useState("");

  // const [bankName] = useState(scoutData?.data?.data?.bankName);

  const [accountNumber, setAccountNumber] = useState(
    scoutData?.data?.data?.accountNumber
  );

  const [dobValue, setDob] = useState(scoutData?.data?.data?.dob);

  const [genderState, setGenderState] = useState(scoutData?.data?.data?.gender);

  const [maritalStatusState, setMaritalStatusState] = useState(
    scoutData?.data?.data?.maritalStatus
  );

  const [religionStatus, setReligionStatus] = useState(
    scoutData?.data?.data?.religion
  );

  const [tribeStatus, setTribeStatus] = useState(scoutData?.data?.data?.tribe);

  const [stateStatus, setStateStatus] = useState(scoutData?.data?.data?.state);

  const [lgaStatus, setLgaStatus] = useState(
    scoutData?.data?.data?.localGovernmentArea
  );

  const [educationStatus, setEducationStatus] = useState(
    scoutData?.data?.data?.education
  );
  const [employment, setEmployment] = useState(
    scoutData?.data?.data?.employment
  );
  const [personalIncomeStatus, setPersonalIncomeStatus] = useState(
    scoutData?.data?.data?.personalMonthlyIncome
  );
  const [houseHoldMonthlyIncomeStatus, setHouseHoldMonthlyIncomeStatus] =
    useState(scoutData?.data?.data?.householdMonthlyIncome);

  const [peopleInHouseHoldStatus, setPeopleInHouseHoldStatus] = useState(
    scoutData?.data?.data?.peopleInHousehold
  );

  const [childrenInHouseHoldStatus, setChildrenInHouseHoldStatus] = useState(
    scoutData?.data?.data?.childrenInHousehold
  );

  const [industryAffiliationState] = useState(
    scoutData?.data?.data?.industryAffiliations
  );

  const [footballFanStatus, setFootballFanStatus] = useState(
    scoutData?.data?.data?.soccerFan
  );

  const [sportsFanStatus, setSportsFanStatus] = useState(
    scoutData?.data?.data?.sportsFan
  );
  const [favoriteTeamStatus, setFavoriteTeamStatus] = useState(
    scoutData?.data?.data?.favourite_team
  );

  const dispatch = useDispatch();

  let history = useHistory();

  const fetchBanks = useSelector((state) => state.fetchBanks);
  const fetchAllStates = useSelector((state) => state.fetchAllStates);
  const fetchAllStatesLGA = useSelector((state) => state.fetchAllStatesLGA);

  const onClickDropDown = () => setShowDropdown(!showDropdown);

  const {
    data: { data: bankList },
  } = fetchBanks;

  const {
    data: { data: states },
  } = fetchAllStates;

  const {
    data: { data: stateLGA },
  } = fetchAllStatesLGA;

  useEffect(() => {
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
    dispatch(getBanks({ authstring: string }));
  }, [dispatch]);

  //Bank Search Filter
  const filterBank = bankList?.banks?.filter((bank) => {
    return bank.name
      .toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase());
  });

  const accountNumberValue = (e) => {
    setAccountNumber(e.target.value);
  };

  //Demographic Survey Functions
  const dateOfBirthValue = (e) => {
    setDob(e.target.value);
  };

  const genderValue = (e) => {
    setGenderState(e.target.value);
  };

  const maritalStatusValue = (e) => {
    setMaritalStatusState(e.target.value);
  };

  const religionValue = (e) => {
    setReligionStatus(e.target.value);
  };

  const tribeValue = (e) => {
    setTribeStatus(e.target.value);

    dispatch(
      tribe({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        tribe: e.target.value,
      })
    );
  };

  const educationValue = (e) => {
    setEducationStatus(e.target.value);
  };

  const employmentValue = (e) => {
    setEmployment(e.target.value);

    dispatch(
      employmentStatus({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        employment_status: e.target.value,
      })
    );
  };

  const personalIncomeValue = (e) => {
    setPersonalIncomeStatus(e.target.value);

    dispatch(
      personalMonthlyIncome({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        personal_monthly_income: e.target.value,
      })
    );
  };

  const houseHoldMonthlyIncomeValue = (e) => {
    setHouseHoldMonthlyIncomeStatus(e.target.value);

    dispatch(
      houseHoldMonthlyIncome({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        household_monthly_income: e.target.value,
      })
    );
  };

  const peopleInHouseHoldValue = (e) => {
    setPeopleInHouseHoldStatus(e.target.value);

    dispatch(
      peopleInHousehold({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        people_in_household: e.target.value,
      })
    );
  };

  const childrenInHouseHoldValue = (e) => {
    setChildrenInHouseHoldStatus(e.target.value);

    dispatch(
      childrenInHousehold({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        children_in_household: e.target.value,
      })
    );
  };

  const sportsFanValue = (e) => {
    setSportsFanStatus(e.target.value);

    dispatch(
      sportsFan({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        sportsFan: e.target.value,
      })
    );
  };

  const footballFanValue = (e) => {
    setFootballFanStatus(e.target.value);

    dispatch(
      soccerFan({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        soccerFan: e.target.value,
      })
    );
  };

  const favoriteTeamValue = (e) => {
    setFavoriteTeamStatus(e.target.value);

    dispatch(
      favoriteTeam({
        authtoken: authString,
        scoutRef: scoutData.data?.data?.scoutRef,
        favorite_team_id: e.target.value,
      })
    );
  };

  const addOrRemoveInterests = (name) => {
    const newInterest = [...selectMultipleOption];
    const index = newInterest.indexOf(name);

    if (index === -1) {
      newInterest.push(name);
    } else {
      newInterest.splice(index, 1);
    }
    setMultipleSelectOption(newInterest);
  };

  const submitAffiliation = () => {
    setDemoData(DemographicData[4]);
    setIndustryAffiliationModal(!industryAffiliationModal);
    if (selectMultipleOption.length > 0) {
      dispatch(
        industryAffiliation({
          authtoken: authString,
          scoutRef: scoutData.data?.data?.scoutRef,
          interests: selectMultipleOption,
        })
      );
    }
  };

  const onClickDelete = () => {
    setDeleteAccountModal(!deleteAccountModal);
  };

  //Editing State and Local Government
  useEffect(() => {
    dispatch(getStates());
  }, [dispatch]);

  const stateValue = (e) => {
    setStateStatus(e.target.value);

    setStateID(
      states?.data?.states?.find((state) => state.name === e.target.value)?.id
    );

    dispatch(
      getStatesLGA({
        state_id: states?.data?.states?.find(
          (state) => state.name === e.target.value
        )?.id,
      })
    );
  };
  const lgaValue = (e) => {
    setLgaStatus(e.target.value);
    setLGAID(
      stateLGA?.data?.lga?.find((state) => state.name === e.target.value)?.id
    );
  };
  const submit = async (e) => {
    setLoading(true);
    e.preventDefault();

    let phoneNumber = scoutData?.data?.data?.phoneNumber;
    let firstName = scoutData?.data?.data?.firstName;
    let lastName = scoutData?.data?.data?.lastName;
    let country = "Nigeria";
    let state =
      stateID === undefined
        ? states?.data?.states?.find(
            (state) => state.name === scoutData?.data?.data?.state
          )?.id
        : stateID;
    let localGovernmentArea =
      lgaID === undefined ? scoutData?.data?.data?.lga_id : lgaID;
    let source = scoutData?.data?.data?.source || "";
    let scoutRef = scoutData.data?.data?.scoutRef;
    let language = "English";
    let bankName =
      bankDetails?.name === undefined
        ? scoutData?.data?.data?.bankName
        : bankDetails?.name;
    let bankCode = scoutData?.data?.data?.bankCode || bankDetails?.code;
    let authtoken =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
    let dob = dobValue;
    let gender = genderState;
    let maritalStatus = maritalStatusState;
    let religion = religionStatus;
    let education = educationStatus;

    const payload = {
      phoneNumber,
      firstName,
      lastName,
      country,
      state,
      localGovernmentArea,
      source,
      scoutRef,
      language,
      bankCode,
      ...(scoutData?.data?.data?.accountNumber !== accountNumber && {
        accountNumber,
      }),
      bankName,
      authtoken,
      dob,
      gender,
      maritalStatus,
      religion,
      education,
    };

    const {
      baseUrl,
      updateScoutData: { path },
    } = APIS;
    await axios
      .post(baseUrl + path, payload)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setLoading(true);
        history.push("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        setError(`${error.response.data.error}, please try again`);
      });
  };

  return (
    <div>
      <ToastContainer />
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>

        <>
          {industryAffiliationModal === false && deleteAccountModal === false && (
            <div>
              <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
                <div className="modal-section">
                  <div>
                    <h3 className="text-color-4 margin-0">Profile</h3>
                  </div>
                  <div className="margin-top-6 w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      aria-label="Email Form"
                    >
                      <div className="margin-top-6 padding-top-6 border-top-2px">
                        <h6 className="text-color-2">BASIC INFORMATION</h6>
                        <div className="field">
                          <div>{scoutData?.data?.data?.firstName}</div>
                        </div>
                        <div className="field">
                          <div>{scoutData?.data?.data?.lastName}</div>
                        </div>
                      </div>

                      <div className="margin-top-6 padding-top-6 border-top-2px">
                        <h6 className="text-color-2">Bank details</h6>
                        <div
                          data-hover="false"
                          data-delay="0"
                          className="form-input width-full w-dropdown"
                        >
                          <div
                            className="text-color-4 padding-0 width-full w-dropdown-toggle"
                            id="w-dropdown-toggle-1"
                            aria-controls="w-dropdown-list-1"
                            aria-haspopup="menu"
                            aria-expanded="false"
                            role="button"
                            onClick={onClickDropDown}
                          >
                            <div
                              className="margin-0 margin-top-1 w-icon-dropdown-toggle"
                              aria-hidden="true"
                            ></div>
                            <div>
                              {bankDetails?.name === undefined
                                ? scoutData?.data?.data?.bankName
                                : bankDetails?.name}
                            </div>
                          </div>

                          {showDropdown ? (
                            <nav
                              className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w--open"
                              id="w-dropdown-list-1"
                              aria-labelledby="w-dropdown-toggle-1"
                            >
                              <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                                <div className="relative">
                                  <input
                                    type="tel"
                                    className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                                    name="Phone-2"
                                    data-name="Phone 2"
                                    placeholder="Search banks"
                                    id="Phone-2"
                                    required=""
                                    defaultValue={query}
                                    style={{ color: "black" }}
                                    onChange={(event) =>
                                      setQuery(event.target.value)
                                    }
                                  />
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search absolute-top-left icon margin-4"
                                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                    loading="lazy"
                                    alt=""
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                </div>
                              </div>
                              {filterBank?.map((bank) => (
                                <a
                                  href="/#"
                                  className="padding-4 w-dropdown-link"
                                  key={bank.id}
                                  onClick={(e) => {
                                    setBankDetails(bank);
                                    setShowDropdown(false);
                                    e.preventDefault();
                                  }}
                                >
                                  {bank.name}
                                </a>
                              ))}
                            </nav>
                          ) : null}
                        </div>
                        <input
                          pattern="[0-9]*"
                          inputMode="numeric"
                          type="number"
                          className="form-input margin-top-2 w-input"
                          name="field-2"
                          data-name="Field 2"
                          placeholder="01234567890"
                          id="field-2"
                          required=""
                          value={accountNumber}
                          onChange={accountNumberValue}
                        />
                      </div>
                      <div className="margin-top-6 padding-top-6 border-top-2px">
                        <h6 className="text-color-2">
                          DEMOGRAPHIC INFORMATION
                        </h6>

                        <h6 className="text-color-5">D.O.B</h6>
                        <input
                          type="date"
                          className="form-input-2 w-input"
                          name="Age"
                          data-name="Age"
                          placeholder="31"
                          id="Age"
                          value={dobValue}
                          onChange={dateOfBirthValue}
                        />

                        <h6 className="text-color-5">GENDER</h6>
                        <select
                          id="Gender"
                          name="Gender"
                          data-name="Gender"
                          className="select-field-2 w-select"
                          onChange={genderValue}
                          value={genderState}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>

                      <h6 className="text-color-5">Marital status</h6>
                      <select
                        id="Marital-Status"
                        name="Marital-Status"
                        data-name="Marital Status"
                        className="select-field-2 w-select"
                        onChange={maritalStatusValue}
                        defaultValue={maritalStatusState}
                      >
                        <option value="Single never married">
                          Single never married
                        </option>
                        <option value="divorced">Divorced</option>
                        <option value="Married or domestic partnership">
                          Married or Domestic Partnership
                        </option>
                        <option value="Widowed">Widowed</option>
                        <option value="Separated">Separated</option>
                      </select>

                      <h6 className="text-color-5">religion</h6>
                      <select
                        id="Religion"
                        name="Religion"
                        data-name="Religion"
                        className="select-field-2 w-select"
                        onChange={religionValue}
                        defaultValue={religionStatus}
                      >
                        <option value="Atheist (do not believe in God)">
                          Atheist (Do not believe in God)
                        </option>
                        <option value="Catholic">Catholic</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Protestant (Anglican Baptist)">
                          Protestant (Anglican Baptist)
                        </option>
                        <option value="Christian Orthodox">
                          Christian Orthodox
                        </option>
                        <option value="Buddhist">Buddhist</option>
                      </select>

                      <h6 className="text-color-5">tribe</h6>
                      <select
                        id="Tribe"
                        name="Tribe"
                        data-name="Tribe"
                        className="select-field-2 w-select"
                        onChange={tribeValue}
                        defaultValue={tribeStatus}
                      >
                        <option value="Yoruba">Yoruba</option>
                        <option value="Igbo">Igbo</option>
                        <option value="Hausa">Hausa</option>
                        <option value="Other">Other</option>
                      </select>

                      <h6 className="text-color-5">State</h6>
                      <select
                        id="State"
                        name="State"
                        data-name="State"
                        className="select-field-2 w-select"
                        onChange={stateValue}
                        value={stateStatus}
                      >
                        {states?.data?.states.map((state) => (
                          <>
                            <option key={state.id}>{state.name}</option>
                          </>
                        ))}
                      </select>
                      <h6 className="text-color-5">LGA</h6>
                      <select
                        id="Lga"
                        name="Lga"
                        data-name="Lga"
                        className="select-field-2 w-select"
                        onChange={lgaValue}
                        value={lgaStatus}
                      >
                        <option
                          value={
                            lgaStatus === null
                              ? "Select a local government"
                              : lgaStatus
                          }
                        >
                          {lgaStatus === null
                            ? "Select a local government"
                            : lgaStatus}
                        </option>
                        {stateLGA?.data?.lga.map((lgas) => (
                          <>
                            <option key={lgas?.id}>{lgas?.name}</option>
                          </>
                        ))}
                      </select>

                      <h6 className="text-color-5">EDUCATION</h6>
                      <select
                        id="Education"
                        name="Education"
                        data-name="Education"
                        className="select-field-2 w-select"
                        onChange={educationValue}
                        defaultValue={educationStatus}
                      >
                        <option value="Bachelor's degree">
                          Bachelor's degree
                        </option>
                        <option value="Master's degree">Master's degree</option>
                        <option value="Doctorate degree">
                          Doctorate degree
                        </option>
                        <option value="Professional degree">
                          Professional degree
                        </option>
                        <option value="No schooling completed">
                          No schooling completed
                        </option>
                        <option value="High school graduate diploma or the equivalent (for example: GED)">
                          High school graduate diploma or the equivalent e.g GED
                        </option>
                        <option value="Some high school no diploma">
                          Some high school no diploma
                        </option>
                        <option value="Some college credit no degree">
                          Some college credit no degree
                        </option>
                        <option value="Nursery school to 8th grade">
                          Nursery school to 8th grade
                        </option>
                        <option value="Trade/technical/vocational training">
                          Trade/Technical/Vocational Training
                        </option>
                        <option value="Associate degree">
                          Associate degree
                        </option>
                      </select>

                      <h6 className="text-color-5">EMPLOYMENT STATUS</h6>
                      <select
                        id="Employment-Status-3"
                        name="Employment-Status"
                        data-name="Employment Status"
                        className="select-field-2 w-select"
                        onChange={employmentValue}
                        defaultValue={employment}
                      >
                        <option value="Partime employed">
                          Part-time employed
                        </option>
                        <option value="A student">A student</option>
                        <option value="A homemaker">A homemaker</option>
                        <option value="Unable to work">Unable to work</option>
                        <option value="Military">Military</option>
                        <option value="Unemployed">Unemployed</option>
                        <option value="Retired">Retired</option>
                        <option value="Fully employed">Full employed</option>
                        <option value="Self employed">Self employed</option>
                      </select>

                      <h6 className="text-color-5">PERSONAL INCOME</h6>
                      <select
                        id="Income"
                        name="Income"
                        data-name="Income"
                        className="select-field-2 w-select"
                        onChange={personalIncomeValue}
                        defaultValue={personalIncomeStatus}
                      >
                        <option value="below_100_USD">Below 100 USD</option>
                        <option value="100_to_500_USD">100 to 500 USD</option>
                        <option value="500_to_1000_USD">500 to 1000 USD</option>
                        <option value="above_1000_USD">Above 1000 USD</option>
                      </select>

                      <h6 className="text-color-5">HOUSEHOLD INCOME</h6>
                      <select
                        id="Household-Income"
                        name="Household-Income"
                        data-name="Household Income"
                        className="select-field-2 w-select"
                        onChange={houseHoldMonthlyIncomeValue}
                        defaultValue={houseHoldMonthlyIncomeStatus}
                      >
                        <option value="below_100_USD">Below 100 USD</option>
                        <option value="100_to_500_USD">100 to 500 USD</option>
                        <option value="500_to_1000_USD">500 to 1000 USD</option>
                        <option value="above_1000_USD">Above 1000 USD</option>
                      </select>

                      <h6 className="text-color-5">No. in household</h6>
                      <select
                        id="No.-in-Household"
                        name="No.-in-Household"
                        data-name="No. in Household"
                        className="select-field-2 w-select"
                        onChange={peopleInHouseHoldValue}
                        defaultValue={peopleInHouseHoldStatus}
                      >
                        <option value="below_3">Below 3</option>
                        <option value="3_to_5">3 to 5</option>
                        <option value="5_to_10">5 to 10</option>
                        <option value="above_10">Above 10</option>
                      </select>

                      <h6 className="text-color-5">CHILDREN in household</h6>
                      <select
                        id="Children-in-Household"
                        name="Children-in-Household"
                        data-name="Children in Household"
                        className="select-field-2 w-select"
                        onChange={childrenInHouseHoldValue}
                        defaultValue={childrenInHouseHoldStatus}
                      >
                        <option value="below_3">Below 3</option>
                        <option value="3_to_5">3 to 5</option>
                        <option value="5_to_10">5 to 10</option>
                        <option value="above_10">Above 10</option>
                      </select>

                      <h6 className="text-color-5">SOCCER / FOOTBALL FAN</h6>
                      <select
                        id="Children-in-Household"
                        name="Children-in-Household"
                        data-name="Children in Household"
                        className="select-field-2 w-select"
                        onChange={footballFanValue}
                      >
                        <option selected hidden disabled>
                          {footballFanStatus === false ? "No" : "Yes"}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>

                      <h6 className="text-color-5">SPORTS FAN</h6>
                      <select
                        id="Children-in-Household"
                        name="Children-in-Household"
                        data-name="Children in Household"
                        className="select-field-2 w-select"
                        onChange={sportsFanValue}
                      >
                        <option selected hidden disabled>
                          {sportsFanStatus === false ? "No" : "Yes"}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>

                      <h6 className="text-color-5">FAVOURITE TEAM</h6>
                      <select
                        id="Favourite-Team"
                        name="Favourite-Team"
                        data-name="Favourite Team"
                        className="select-field-2 w-select"
                        onChange={favoriteTeamValue}
                      >
                        <option selected hidden disabled>
                          {favoriteTeamStatus}
                        </option>
                        <option value="1">Chelsea F.C</option>
                        <option value="2">Liverpool F.C</option>
                        <option value="3">Tottenham F.C</option>
                        <option value="4">Arsenal F.C</option>
                        <option value="5">Manchester United F.C</option>
                        <option value="6">Manchester City F.C</option>
                        <option value="7">Other</option>
                        <option value="8">I am not a football fan</option>
                      </select>

                      <h6 className="text-color-5">INDUSTRY AFFILIATION</h6>
                      <span
                        onClick={submitAffiliation}
                        className="link-block w-inline-block"
                      >
                        <input
                          type="text"
                          className="form-input-2 w-input"
                          name="field-2"
                          data-name="Field 2"
                          value={
                            selectMultipleOption?.length === 0
                              ? industryAffiliationState?.toString()
                              : selectMultipleOption?.toString()
                          }
                          id="field-2"
                          required=""
                        />
                      </span>
                    </form>
                  </div>
                  <button
                    onClick={onClickDelete}
                    className="button-destructive w-button"
                  >
                    Delete Account
                  </button>
                  <span style={{ color: "#FFCC00" }} className=" margin-top-6">
                    {error}
                  </span>
                  <div className="margin-top-6 padding-top-6 border-top-2px flex-justify-end flex-space-between">
                    <a
                      href="/dashboard"
                      className="button-secondary subtle w-button"
                    >
                      Cancel
                    </a>
                    <button
                      onClick={submit}
                      className="button-secondary w-button"
                    >
                      {(loading && <Loader />) || (
                        <span style={{ marginLeft: "5px" }}>
                          {" "}
                          Update Profile
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Industry Affiliation Modal */}
          {industryAffiliationModal ? (
            <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
              <div className="modal-section">
                <div className="margin-top-12">
                  <h3 className="text-color-4">
                    What are your interests or
                    <br />
                    industry affiliations?
                  </h3>

                  <h5 className="text-color-4">Select all that apply</h5>
                  <div className="w-form">
                    {demoData?.options.map((x) => (
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        aria-label="Email Form"
                        key={x.id}
                      >
                        <label className="w-checkbox checkbox-field">
                          <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            data-name="Checkbox"
                            className="w-checkbox-input checkbox-2"
                            onChange={() => addOrRemoveInterests(x.value)}
                          />
                          <span
                            className="checkbox-label w-form-label"
                            htmlFor="checkbox"
                          >
                            {x.value}
                          </span>
                        </label>
                      </form>
                    ))}
                  </div>
                </div>
                <div className="flex-justify-end padding-top-6 border-top-2px margin-top-6">
                  <button
                    onClick={submitAffiliation}
                    className="button-secondary w-button"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>

        <>
          {/* Delete Account Modal */}
          {deleteAccountModal ? <DeleteAccountModal /> : null}
        </>
      </div>
    </div>
  );
};

export default Profile;
