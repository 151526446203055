import { configureStore } from "@reduxjs/toolkit";
import { mediaApi } from "./actions/surveyActions";
import {
  createChildrenInHousehold,
  createHouseHoldIncome,
  createReligion,
  createEmploymentStatus,
  createIndustryAffiliation,
  createPersonalMonthlyIncome,
  createPeopleInHousehold,
  createDateOfBirth,
  createTribe,
  createHighestEducationLevel,
  createGender,
  createMaritalStatus,
  createSportsFan,
  createSoccerFan,
  createFavoriteTeam,
} from "./reducers/demographicReducers";
import {
  fetchScoutReducer,
  fetchCampaignReducer,
  fetchQuestionReducer,
  addResponseReducer,
  fetchMissingDataReducer,
  setCampaignReducer,
  setMultiMediaRequestReducer,
  fetchBanksReducers,
  verifyAccountNumberReducer,
  convertAmountReducer,
  cashOutToBankReducer,
  cashOutAfricaReducer,
  cashOutWithPagaReducer,
  sendSMSReducer,
  sendWhatsappMessageReducer,
  saveWebTokenReducer,
  createNewSignUpReducer,
  getStatesReducer,
  getStatesLGAReducer,
  updateScoutDataReducer,
  deleteScoutReducer,
} from "./reducers/surveyReducers";

const store = configureStore({
  reducer: {
    fetchScout: fetchScoutReducer.reducer,
    fetchCampaign: fetchCampaignReducer.reducer,
    fetchQuestion: fetchQuestionReducer.reducer,
    addResponse: addResponseReducer.reducer,
    fetchMissingData: fetchMissingDataReducer.reducer,
    setCampaignEnd: setCampaignReducer.reducer,
    setMultiMediaRequest: setMultiMediaRequestReducer.reducer,
    fetchBanks: fetchBanksReducers.reducer,
    verifyAccount: verifyAccountNumberReducer.reducer,
    amountConverter: convertAmountReducer.reducer,
    cashOutToBankAccount: cashOutToBankReducer.reducer,
    cashOutAfricaAccount: cashOutAfricaReducer.reducer,
    cashOutToPagaAccount: cashOutWithPagaReducer.reducer,
    sendScoutSMS: sendSMSReducer.reducer,
    sendScoutWhatsappMessage: sendWhatsappMessageReducer.reducer,
    webTokenNotification: saveWebTokenReducer.reducer,
    createSignUp: createNewSignUpReducer.reducer,
    fetchAllStates: getStatesReducer.reducer,
    fetchAllStatesLGA: getStatesLGAReducer.reducer,
    updateTheScoutData: updateScoutDataReducer.reducer,
    deleteScoutData: deleteScoutReducer.reducer,

    // Demographic Reducers Below
    houseHoldChildren: createChildrenInHousehold.reducer,
    totalHouseHoldIncome: createHouseHoldIncome.reducer,
    religiousChoice: createReligion.reducer,
    employmentStatusChoice: createEmploymentStatus.reducer,
    industryAffiliationChoice: createIndustryAffiliation.reducer,
    householdPersonalMonthlyIncome: createPersonalMonthlyIncome.reducer,
    totalNumberOfPeopleInHouseHold: createPeopleInHousehold.reducer,
    birthDay: createDateOfBirth.reducer,
    tribeSelection: createTribe.reducer,
    educationLevel: createHighestEducationLevel.reducer,
    genderChoice: createGender.reducer,
    maritalChoice: createMaritalStatus.reducer,
    sportsFanChoice: createSportsFan.reducer,
    soccerFanChoice: createSoccerFan.reducer,
    selectFavoriteTeam: createFavoriteTeam.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(mediaApi.middleware),
});

export default store;
