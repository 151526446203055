import React, { useState } from "react";
import ConfirmAccountDeletion from "./ConfirmAccountDeletion";
import deleteOptionsList from "../../deleteAccountOptions";
import { Link } from "react-router-dom";

const DeleteAccountModal = () => {
  const [confirmAccountModal, setConfirmAccountModal] = useState(false);
  const [active, setActive] = useState(false);
  const [otherValue, setOtherValue] = useState("Other");

  const onClickConfirm = () => {
    setConfirmAccountModal(!confirmAccountModal);
  };
  //Function to track active class
  function setClassClicked(option) {
    setActive(option);
  }

  const otherChangeHandler = (event) => {
    setOtherValue(event.target.value);
  };

  return (
    <>
      {confirmAccountModal === false && (
        <div
          className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl"
          bis_skin_checked="1"
        >
          <div className="modal-section" bis_skin_checked="1">
            <div bis_skin_checked="1">
              <h5 className="text-color-4 margin-0">Delete Account</h5>
            </div>
            <div
              className="flex-justify-center neg-margin margin-top-3"
              bis_skin_checked="1"
            >
              <div
                className="progress-bar expand active"
                bis_skin_checked="1"
              ></div>
              <div className="progress-bar expand" bis_skin_checked="1"></div>
            </div>
            <div className="margin-top-6 w-form" bis_skin_checked="1">
              <form>
                <div
                  className="margin-top-6 padding-top-6 border-top-2px"
                  bis_skin_checked="1"
                >
                  <h3 className="text-color-4">
                    Why are you deleting your account?
                  </h3>
                  {deleteOptionsList?.map((x, i) => (
                    <>
                      <div onClick={() => setClassClicked(x)}>
                        <label key={i} className="radio-button-field w-radio">
                          <div
                            className={`w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input${
                              active?.option === x?.option
                                ? "w--redirected-checked w--redirected-focus"
                                : ""
                            }`}
                          ></div>
                          <input
                            type="radio"
                            name="radio"
                            style={{
                              opacity: "0",
                              position: "absolute",
                              zIndex: "-1",
                            }}
                          />
                          <span
                            className="radio-button-label w-form-label"
                            htmlFor="radio"
                          >
                            {x?.option}
                          </span>
                        </label>
                      </div>
                    </>
                  ))}
                </div>

                {deleteOptionsList[0]?.option ? (
                  <div>
                    <label
                      className=""
                      onClick={() => setClassClicked(otherValue)}
                    >
                      <input
                        type="text"
                        style={{
                          color: `${active ? "white" : "white"}`,
                          outline: "none",
                          position: "relative",
                          width: "100%",
                          marginBottom: "4px",
                          padding: "12px",
                          borderRadius: "0.5rem",
                          height: "48px",
                          backgroundColor: `${
                            active
                              ? "rgba(84, 40, 176, 0.44)"
                              : "rgba(84, 40, 176, 0.44)"
                          }`,
                          border: "none",
                        }}
                        onChange={otherChangeHandler}
                        value={otherValue === undefined ? "" : otherValue}
                      />
                    </label>
                  </div>
                ) : null}
              </form>
            </div>
            <div
              className="margin-top-6 padding-top-6 border-top-2px flex-justify-end flex-space-between"
              bis_skin_checked="1"
            >
              <Link
                to="/dashboard"
                className="button-secondary subtle w-button"
              >
                Cancel
              </Link>
              <button
                onClick={onClickConfirm}
                className="button-secondary w-button"
                disabled={!active}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal  */}
      {confirmAccountModal ? (
        <ConfirmAccountDeletion
          selectedOption={active?.option}
          selectedOtherOption={otherValue}
        />
      ) : null}
    </>
  );
};

export default DeleteAccountModal;
