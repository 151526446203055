export const APIS = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  refreshToken: {
    method: "POST",
    path: `${process.env.REACT_APP_API_REFRESH_URL}${process.env.REACT_APP_FIREBASE_API_KEY}`,
  },
  createScout: {
    method: "POST",
    path: "/scout_create_scout",
  },
  getPartners: {
    method: "POST",
    path: "/scout_get_partners",
  },
  getScout: {
    method: "POST",
    path: "/scout_get_scout",
  },
  getCampaigns: {
    method: "POST",
    path: "/scout_get_campaigns",
  },
  getQuestions: {
    method: "POST",
    path: "/scout_get_questions",
  },
  addResponse: {
    method: "POST",
    path: "/scout_add_response",
  },
  getMissingData: {
    method: "POST",
    path: "/scout_get_missing_data",
  },

  setEndCampaign: {
    method: "POST",
    path: "/scout_end_campaign",
  },

  addMultiMediaRequest: {
    method: "POST",
    path: "/scout_add_multimedia_request_response",
  },

  getBanks: {
    method: "POST",
    path: "/scout_get_banks",
  },

  verifyAccountNumber: {
    method: "POST",
    path: "/scout_verify_account_number",
  },

  convertAmount: {
    method: "POST",
    path: "/scout_convert_amount",
  },

  cashOutToBank: {
    method: "POST",
    path: "/scout_cashout",
  },

  cashOutAfrica: {
    method: "POST",
    path: "/scout_redeem_credits",
  },

  cashOutWithPaga: {
    method: "POST",
    path: "/redeem_credits",
  },

  sendSMS: {
    method: "POST",
    path: "/versus_v2_send_sms",
  },

  pendingResponses: {
    method: "POST",
    path: "/scout_get_request_responses",
  },
  sendWhatsappMessage: {
    method: "POST",
    path: "/send_whatsapp_message",
  },
  saveWebToken: {
    method: "POST",
    path: "/scout_save_web_token",
  },
  createNewSignUp: {
    method: "POST",
    path: "/scout_validate_new_signup",
  },
  getStates: {
    method: "POST",
    path: "/scout_get_states",
  },
  getStatesLGA: {
    method: "POST",
    path: "/scout_get_states_lga",
  },
  updateScoutData: {
    method: "POST",
    path: "/scout_update_scout",
  },

  deleteScout: {
    method: "POST",
    path: "/scout_delete_scout",
  },

  // Demographic Endpoints
  updateChildrenInHouseHold: {
    method: "POST",
    path: "/scout_update_children_in_household",
  },

  updateHouseHoldMonthlyIncome: {
    method: "POST",
    path: "/scout_update_household_monthly_income",
  },

  updateReligion: {
    method: "POST",
    path: "/scout_update_religion",
  },

  updateEmploymentStatus: {
    method: "POST",
    path: "/scout_update_employment_status",
  },

  updateIndustryAffiliation: {
    method: "POST",
    path: "/scout_update_industry_affiliation",
  },

  updatePersonalMonthlyIncome: {
    method: "POST",
    path: "/scout_update_personal_monthly_income",
  },

  updatePeopleInHousehold: {
    method: "POST",
    path: "/scout_update_people_in_household",
  },

  updateDob: {
    method: "POST",
    path: "/scout_update_dob",
  },

  updateTribe: {
    method: "POST",
    path: "/scout_update_tribe",
  },

  updateHighestEducationLevel: {
    method: "POST",
    path: "/scout_update_highest_education_level",
  },

  updateGender: {
    method: "POST",
    path: "/scout_update_gender",
  },

  updateMaritalStatus: {
    method: "POST",
    path: "/scout_update_marital_status",
  },

  updateSportsFan: {
    method: "POST",
    path: "/scout_update_sports_fan",
  },

  updateSoccerFan: {
    method: "POST",
    path: "/scout_update_soccer_fan",
  },

  updateFavoriteTeam: {
    method: "POST",
    path: "/scout_update_favorite_team",
  },
};
