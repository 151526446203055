import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getMissingData } from "../../actions/surveyActions";
import { useDispatch, useSelector } from "react-redux";
import DemographicSurveyStart from "./DemographicSurveyStart";

const DashboardEmpty = ({ data }) => {
  const [toggleTab, setToggleTab] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMissingData());
  }, [dispatch]);

  const fetchMissingData = useSelector((state) => state.fetchMissingData);
  const { data: missingData } = fetchMissingData;

  const toggleTabState = (index) => {
    setToggleTab(index);
  };

  return (
    <div>
      {missingData?.data?.questions?.length > 14 ? (
        <>
          <div className="section-in-base background-color-3 padding-bottom-40">
            <div className="container-1280">
              <p className="text-2xl weight-bold">
                0{" "}
                <span className="text-medium weight-normal">scout credits</span>
              </p>
            </div>
          </div>
          <div className="background-color-white">
            <div className="container-1280">
              <div
                data-duration-in="300"
                data-duration-out="100"
                className="w-tabs"
              >
                <div
                  className="page-tabs margin-bottom-6 w-tab-menu"
                  role="tablist"
                >
                  <div
                    data-w-tab="Tab 1"
                    className={
                      toggleTab === 1
                        ? "tab w-inline-block w-tab-link w--current"
                        : "tab w-inline-block w-tab-link"
                    }
                    id="w-tabs-0-data-w-tab-0"
                    href="#w-tabs-0-data-w-pane-0"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-0"
                    aria-selected="true"
                    onClick={() => toggleTabState(1)}
                  >
                    <div className="text-color-4">0 Surveys</div>
                  </div>
                  <div
                    data-w-tab="Tab 2"
                    className={
                      toggleTab === 2
                        ? "tab w-inline-block w-tab-link w--current"
                        : "tab w-inline-block w-tab-link"
                    }
                    tabIndex="-1"
                    id="w-tabs-0-data-w-tab-1"
                    href="#w-tabs-0-data-w-pane-1"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-1"
                    aria-selected="false"
                    onClick={() => toggleTabState(2)}
                  >
                    <div>
                      <span>0</span> Requests
                    </div>
                  </div>
                </div>
                <div className="tabs-content w-tab-content">
                  <div
                    data-w-tab="Tab 1"
                    className={
                      toggleTab === 2
                        ? "w-tab-pane"
                        : "w-tab-pane w--tab-active"
                    }
                    id="w-tabs-0-data-w-pane-0"
                    role="tabpanel"
                    aria-labelledby="w-tabs-0-data-w-tab-0"
                    style={{
                      opacity: "1",
                      transition: `opacity 300ms ease 0s`,
                    }}
                  >
                    <div className="flex-space-between">
                      <h5 className="text-color-4 text-medium">
                        Available surveys
                      </h5>
                      <p className="text-color-2">1 Completed</p>
                    </div>
                    <div className="grid-3-columns">
                      <div className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between">
                        <h4 className="margin-0">📭 No surveys right now</h4>
                        <p className="margin-top-2 text-color-2">
                          We’ll notify you as soon as we get surveys that are a
                          good fit for&nbsp; you
                        </p>
                        <a
                          href="/onboarding/demographics-start"
                          className="button button-small w-button"
                        >
                          Enable notifications
                        </a>
                      </div>

                      {missingData?.data?.questions.length === 0 ? (
                        <div className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between">
                          <h4 className="margin-0">
                            Earn 9 starting credits with the demographics
                            survey!
                          </h4>
                          <div className="flex-align-center margin-top-6 flex-space-between">
                            <div className="flex-align-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-message-square icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                              </svg>
                              <div className="text-color-2">9</div>
                            </div>
                            <Link className="button button-small w-button">
                              Start{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-arrow-right icon margin-left-1"
                              >
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="12 5 19 12 12 19"></polyline>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    data-w-tab="Tab 2"
                    className="w-tab-pane"
                    id="w-tabs-0-data-w-pane-1"
                    role="tabpanel"
                    aria-labelledby="w-tabs-0-data-w-tab-1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <DemographicSurveyStart data={data} />
      )}
    </div>
  );
};

export default DashboardEmpty;
