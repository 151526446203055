import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "../../../services/axios";
import { APIS } from "../../../services/config";
import { ToastContainer } from "react-toastify";

const Honour = (props) => {
  const [userData, setUserData] = useState();

  const {
    handleBack,
    // stateValue,
    // values,
    // localGovernmentValue,
    // referrerValue,
  } = props;

  let history = useHistory();

  let phoneNumber = remove_character(
    JSON.parse(localStorage.getItem("phoneNumber")),
    4
  );
  // let firstName = values?.firstName;
  // let lastName = values?.lastName;
  // let country = "Nigeria";
  // let state = stateValue?.id;
  // let localGovernmentArea = localGovernmentValue?.id;
  // let source = referrerValue?.name;

  // const payload = {
  //   phoneNumber,
  //   firstName,
  //   lastName,
  //   country,
  //   state,
  //   localGovernmentArea,
  //   source,
  // };

  // utility function that removes 4th element from a phonenumber
  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  useEffect(() => {
    async function getData() {
      const payload = { docRef: remove_character(phoneNumber, 4) };
      const {
        baseUrl,
        getScout: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);
      setUserData(data);
    }
    getData();
  });

  const submit = async (e) => {
    e.preventDefault();
    // const {
    //   baseUrl,
    //   createScout: { path },
    // } = APIS;
    // const request = await axios.post(baseUrl + path, payload);
    // if (request.status === 201) {
    //   history.push("/orientation");
    // } else {
    //   toast.error(request.data.text, {
    //     autoClose: 5000,
    //   });
    // }

    // const payload = { docRef: remove_character(phoneNumber, 4) };
    // const {
    //   baseUrl,
    //   getScout: { path },
    // } = APIS;
    // const data = await axios.post(baseUrl + path, payload);
    // setUserData(data);

    if (userData === undefined) {
      localStorage.setItem("user_data", JSON.stringify({}));
      history.push("/dashboard");
    } else {
      localStorage.setItem("user_data", JSON.stringify(userData?.data));
      history.push("/dashboard");
    }
  };

  const previousStep = () => {
    handleBack();
  };

  return (
    <div>
      <ToastContainer />
      <div className="section-in-base background-color-3">
        <div className="container-1140">
          <div className="flex-justify-center">
            <a href="/" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
          </div>
          <div className="flex-justify-center margin-top-10">
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar"></div>
          </div>
          <div className="flex-justify-center">
            <div className="margin-top-40 margin-bottom-40 max-width-medium">
              <h1 className="text-color-4 text-align-center">
                🎖
                <br />
                Scout’s Honour
              </h1>
              <p className="text-color-2 text-align-center text-medium">
                We reserve the right to{" "}
                <strong className="text-color-4">
                  prevent you from cashing out
                </strong>{" "}
                if we detect dishonest answers.
                <br />
                <br />
                By continuing you agree and accept the{" "}
                <Link
                  to="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
                  className="underline text-color-4"
                >
                  terms
                </Link>{" "}
                and conditions and{" "}
                <Link
                  to="https://doc.versus.africa/versus/privacy-policy"
                  className="text-color-4 underline"
                >
                  privacy policy
                </Link>{" "}
                of Versus Scouts.
              </p>
              <div className="margin-top-6 padding-top-6 border-top-2px flex-space-between flex-align-baseline">
                <button
                  data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                  className="button-secondary w-button"
                  onClick={previousStep}
                >
                  Back
                </button>

                <button onClick={submit} className="button-secondary w-button">
                  I Agree and Accept
                </button>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Honour;
