import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardEmpty from "./DashboardEmpty";
import { useIntercom } from "react-use-intercom";
import Header from "../header/Header";
import Requests from "./Requests";
import { useDispatch, useSelector } from "react-redux";
import {
  getScouts,
  getCampaign,
  useGetMediaByDetailsQuery,
  useGetResponsesByDetailsQuery,
} from "../../actions/surveyActions";
// import Loader from "../Loader/loader";
import { getUser } from "../../services/Storage";

const Dashboard = () => {
  const user = getUser();
  const { boot } = useIntercom();
  const [toggleTab, setToggleTab] = useState(1);

  const dispatch = useDispatch();
  const fetchScout = useSelector((state) => state.fetchScout);
  const fetchCampaign = useSelector((state) => state.fetchCampaign);
  const { data } = fetchScout;
  const { data: campaignData } = fetchCampaign;

  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }
  const string =
    window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
    window.location.hostname === "localhost"
      ? "55ad4986-b519-4e44-ab0b-890527299af6"
      : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

  const { data: allRequests } = useGetMediaByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString: string,
    mediaType: "",
  });

  const { data: pendingRequests } = useGetResponsesByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString: string,
  });

  const toggleTabState = (index) => {
    setToggleTab(index);
  };

  useEffect(() => {
    if (!user) {
      window.location.href = "/home";
    }
  }, [user]);

  useEffect(() => {
    // const fetchData = async () => {
    dispatch(getScouts());
    dispatch(getCampaign());
    // };
    // fetchData();
  }, [dispatch]);

  useEffect(() => {
    //Intercom Configuration
    if (data.data) {
      boot({
        name: `${data?.data?.firstName} ${data?.data?.lastName}`,
        ref: data.data.scoutRef,
      });
    }
  }, [data, boot]);

  useEffect(() => {
    if (data?.data?.blacklisted === true) {
      alert("Scout is Blacklisted");
    }
  }, [data]);

  return (
    <>
      <div>
        <Header fetchScout={fetchScout} />
        {data?.data?.childrenInHousehold !== null &&
        data?.data?.householdMonthlyIncome !== null &&
        data?.data?.religion !== null &&
        data?.data?.employment !== null &&
        data?.data?.industryAffiliations !== null &&
        data?.data?.personalMonthlyIncome !== null &&
        data?.data?.peopleInHousehold !== null &&
        data?.data?.dob !== null &&
        data?.data?.tribe !== null &&
        data?.data?.education !== null &&
        data?.data?.gender !== null &&
        data?.data?.maritalStatus !== null &&
        data?.data?.sportsFan !== null &&
        data?.data?.soccerFan !== null &&
        data?.data?.favourite_team !== null ? (
          <>
            <div className="section-in-base background-color-3 padding-bottom-40 portrait-padding-top-6">
              <div className="container-1280">
                <div className="hello">
                  <p className="paragraph-2">
                    <span className="text-medium">Hello</span>
                  </p>
                  <p>
                    <span className="text-2xl">{data?.data?.firstName}</span>
                  </p>
                </div>
                <p className="text-2xl weight-bold portrait-text-align-center">
                  {data?.data?.versusCredits_amount}{" "}
                  <span className="text-medium weight-normal">
                    scout credits
                  </span>
                </p>
              </div>
            </div>

            <div className="background-color-white">
              <div className="container-1280">
                <div
                  data-duration-in="300"
                  data-duration-out="100"
                  className="w-tabs"
                >
                  <div
                    className="page-tabs margin-bottom-6 w-tab-menu"
                    role="tablist"
                  >
                    <a
                      className={
                        toggleTab === 1
                          ? "tab w-inline-block w-tab-link w--current"
                          : "tab w-inline-block w-tab-link"
                      }
                      id="w-tabs-0-data-w-tab-0"
                      style={{ display: "flex" }}
                      href="#!"
                      onClick={() => toggleTabState(1)}
                    >
                      <div
                        className="text-block-2"
                        style={{ marginTop: "6px" }}
                      >
                        Text{" "}
                      </div>
                      <span
                        className="text-count"
                        style={{ marginLeft: "7px" }}
                      >
                        {campaignData?.data?.campaigns?.length === undefined
                          ? 0
                          : campaignData?.data?.campaigns?.length}{" "}
                      </span>
                    </a>

                    <a
                      data-w-tab="Tab 2"
                      className={
                        toggleTab === 2
                          ? "tab w-inline-block w-tab-link w--current"
                          : "tab w-inline-block w-tab-link"
                      }
                      id="w-tabs-0-data-w-tab-1"
                      style={{ display: "flex" }}
                      href="#!"
                      onClick={() => toggleTabState(2)}
                    >
                      <div
                        className="text-block-2"
                        style={{ marginTop: "6px" }}
                      >
                        Media{" "}
                      </div>
                      <span
                        className="media-count"
                        style={{ marginLeft: "7px" }}
                      >
                        {allRequests?.requests?.length === undefined
                          ? 0
                          : allRequests?.requests?.length}{" "}
                      </span>
                    </a>
                  </div>
                  {/* <div
                  className="page-tabs margin-bottom-6 w-tab-menu" role="tablist">
                  <a
                    data-w-tab="Tab 1"
                    className="tab w-inline-block w-tab-link w--current" id="w-tabs-0-data-w-tab-0"
                    href="#w-tabs-0-data-w-pane-0"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-0"
                    aria-selected="true">
                    <div className="text-block-2">Text  <span className="text-count">4 </span></div>

                  </a>
                  <a
                    data-w-tab="Tab 2"
                    className="tab w-inline-block w-tab-link"
                    id="w-tabs-0-data-w-tab-1"
                    href="#w-tabs-0-data-w-pane-1"
                    role="tab"
                    aria-controls="w-tabs-0-data-w-pane-1"
                    aria-selected="false"
                    tabindex="-1">
                    <div>Media  <span className="media-count">6 </span></div>

                  </a>
                </div> */}

                  <div className="tabs-content w-tab-content">
                    <div
                      data-w-tab="Tab 1"
                      className={
                        toggleTab === 2
                          ? "w-tab-pane"
                          : "w-tab-pane w--tab-active"
                      }
                      id="w-tabs-0-data-w-pane-0"
                      role="tabpanel"
                      aria-labelledby="w-tabs-0-data-w-tab-0"
                      style={
                        toggleTab === 2
                          ? {
                              opacity: "1",
                              transition: "opacity 300ms ease 0s",
                            }
                          : {}
                      }
                    >
                      <div className="flex-space-between">
                        <h5 className="text-color-4 text-medium">
                          Available surveys{" "}
                        </h5>
                        <p className="text-color-2">
                          {data?.data?.completedCampaigns?.length} Completed
                        </p>
                      </div>
                      {campaignData === undefined ? (
                        <h2>Loading</h2>
                      ) : (
                        <div className="grid-3-columns">
                          {campaignData?.data?.campaigns?.map((campaigns) => (
                            <div
                              key={campaigns?.campaignRef}
                              className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between"
                            >
                              <h4 className="margin-0">
                                {campaigns?.campaignName}
                              </h4>
                              <div className="flex-align-center margin-top-6 flex-space-between portrait-flex-column portrait-flex-align-start">
                                <div className="flex-align-center">
                                  <div className="flex-align-center margin-right-4">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-message-square icon text-color-3 margin-right-1"
                                      src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                      loading="lazy"
                                      alt=""
                                    >
                                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg>
                                  </div>
                                </div>
                                <Link
                                  to={{
                                    pathname: "/dashboard/survey/start",
                                    state: { campaigns },
                                  }}
                                  className="button button-small portrait-margin-top-4 w-button"
                                >
                                  Start
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-right icon margin-left-1"
                                  >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      data-w-tab="Tab 2"
                      className={
                        toggleTab === 1
                          ? "w-tab-pane"
                          : "w-tab-pane w--tab-active"
                      }
                      id="w-tabs-0-data-w-pane-1"
                      role="tabpanel"
                      aria-labelledby="w-tabs-0-data-w-tab-1"
                      style={
                        toggleTab === 1
                          ? {
                              opacity: "1",
                              transition: "opacity 300ms ease 0s",
                            }
                          : {}
                      }
                    >
                      <Requests
                        toggleTab={toggleTab}
                        pendingRequests={pendingRequests}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <DashboardEmpty data={data?.data} />
        )}
      </div>
    </>
  );
};

export default Dashboard;
