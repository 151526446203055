const deleteOptionsList = [
  {
    id: 1,
    option: "I don't have time to take surveys",
  },
  {
    id: 2,
    option: "Available surveys do not match my interests",
  },
  {
    id: 3,
    option: "I found another survey platform with more rewards",
  },
  {
    id: 4,
    option: "I encountered technical issues",
  },
];

export default deleteOptionsList;
