import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchIcon } from "../../assets/icons";
import countryList from "../../countryCode";
import firebase from "../../services/firebase";

import Loader from "../Loader/loader";

const PhoneNumberSignUp = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [countryShortCode, setCountryShortCode] = useState("🇳🇬");
  const [countryCode, setCountryCode] = useState("+234");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickDropDown = () => setShowDropdown(!showDropdown);
  const onClickDropOpen = () => setShowDropdown(showDropdown);

  const ref = useRef(null);

  let history = useHistory();
  const change = (e) => {
    setPhoneNumberValue(e.currentTarget.value);
  };

  const handleClick = (e) => {
    e.preventDefault();

    setLoading(true);

    let reCaptcha = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
    });
    firebase
      .auth()
      .signInWithPhoneNumber(countryCode + phoneNumberValue, reCaptcha)
      .then((confirmationResult) => {
        localStorage.setItem("confirm", JSON.stringify(confirmationResult));
        localStorage.setItem(
          "phoneNumber",
          JSON.stringify(countryCode + phoneNumberValue)
        );
        history.push("/onboarding/code");
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
  };

  const filterCodes = countryList.filter((code) => {
    return code.countryName
      .toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase());
  });

  return (
    <>
      <ToastContainer />
      <div className="section-in-base background-color-3">
        <div className="container-480">
          <div className="flex-justify-center">
            <Link to="/home" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </Link>
          </div>
          <div className="margin-top-40 margin-bottom-40">
            <div className="w-form">
              <form>
                <h1 className="text-color-4 text-align-center">
                  🙋🏿‍♀️🙋🏾‍♂️🙋🏽
                  <br />
                  Become a Scout
                </h1>
                <p className="text-color-2 text-align-center">
                  We’ll text you a code to verify your phone
                </p>
                <div className="margin-top-10 relative">
                  <input
                    type="tel"
                    className="form-input light padding-left-20 w-input"
                    maxLength="15"
                    name="Phone-2"
                    data-name="Phone 2"
                    placeholder="Your phone number"
                    required=""
                    onChange={change}
                  />
                  <div
                    data-hover=""
                    data-delay="0"
                    className="absolute-top-left w-dropdown"
                    style={{ zIndex: "901" }}
                  >
                    <div
                      className="text-color-4 padding-y-4 w-dropdown-toggle w--open"
                      id="w-dropdown-toggle-0"
                      aria-controls="w-dropdown-list-0"
                      aria-haspopup="menu"
                      aria-expanded="true"
                      role="button"
                      onClick={onClickDropDown}
                    >
                      <div className="w-icon-dropdown-toggle"></div>
                      {countryShortCode}
                    </div>

                    {showDropdown ? (
                      <nav
                        className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w--open"
                        id="w-dropdown-list-0"
                        aria-labelledby="w-dropdown-toggle-0"
                      >
                        <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                          <div className="relative">
                            <input
                              style={{ color: "black" }}
                              type="text"
                              className="form-input light width-64 padding-left-24 margin-0 mobile-width-full w-input"
                              placeholder="Search country"
                              ref={ref}
                              value={query}
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                              onClick={onClickDropOpen}
                            />
                            <SearchIcon />
                          </div>
                        </div>
                        {filterCodes.map((code) => (
                          <a
                            style={{ cursor: "pointer" }}
                            className="padding-4 w-dropdown-link"
                            key={code.countryShortCode}
                            href="#!"
                            onClick={() => {
                              setQuery("");
                              setCountryShortCode(code.countryFlag);
                              setCountryCode(code.countryCode);
                              setShowDropdown(false);
                            }}
                          >
                            {code.countryFlag} {code.countryName}
                          </a>
                        ))}
                      </nav>
                    ) : null}
                  </div>
                </div>

                <div id="sign-in-button"></div>
                <button
                  style={{ marginTop: "5px" }}
                  type="submit"
                  onClick={handleClick}
                  disabled={phoneNumberValue.length < 9 || loading}
                  className="button-secondary width-full w-button"
                >
                  {(loading && <Loader />) || <span> Get Code</span>}
                </button>

                <div className="flex-justify-center margin-top-10">
                  <p className="text-color-2 margin-bottom-0 margin-right-1">
                    Already have an account?
                  </p>
                  <Link to="/login" style={{ color: "white" }}>
                    Log in
                  </Link>
                </div>
              </form>

              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneNumberSignUp;
