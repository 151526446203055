import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { APIS } from "../../../services/config";

const Orientation = (props) => {
  const [userData, setUserData] = useState();
  const [phoneNumber] = useState(
    JSON.parse(localStorage.getItem("phoneNumber"))
  );

  let history = useHistory();

  // utility function that removes 4th element from a phonenumber
  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  useEffect(() => {
    async function getData() {
      const payload = { docRef: remove_character(phoneNumber, 4) };
      const {
        baseUrl,
        getScout: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);
      setUserData(data);
    }
    getData();
  }, [phoneNumber]);

  const setData = () => {
    if (userData) {
      localStorage.setItem("user_data", JSON.stringify(userData?.data));
      history.push("/dashboard");
    }
  };
  return (
    <div>
      <div className="section-in-base background-color-3">
        <div className="container-1140">
          <div className="flex-justify-center">
            <a href="/" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
          </div>
          <div className="flex-justify-center margin-top-10">
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
          </div>
          <div className="flex-justify-center">
            <div className="margin-top-10 margin-bottom-40">
              <h1 className="text-color-4 text-align-center">
                Scouts’ Orientation
              </h1>
              <p className="text-color-2 text-align-center text-medium">
                Get familiar with Versus Scouts rules, and set yourself up for
                success
              </p>
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqEfPBt9dU60%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqEfPBt9dU60&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqEfPBt9dU60%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div className="flex-justify-center margin-top-10">
                <button className="button-secondary w-button">
                  <span onClick={setData}>Finish Orientation</span>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orientation;
