import React, { useState, useRef, useEffect } from "react";
import { SearchIcon } from "../../../assets/icons";
import { APIS } from "../../../services/config";
// import { useHistory } from "react-router-dom";
import axios from "axios";

const Location = (props) => {
  const {
    partners,
    handleNext,
    handleBack,
    prompt,
    value,
    values,
    stateValue,
    localGovernmentValue,
    referrerValue,
    statePrompt,
    localGovtPrompt,
    referrerPrompt,
    onStateChange,
    onChange,
    onLocalGovernmentChange,
    bankNameValue,
    accountNumberValue,
    onReferrerChange,
  } = props;

  // let history = useHistory();
  const [APIStates, setAPIStates] = useState("");
  const [APILocalGovernment, setAPILocalGovernment] = useState("");
  const [stateID, setStateId] = useState("");

  const [open, setOpen] = useState(false);
  const [openStateOptions, setOpenStateOptions] = useState(false);
  const [openLocalGovernmentOptions, setOpenLocalGovernmentStateOptions] =
    useState(false);
  const [openReferrerOptions, setOpenReferrerOptions] = useState(false);
  const [countrySearchQuery, setCountrySearchQuery] = useState("");
  const [stateSearchQuery, setStateSearchQuery] = useState("");
  const [localGovernmentSearchQuery, setLocalGovernmentSearchQuery] =
    useState("");

  const ref = useRef(null);
  const stateRef = useRef(null);
  const localGovtRef = useRef(null);
  const referrerRef = useRef(null);

  //Country Toggle
  const onClickCountryDropDown = () => setOpen(!open);
  const onClickCountryDropOpen = () => setOpen(open);

  //Get States
  useEffect(() => {
    const {
      baseUrl,
      getStates: { path },
    } = APIS;
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    const payload = { authstring: string };
    async function fetchStates() {
      const request = await axios.post(baseUrl + path, payload);
      setAPIStates(request?.data?.data);
    }
    fetchStates();
  }, []);

  //Get Local Government
  useEffect(() => {
    if (stateID) {
      const {
        baseUrl,
        getStatesLGA: { path },
      } = APIS;
      const string =
        window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
        window.location.hostname === "localhost"
          ? "55ad4986-b519-4e44-ab0b-890527299af6"
          : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

      const payload = { authstring: string, state_id: stateID };
      async function fetchLGA() {
        const request = await axios.post(baseUrl + path, payload);
        setAPILocalGovernment(request?.data?.data);
      }
      fetchLGA();
    }
  }, [stateID]);
  //GET Referrer
  useEffect(() => {
    function close(e) {
      setOpenReferrerOptions(e && e.target === referrerRef.current);
    }
    document.addEventListener("click", close);
    return () => document.removeEventListener("click", close);
  }, []);

  //Country Search Filter
  // var filterCountry = APIStates?.states?.filter((state) => {
  //   return state?.name
  //     .toString()
  //     .toLowerCase()
  //     .includes(countrySearchQuery.toString().toLowerCase());
  // });

  //State  Search Filter
  if (value !== "") {
    var filterState = APIStates?.states?.filter((state) => {
      return state?.name
        .toString()
        .toLowerCase()
        .includes(stateSearchQuery.toString().toLowerCase());
    });
  }

  //Local Govt.  Search Filter
  let filterLocalGovernment = APILocalGovernment?.lga?.filter((local) => {
    return local.name
      .toString()
      .toLowerCase()
      .includes(localGovernmentSearchQuery.toString().toLowerCase());
  });

  let phoneNumber = remove_character(
    JSON.parse(localStorage.getItem("phoneNumber")),
    4
  );

  // utility function that removes 4th element from a phonenumber
  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  let firstName = values?.firstName;
  let lastName = values?.lastName;
  let state = stateValue?.id;
  let localGovernmentArea = localGovernmentValue?.id;
  let scoutPartnerRef = referrerValue?.name;
  let bankName = bankNameValue?.name;
  let accountNumber = accountNumberValue;
  let bankCode = bankNameValue?.code;

  const payload = {
    phoneNumber,
    firstName,
    lastName,
    country: "NG",
    state,
    localGovernmentArea,
    scoutPartnerRef,
    bankName,
    accountNumber,
    bankCode,
  };

  const confirmLocation = async (e) => {
    e.preventDefault();

    const {
      baseUrl,
      createScout: { path },
    } = APIS;

    const request = await axios.post(baseUrl + path, payload);
    if (request.status === 201) {
      handleNext();
    } else {
      // toast.error(request.data.text, {
      //   autoClose: 5000,
      // });
      alert(request.data.text);
    }
  };
  const previousStep = () => {
    handleBack();
  };

  return (
    <>
      <div className="section-in-base background-color-3">
        <div className="container-1140">
          <div className="flex-justify-center">
            <a href="/" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
          </div>
          <div className="flex-justify-center margin-top-10">
            <div className="progress-bar active"></div>
            <div className="progress-bar active"></div>
            <div className="progress-bar"></div>
            <div className="progress-bar"></div>
          </div>
          <div className="flex-justify-center">
            <div className="margin-top-40 margin-bottom-40 max-width-medium">
              <div className="w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <h1 className="text-color-4 text-align-center">
                    Just a few more things
                  </h1>
                  <p className="text-color-2 text-align-center">
                    We need this information to send you relevant surveys
                  </p>
                  <div className="margin-top-10 grid-2-columns">
                    <div
                      data-hover=""
                      data-delay="0"
                      className="form-input width-full w-dropdown"
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle"
                        id="w-dropdown-toggle-0"
                        aria-controls="w-dropdown-list-0"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        onClick={onClickCountryDropDown}
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div ref={ref}>{value ? "Nigeria" : prompt}</div>
                      </div>
                      {open ? (
                        <nav className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w--open">
                          <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                            <div className="relative">
                              <input
                                style={{ color: "black" }}
                                type="tel"
                                className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                                placeholder="Search countries"
                                ref={ref}
                                value={countrySearchQuery}
                                onChange={(e) => {
                                  setCountrySearchQuery(e.target.value);
                                }}
                                onClick={onClickCountryDropOpen}
                              />
                              <SearchIcon />
                            </div>
                          </div>

                          <>
                            <a
                              style={{ cursor: "pointer" }}
                              className="padding-4 w-dropdown-link"
                              href="#!"
                              onClick={() => {
                                onChange("Nigeria");
                                setOpen(false);
                              }}
                            >
                              {"Nigeria"}
                            </a>
                          </>
                        </nav>
                      ) : null}
                    </div>

                    <div
                      data-hover=""
                      data-delay="0"
                      className="form-input width-full w-dropdown"
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle"
                        id="w-dropdown-toggle-1"
                        aria-controls="w-dropdown-list-1"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          setOpenStateOptions((prevState) => !prevState)
                        }
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div ref={stateRef}>
                          {stateValue ? stateValue.name : statePrompt}
                        </div>
                      </div>

                      <nav
                        className={`background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list ${
                          openStateOptions ? "w--open" : null
                        }`}
                        id="w-dropdown-list-1"
                      >
                        <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                          <div className="relative">
                            <input
                              style={{ color: "black" }}
                              type="tel"
                              className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                              placeholder="Search states"
                              ref={stateRef}
                              onChange={(e) => {
                                setStateSearchQuery(e.target.value);
                              }}
                              onClick={setOpenStateOptions}
                            />
                            <SearchIcon />
                          </div>
                        </div>
                        <>
                          {filterState &&
                            filterState?.map((state) => (
                              <a
                                href="#!"
                                style={{ cursor: "pointer" }}
                                className="padding-4 w-dropdown-link"
                                onClick={() => {
                                  onStateChange(state);
                                  setOpenStateOptions(false);
                                  setStateId(state?.id);
                                }}
                                key={state?.id}
                              >
                                {state.name}
                              </a>
                            ))}
                        </>
                      </nav>
                    </div>

                    <div
                      data-hover=""
                      data-delay="0"
                      id="w-node-bd8d7202-dbc4-567e-16d7-f8ba354f72fd-f7d0c2fd"
                      className="form-input width-full w-dropdown"
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle"
                        id="w-dropdown-toggle-2"
                        aria-controls="w-dropdown-list-2"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          setOpenLocalGovernmentStateOptions(
                            (prevStateLocal) => !prevStateLocal
                          )
                        }
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div ref={localGovtRef}>
                          {localGovernmentValue
                            ? localGovernmentValue.name
                            : localGovtPrompt}
                        </div>
                      </div>
                      <nav
                        className={`background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list ${
                          openLocalGovernmentOptions ? "w--open" : null
                        }`}
                        id="w-dropdown-list-1"
                      >
                        <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                          <div className="relative">
                            <input
                              style={{ color: "black" }}
                              type="tel"
                              className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                              placeholder="Search local government areas"
                              id="Phone-2"
                              ref={localGovtRef}
                              onChange={(e) => {
                                setLocalGovernmentSearchQuery(e.target.value);
                              }}
                              onClick={setOpenLocalGovernmentStateOptions}
                            />
                            <SearchIcon />
                          </div>
                        </div>
                        <>
                          {filterLocalGovernment?.map((local) => (
                            <a
                              href="#!"
                              style={{ cursor: "pointer" }}
                              className="padding-4 w-dropdown-link"
                              onClick={() => {
                                onLocalGovernmentChange(local);
                                setOpenLocalGovernmentStateOptions(false);
                              }}
                              key={local?.id}
                            >
                              {local.name}
                            </a>
                          ))}
                        </>
                      </nav>
                    </div>

                    <div
                      data-hover=""
                      data-delay="0"
                      id="w-node-_3b764474-ee0e-0ea8-a226-55f5313c3c7c-f7d0c2fd"
                      className="form-input width-full w-dropdown"
                      onClick={() =>
                        setOpenReferrerOptions(
                          (prevStateReferrer) => !prevStateReferrer
                        )
                      }
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle"
                        id="w-dropdown-toggle-3"
                        aria-controls="w-dropdown-list-3"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        tabIndex="0"
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div ref={referrerRef}>
                          {" "}
                          {referrerValue ? referrerValue.name : referrerPrompt}
                        </div>
                      </div>
                      <nav
                        className={`background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list ${
                          openReferrerOptions ? "w--open" : null
                        }`}
                        id="w-dropdown-list-3"
                        aria-labelledby="w-dropdown-toggle-3"
                      >
                        <>
                          {partners?.data?.scoutPartners?.map(
                            (scoutPartnerRef) => (
                              <a
                                href="#!"
                                style={{ cursor: "pointer" }}
                                className="padding-4 w-dropdown-link"
                                onClick={() => {
                                  onReferrerChange(scoutPartnerRef);
                                }}
                                key={scoutPartnerRef?.id}
                              >
                                {scoutPartnerRef.name}
                              </a>
                            )
                          )}
                        </>
                      </nav>
                    </div>
                  </div>
                  <div className="margin-top-6 padding-top-6 border-top-2px flex-space-between flex-align-baseline">
                    <button
                      data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                      className="button-secondary w-button"
                      onClick={previousStep}
                    >
                      Back
                    </button>

                    <button
                      disabled={!localGovernmentValue}
                      onClick={confirmLocation}
                      className="button-secondary margin-top-6 w-button"
                    >
                      Continue
                    </button>
                  </div>
                </form>
                <div className="background-color-transparent padding-0 w-form-done">
                  <p className="margin-top-10 text-color-2">
                    Didn’t get a code?{" "}
                    <a href="#!" className="text-color-4">
                      Resend it
                    </a>
                  </p>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
