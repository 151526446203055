import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GroupThreeIcon } from "../../assets/icons";

const Home = () => {
  const [nav, setNav] = useState(false);
  let history = useHistory();

  const openAndClose = () => {
    setNav(!nav);
  };

  const phone = () => {
    history.push({
      pathname: "/onboarding/phone-number",
    });
  };

  return (
    <>
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <Link to="/" className="w-nav-brand">
              <GroupThreeIcon className="versus-logo" />
            </Link>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-baseline w-nav-menu"
            >
              <a
                href="https://www.versus.africa/scouts-faqs"
                target="_blank"
                rel="noreferrer"
                className="nav-link w-nav-link"
              >
                FAQs
              </a>
              <Link to="/login" className="nav-link w-nav-link">
                Log In
              </Link>
              <Link
                to="/onboarding/phone-number"
                className="button-secondary margin-left-2 w-button"
              >
                Get Started
              </Link>
            </nav>

            <div className="margin-left-auto w-nav-button">
              <div className="w-icon-nav-menu" onClick={openAndClose}></div>
            </div>
          </div>

          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-0"
            style={{ height: "200px", display: "block" }}
          >
            {nav === true ? (
              <nav
                role="navigation"
                className="margin-left-auto flex-align-baseline w-nav-menu"
                style={{
                  transform: "translateY(0px) translateX(0px)",
                  transition: "transform 400ms ease 0s",
                  height: "282px",
                  backgroundColor: "#21074b",
                }}
                data-nav-menu-open=""
              >
                <a
                  href="https://www.versus.africa/scouts-faqs"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-link w-nav-link w--nav-link-open"
                  style={{ maxWidth: "1280px" }}
                >
                  FAQs
                </a>
                <Link
                  to="/login"
                  className="nav-link w-nav-link w--nav-link-open"
                  style={{ maxWidth: "1280px" }}
                >
                  Log in
                </Link>
                <Link
                  to="/onboarding/phone-number"
                  className="button-secondary margin-left-2 w-button"
                >
                  Get Started
                </Link>
              </nav>
            ) : null}
          </div>
        </div>
      </div>

      <div className="div-block-6"></div>
      <div className="section-in-base background-color-3 wf-section">
        <div className="container-1280">
          <div className="hero">
            <div
              id="w-node-cfffc76a-fb8c-5610-3f68-f86632311a46-f85bf252"
              className="hero-content-left"
            >
              <div className="heading-xl push-down">
                Brands want to work with you
              </div>
              <p className="text-color-4 text-medium margin-right-20 portrait-margin-right-0">
                A new way to collaborate with the brands you love. Answer
                surveys, complete requests, and earn money for your
                contributions.
                <br />
              </p>
              <div className="cta-buttons">
                <button
                  className="button-secondary w-button"
                  onClick={phone}
                  style={{ cursor: "pointer" }}
                >
                  Get Started
                </button>
              </div>
              <div className="div-block-5">
                <a
                  href="http://q-r.to/versusscouts"
                  target="_blank"
                  className="button-tertiary w-inline-block"
                  rel="noreferrer"
                >
                  <img
                    src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/6334013ef214e113d069070c_play_store-icon.svg"
                    loading="lazy"
                    alt=""
                    className="icon"
                  />
                  <div>Get it on Android</div>
                </a>
              </div>
            </div>
            <div
              id="w-node-_5fe1a442-0f58-dd56-8587-ea7d7125c03e-f85bf252"
              className="hero-content-right"
            >
              <div className="ask-vector">
                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/633430781d7f77885d41f88e_Versus%20Ask.svg"
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="listen-vector">
                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/633413eae4b0653f4dd3e01e_listen%20icon.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 32px, 56px"
                  srcSet="https://assets.website-files.com/602cf98191f0281f4b6d6db3/633413eae4b0653f4dd3e01e_listen%20icon-p-500.png 500w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/633413eae4b0653f4dd3e01e_listen%20icon-p-800.png 800w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/633413eae4b0653f4dd3e01e_listen%20icon-p-1080.png 1080w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/633413eae4b0653f4dd3e01e_listen%20icon.png 1377w"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="media-card">
                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/633422fb27d940836cd730f9_media%20card.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 47vw, (max-width: 767px) 39vw, (max-width: 991px) 290px, 22vw"
                  srcSet="https://assets.website-files.com/602cf98191f0281f4b6d6db3/633422fb27d940836cd730f9_media%20card-p-500.png 500w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/633422fb27d940836cd730f9_media%20card-p-800.png 800w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/633422fb27d940836cd730f9_media%20card.png 920w"
                  alt=""
                  className="meda-image-card"
                />
              </div>
              <div className="survey-card">
                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/6334276fc9e9cf1031b01c58_Survey%20card.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 205px, (max-width: 767px) 250px, 270px"
                  srcSet="https://assets.website-files.com/602cf98191f0281f4b6d6db3/6334276fc9e9cf1031b01c58_Survey%20card-p-500.png 500w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/6334276fc9e9cf1031b01c58_Survey%20card-p-800.png 800w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/6334276fc9e9cf1031b01c58_Survey%20card.png 920w"
                  alt=""
                  className="survey-image-card"
                />
              </div>
              <div className="hero-container">
                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/637600bec1f6035764bca399_Avatar%202.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 120px, 179px"
                  srcSet="https://assets.website-files.com/602cf98191f0281f4b6d6db3/637600bec1f6035764bca399_Avatar%202-p-500.png 500w, https://assets.website-files.com/602cf98191f0281f4b6d6db3/637600bec1f6035764bca399_Avatar%202.png 546w"
                  alt=""
                  className="image-6"
                />

                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/637600dbab00cb443141aa3e_avatar%203%20white.png"
                  loading="lazy"
                  alt=""
                  className="image-7"
                />

                <img
                  src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/637600ed3a6d6238db580d93_avatar%20white.png"
                  loading="lazy"
                  alt=""
                  className="image-8"
                />
              </div>
              <div className="hero-card-outline"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
