import { createSlice } from "@reduxjs/toolkit";
import {
  getScouts,
  getCampaign,
  getQuestion,
  getMissingData,
  setEndCampaign,
  addMultiMediaRequest,
  getBanks,
  verifyAccountNumber,
  convertAmount,
  cashOutToBank,
  cashOutAfrica,
  cashOutWithPaga,
  sendSMS,
  sendWhatsappMessage,
  saveWebToken,
  createNewSignUp,
  getStates,
  getStatesLGA,
  updateScoutData,
  deleteScout,
} from "../actions/surveyActions";

export const fetchScoutReducer = createSlice({
  name: "fetchScouts",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getScouts.pending]: (state, action) => {
      state.loading = true;
    },
    [getScouts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getScouts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const fetchCampaignReducer = createSlice({
  name: "fetchCampaign",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [getCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getCampaign.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const fetchQuestionReducer = createSlice({
  name: "fetchQuestion",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuestion.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getQuestion.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const addResponseReducer = createSlice({
  name: "addResponse",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getQuestion.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuestion.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getQuestion.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const fetchMissingDataReducer = createSlice({
  name: "fetchMissingData",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getMissingData.pending]: (state, action) => {
      state.loading = true;
    },
    [getMissingData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getMissingData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const setCampaignReducer = createSlice({
  name: "setCampaignEnd",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [setEndCampaign.pending]: (state, action) => {
      state.loading = true;
    },
    [setEndCampaign.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [setEndCampaign.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const setMultiMediaRequestReducer = createSlice({
  name: "setMultiMediaRequest",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [addMultiMediaRequest.pending]: (state, action) => {
      state.loading = true;
    },
    [addMultiMediaRequest.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [addMultiMediaRequest.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const fetchBanksReducers = createSlice({
  name: "fetchBanks",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getBanks.pending]: (state, action) => {
      state.loading = true;
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getBanks.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const verifyAccountNumberReducer = createSlice({
  name: "verifyAccount",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [verifyAccountNumber.pending]: (state, action) => {
      state.loading = true;
    },
    [verifyAccountNumber.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [verifyAccountNumber.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const convertAmountReducer = createSlice({
  name: "amountConverter",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [convertAmount.pending]: (state, action) => {
      state.loading = true;
    },
    [convertAmount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [convertAmount.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const cashOutToBankReducer = createSlice({
  name: "cashOutToBankAccount",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [cashOutToBank.pending]: (state, action) => {
      state.loading = true;
    },
    [cashOutToBank.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [cashOutToBank.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const cashOutAfricaReducer = createSlice({
  name: "cashOutAfricaAccount",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [cashOutAfrica.pending]: (state, action) => {
      state.loading = true;
    },
    [cashOutAfrica.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [cashOutAfrica.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const cashOutWithPagaReducer = createSlice({
  name: "cashOutToPagaAccount",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [cashOutWithPaga.pending]: (state, action) => {
      state.loading = true;
    },
    [cashOutWithPaga.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [cashOutWithPaga.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const sendSMSReducer = createSlice({
  name: "sendScoutSMS",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [sendSMS.pending]: (state, action) => {
      state.loading = true;
    },
    [sendSMS.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [sendSMS.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const sendWhatsappMessageReducer = createSlice({
  name: "sendScoutWhatsappMessage",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [sendWhatsappMessage.pending]: (state, action) => {
      state.loading = true;
    },
    [sendWhatsappMessage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [sendWhatsappMessage.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const saveWebTokenReducer = createSlice({
  name: "webTokenNotification",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [saveWebToken.pending]: (state, action) => {
      state.loading = true;
    },
    [saveWebToken.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [saveWebToken.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const createNewSignUpReducer = createSlice({
  name: "createSignUp",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [createNewSignUp.pending]: (state, action) => {
      state.loading = true;
    },
    [createNewSignUp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [createNewSignUp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const getStatesReducer = createSlice({
  name: "fetchAllStates",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getStates.pending]: (state, action) => {
      state.loading = true;
    },
    [getStates.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getStates.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const getStatesLGAReducer = createSlice({
  name: "fetchAllStatesLGA",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [getStatesLGA.pending]: (state, action) => {
      state.loading = true;
    },
    [getStatesLGA.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getStatesLGA.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const updateScoutDataReducer = createSlice({
  name: "updateTheScoutData",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [updateScoutData.pending]: (state, action) => {
      state.loading = true;
    },
    [updateScoutData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [updateScoutData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});

export const deleteScoutReducer = createSlice({
  name: "deleteScoutData",
  initialState: {
    data: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {},
  extraReducers: {
    [deleteScout.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteScout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [deleteScout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = payload;
    },
  },
});
