import React, { useState, useEffect } from "react";
import Loader from "../../Loader/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getBanks,
  createNewSignUp,
  getScouts,
} from "../../../actions/surveyActions";

const BankDetails = (props) => {
  const {
    values,
    handleNext,
    handleBack,
    onBankValueChange,
    onAccountNumberChange,
  } = props;
  const { firstName, lastName } = values;

  const [bankDetails, setBankDetails] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [query, setQuery] = useState("");
  const [accountNumber, setAccountNumber] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setSuccess] = useState("");
  const [, setError] = useState("");

  const dispatch = useDispatch();
  const fetchBanks = useSelector((state) => state.fetchBanks);
  // const fetchScout = useSelector((state) => state.fetchScout);

  const {
    data: { data: bankList },
  } = fetchBanks;
  // const { data: scoutData } = fetchScout;

  const onClickDropDown = () => setShowDropdown(!showDropdown);

  const getAccountNumberValue = (e) => {
    const limit = 10;
    setAccountNumber(e.target.value.slice(0, limit));
    onAccountNumberChange(e.target.value.slice(0, limit));
    if (bankDetails?.name !== undefined && e.target.value.length === 10) {
    }
  };

  //Bank Search Filter
  const filterBank = bankList?.banks?.filter((bank) => {
    return bank.name
      .toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase());
  });

  useEffect(() => {
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";

    dispatch(getScouts());

    dispatch(getBanks({ authstring: string }));
  }, [dispatch]);

  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  const confirmAccount = () => {
    setLoading(true);
    let phoneNumber = remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    );

    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
    dispatch(
      createNewSignUp({
        phoneNumber: phoneNumber,
        accountNumber: accountNumber,
        bankCode: bankDetails?.code,
        authstring: string,
        firstName: firstName,
        lastName: lastName,
      })
    )
      .unwrap()
      .then((payload) => {
        setLoading(true);
        setSuccess(payload);
        if (payload) {
          handleNext();
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        toast.error(err.message, {
          autoClose: 5000,
        });
      });
  };

  const previousStep = () => {
    handleBack();
  };
  return (
    <div>
      <ToastContainer />
      <div className="section-in-base background-color-3">
        <div className="container-1140">
          <div className="flex-justify-center">
            <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
              <div className="modal-section">
                <div>
                  <h3 className="text-color-4 margin-0">Bank Information</h3>
                </div>
                <div className="margin-top-6 w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                  >
                    <label htmlFor="name">What are your bank details?</label>
                    <div
                      data-hover=""
                      data-delay="0"
                      className="form-input width-full w-dropdown"
                      style={{ zIndex: "901" }}
                    >
                      <div
                        className="text-color-4 padding-0 width-full w-dropdown-toggle w--open"
                        id="w-dropdown-toggle-1"
                        aria-controls="w-dropdown-list-1"
                        aria-haspopup="menu"
                        aria-expanded="false"
                        role="button"
                        onClick={onClickDropDown}
                      >
                        <div className="margin-0 margin-top-1 w-icon-dropdown-toggle"></div>
                        <div>
                          {bankDetails?.name === undefined
                            ? "Select bank"
                            : bankDetails?.name}
                        </div>
                      </div>
                      {showDropdown ? (
                        <nav
                          className="background-color-white rounded-large mobile-fixed-bottom mobile-border-bottom-rounded-none dropdown-list w-dropdown-list w--open"
                          id="w-dropdown-list-1"
                          aria-labelledby="w-dropdown-toggle-1"
                        >
                          <div className="padding-4 background-color-white sticky-top-0 rounded-large z-10 border-bottom-1px bottom-rounded-none">
                            <div className="relative">
                              <input
                                type="tel"
                                className="form-input light width-64 padding-left-24 margin-0 mobile-width-full width-full w-input"
                                maxLength="256"
                                name="Phone-2"
                                data-name="Phone 2"
                                placeholder="Search banks"
                                id="Phone-2"
                                required=""
                                value={query}
                                style={{ color: "black" }}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-search absolute-top-left icon margin-4"
                                src="https://uploads-ssl.webflow.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                            </div>
                          </div>
                          {filterBank?.map((bank) => (
                            <a
                              href="#!"
                              className="padding-4 w-dropdown-link"
                              key={bank.id}
                              onClick={() => {
                                setBankDetails(bank);
                                setShowDropdown(false);
                                onBankValueChange(bank);
                              }}
                            >
                              {bank.name}
                            </a>
                          ))}
                        </nav>
                      ) : null}
                    </div>

                    <input
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="number"
                      className="form-input margin-top-2 w-input"
                      name="Bank"
                      data-name="Bank"
                      placeholder="Account number"
                      id="Bank"
                      maxLength="10"
                      onChange={getAccountNumberValue}
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>

                <div className="margin-top-6 padding-top-6 border-top-2px flex-space-between flex-align-baseline">
                  <button
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                    onClick={previousStep}
                  >
                    Back
                  </button>
                  <button
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                    onClick={confirmAccount}
                    disabled={
                      bankDetails?.code?.length === 0 ||
                      accountNumber?.length < 10
                    }
                  >
                    {(loading && <Loader />) || (
                      <span style={{ marginLeft: "5px" }}>Next</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
