import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Loader from "../../utils/Loader";

const SurveyStart = ({ location }) => {
  const { campaigns } = location.state || {};

  const [disabled, setDisabled] = useState(true);
  const [media] = useState(campaigns?.media_link !== null ? true : false);

  return (
    <div>
      {/* <Loader /> */}
      <div
        data-collapse="small"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="background-color-3 padding-top-4 w-nav"
      >
        <div className="container-1280 w-container">
          <div className="flex-row-middle padding-bottom-4 border-bottom-2px">
            <a href="#!" className="w-nav-brand">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </a>
            <nav
              role="navigation"
              className="margin-left-auto flex-align-center w-nav-menu"
            >
              <a
                href="/sign-up"
                className="button-secondary button-small disabled margin-right-2 w-button"
              >
                Cash out
              </a>
              <div
                data-hover=""
                data-delay="0"
                className="w-dropdown"
                style={{ maxWidth: "1280px" }}
              >
                <div
                  className="background-color-2 radius-full height-10 width-10 padding-1 flex-align-center flex-justify-center background-color-4 w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon text-color-4"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <nav
                  className="dropdown right box-shadow-medium w-dropdown-list"
                  id="w-dropdown-list-0"
                  aria-labelledby="w-dropdown-toggle-0"
                >
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Settings
                  </a>
                  <a href="/" className="w-dropdown-link" tabIndex="0">
                    Log out
                  </a>
                </nav>
              </div>
            </nav>
            <div
              className="margin-left-auto w-nav-button"
              aria-label="menu"
              role="button"
              tabIndex="0"
              aria-controls="w-nav-overlay-0"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <div className="w-icon-nav-menu"></div>
            </div>
          </div>
        </div>
        <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
        ></div>
      </div>
      <div className="section-in-base background-color-3">
        <div className="container-1280">
          <p className="text-2xl weight-bold">
            0 <span className="text-medium weight-normal">scout credits</span>
          </p>
        </div>
      </div>
      <div className="background-color-white">
        <div className="container-1280">
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="w-tabs"
          >
            <div
              className="page-tabs margin-bottom-6 w-tab-menu"
              role="tablist"
            >
              <a
                data-w-tab="Tab 1"
                className="tab w-inline-block w-tab-link w--current"
                id="w-tabs-0-data-w-tab-0"
                href="#w-tabs-0-data-w-pane-0"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-0"
                aria-selected="true"
              >
                <div className="text-color-4">1 Survey</div>
              </a>
              <a
                data-w-tab="Tab 2"
                className="tab w-inline-block w-tab-link"
                tabIndex="-1"
                id="w-tabs-0-data-w-tab-1"
                href="#w-tabs-0-data-w-pane-1"
                role="tab"
                aria-controls="w-tabs-0-data-w-pane-1"
                aria-selected="false"
              >
                <div>
                  <span>0</span> Requests
                </div>
              </a>
            </div>
            <div className="tabs-content w-tab-content">
              <div
                data-w-tab="Tab 1"
                className="grid-3-columns w-tab-pane w--tab-active"
                id="w-tabs-0-data-w-pane-0"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-0"
              >
                <div className="radius-large background-color-white box-shadow-large padding-6 border-1px">
                  <h4>Earn 9 starting credits with the demographics survey!</h4>
                  <div className="flex-align-center margin-top-6 flex-space-between">
                    <div className="flex-align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-message-square icon text-color-3 margin-right-1"
                        src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                        loading="lazy"
                        alt=""
                      >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      <div className="text-color-2">9</div>
                    </div>
                    <a href="/sign-up" className="button button-small w-button">
                      Start{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right icon margin-left-1"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                data-w-tab="Tab 2"
                className="w-tab-pane"
                id="w-tabs-0-data-w-pane-1"
                role="tabpanel"
                aria-labelledby="w-tabs-0-data-w-tab-1"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="w-embed"></div>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h3 className="text-color-4 margin-0">{campaigns?.campaignName}</h3>
            <p>{campaigns?.description}</p>

            {/* Media Section of Survey */}
            {media && (
              <div className="survey-media image-8">
                <iframe
                  width="528"
                  height="300"
                  src={campaigns?.media_link}
                  title="YouTube video player"
                  frameBorder="0"
                ></iframe>
              </div>
            )}

            <h5 className="text-color-4 margin-top-10">Details</h5>
            <p>
              Payout is{" "}
              <span className="text-color-1">
                {campaigns?.versusCreditsAccruing} Scout Credits
              </span>
            </p>
            <div className="padding-top-6 margin-top-6 border-top-2px flex-space-between flex-align-baseline portrait-flex-column">
              <div className="w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      data-name="Checkbox"
                      className="w-checkbox-input"
                      onChange={(e) => setDisabled(!disabled)}
                    />
                    <span className="w-form-label">
                      I agree to the{" "}
                      <a
                        href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
                        target="_blank"
                        rel="noreferrer"
                        className="text-color-4 underline"
                      >
                        terms
                      </a>{" "}
                      &amp;{" "}
                      <a
                        href="https://doc.versus.africa/versus/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        className="text-color-4 underline"
                      >
                        privacy policy
                      </a>
                    </span>
                  </label>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <Link
                to={{
                  pathname: "/dashboard/survey",
                  state: { campaigns },
                }}
              >
                <button
                  disabled={disabled || campaigns?.versusCreditsAccruing === 0}
                  data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                  className="button-secondary w-button"
                >
                  Begin
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyStart;
