import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { deleteScout } from "../../actions/surveyActions";
import Loader from "../Loader/loader";

const ConfirmAccountDeletion = ({ selectedOption, selectedOtherOption }) => {
  const [loading, setLoading] = useState(false);
  const [, setSuccess] = useState("");
  const [, setError] = useState("");

  const dispatch = useDispatch();
  let history = useHistory();
  const fetchScout = useSelector((state) => state.fetchScout);

  const { data: scoutData } = fetchScout;

  const confirmDelete = () => {
    setLoading(true);
    const string =
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
    dispatch(
      deleteScout({
        authstring: string,
        scoutRef: scoutData?.data?.scoutRef,
        reasonForDeletion:
          selectedOption === undefined ? selectedOtherOption : selectedOption,
      })
    )
      .unwrap()
      .then((payload) => {
        setLoading(true);
        setSuccess(payload);
        if (payload) {
          history.push({
            pathname: "/",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <>
      <div
        className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl"
        bis_skin_checked="1"
      >
        <div className="modal-section" bis_skin_checked="1">
          <div bis_skin_checked="1">
            <h5 className="text-color-4 margin-0">Delete Account</h5>
          </div>
          <div
            className="flex-justify-center neg-margin margin-top-3"
            bis_skin_checked="1"
          >
            <div
              className="progress-bar expand active"
              bis_skin_checked="1"
            ></div>
            <div
              className="progress-bar expand active"
              bis_skin_checked="1"
            ></div>
          </div>
          <div className="margin-top-6 w-form" bis_skin_checked="1">
            <form id="email-form">
              <div
                className="margin-top-6 padding-top-6 border-top-2px"
                bis_skin_checked="1"
              >
                <h3 className="text-color-4">
                  Are you sure you want to delete your account?
                </h3>
                <p>
                  This action cannot be reversed. When you delete your account,
                  all your data will be erased from our system.
                </p>
              </div>
            </form>
            <div
              className="w-form-done"
              tabIndex="-1"
              role="region"
              aria-label="Email Form success"
              bis_skin_checked="1"
            ></div>
            <div
              className="w-form-fail"
              tabIndex="-1"
              role="region"
              aria-label="Email Form failure"
              bis_skin_checked="1"
            ></div>
          </div>
          <div
            className="margin-top-6 padding-top-6 border-top-2px flex-justify-end flex-space-between"
            bis_skin_checked="1"
          >
            <Link to="/dashboard" className="button-secondary subtle w-button">
              Cancel
            </Link>
            <button
              onClick={confirmDelete}
              className="button-destructive w-button"
            >
              {(loading && <Loader />) || (
                <span style={{ marginLeft: "5px" }}>Yes, delete</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmAccountDeletion;
