import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";

const RequestStart = ({ location }) => {
  const [disabled, setDisabled] = useState(true);
  const { request } = location.state || {};

  return (
    <div>
      <Dashboard />
      <div className="modal">
        <Link
          to="/dashboard"
          className="modal-bg w-inline-block"
          style={{ backdropFilter: "blur(16px)" }}
        ></Link>
        <div className="padding-6 background-color-3 radius-large box-shadow-large width-full max-width-xl">
          <div className="modal-section">
            <h3 className="text-color-4 margin-0">{request?.requestName}</h3>
            <p className="margin-top-2">{request?.description}</p>

            <>
              <h5 className="text-color-4 margin-top-10">
                {request?.instructions ? "Instructions" : null}
              </h5>

              <div className="w-richtext">
                {request?.instructions === null ? null : (
                  <ul>
                    <li>{request?.instructions}</li>
                  </ul>
                )}
              </div>
            </>

            <>
              <div className="div-block-7">
                <p>
                  <a
                    href={request?.link}
                    target="_blank"
                    className="text-color-1"
                    without
                    rel="noreferrer"
                  >
                    {request?.link ? "Open link" : null}
                  </a>
                </p>
                {request?.link === null ? null : (
                  <svg
                    style={{ width: "16px", height: "16px" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-external-link icon margin-left-1 text-color-1 _16px"
                    loading="lazy"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    alt=""
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                )}
              </div>
            </>

            <h5 className="text-color-4 margin-top-10">Details</h5>
            <p>
              Payout is{" "}
              <span className="text-color-1">
                {request?.versusCreditsAccruing} Scout Credits
              </span>
            </p>
            <p>
              Accepted media:
              <a href="#!" className="text-color-1">
                &nbsp;{request?.mediaType}
              </a>
              <span className="text-color-1"></span>
            </p>
            <div className="padding-top-6 margin-top-6 border-top-2px flex-space-between flex-align-center portrait-flex-column portrait-flex-align-start">
              <div className="w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <label className="w-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      data-name="Checkbox"
                      className="w-checkbox-input"
                      onChange={(e) => setDisabled(!disabled)}
                    />
                    <span className="w-form-label">
                      I agree to the&nbsp;
                      <a
                        href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
                        target="_blank"
                        rel="noreferrer"
                        className="text-color-4 underline"
                      >
                        terms
                      </a>{" "}
                      &amp;&nbsp;
                      <a
                        href="https://doc.versus.africa/versus/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        className="text-color-4 underline"
                      >
                        privacy policy
                      </a>
                    </span>
                  </label>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              {request?.mediaType === "photo" ? (
                <Link
                  to={{
                    pathname: "/requestPhoto",
                    state: { request },
                  }}
                >
                  <button
                    disabled={disabled}
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                  >
                    Begin
                  </button>
                </Link>
              ) : request?.mediaType === "video" ? (
                <Link
                  to={{
                    pathname: "/requestVideo",
                    state: { request },
                  }}
                >
                  <button
                    disabled={disabled}
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                  >
                    Begin
                  </button>
                </Link>
              ) : request?.mediaType === "audio" ? (
                <Link
                  to={{
                    pathname: "/requestAudio",
                    state: { request },
                  }}
                >
                  <button
                    disabled={disabled}
                    data-w-id="f0d8f6be-1456-3ea5-e00b-c2c36cfbde62"
                    className="button-secondary w-button"
                  >
                    Begin
                  </button>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestStart;
