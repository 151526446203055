import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import InputCode from "./components/Onboarding/InputCode";
import LogIn from "./components/Login/LogIn";
import InputPhoneNumber from "./components/Onboarding/InputPhoneNumber";
import FormStepsLogic from "./components/Onboarding/FormStepsLogic";
import Orientation from "./components/Onboarding/FormSteps/Orientation";
import SurveyEnds from "./components/dashboard/SurveyEnds";
import Survey from "./components/dashboard/Survey";
import SurveyNotification from "./components/dashboard/SurveyNotification";
import SurveyStart from "./components/dashboard/SurveyStart";
import DashboardEmpty from "./components/dashboard/DashboardEmpty";
import Dashboard from "./components/dashboard/Dashboard";
import CashOutSuccess from "./components/CashOut/CashOutSuccess";
import CashOutPaga from "./components/CashOut/CashOutPaga";
import CashOutBank from "./components/CashOut/CashOutBank";
import CashOutAmount from "./components/CashOut/CashOutAmount";
import PendingResponse from "./components/dashboard/PendingResponse";
import RejectedResponse from "./components/dashboard/RejectedResponse";
import DisputeResponse from "./components/dashboard/DisputeResponse";
import RequestStart from "./components/dashboard/RequestStart";
import RequestUpload from "./components/dashboard/RequestUpload";
import RequestUploaded from "./components/dashboard/RequestUploaded";
import RequestSubmitSuccess from "./components/dashboard/RequestSubmitSuccess";
import DemographicSurvey from "./components/dashboard/DemographicSurvey";
import ProtectedRoutes from "./services/protectedRoutes";
import DemographicSurveyStart from "./components/dashboard/DemographicSurveyStart";
import RequestVideo from "./components/dashboard/RequestVideo";
import RequestAudio from "./components/dashboard/RequestAudio";
import RequestPhoto from "./components/dashboard/RequestPhoto";
import Profile from "./components/UserData/Profile";
import Settings from "./components/UserData/Settings";
import ApprovedResponse from "./components/dashboard/ApprovedResponse";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <ProtectedRoutes
            path="/requestVideo"
            exact
            component={RequestVideo}
          />
          <ProtectedRoutes
            path="/requestAudio"
            exact
            component={RequestAudio}
          />
          <ProtectedRoutes
            path="/requestPhoto"
            exact
            component={RequestPhoto}
          />
          <ProtectedRoutes
            path="/request-submit-success"
            exact
            component={RequestSubmitSuccess}
          />
          <ProtectedRoutes
            path="/requestUploaded"
            exact
            component={RequestUploaded}
          />
          <ProtectedRoutes
            path="/requestUpload"
            exact
            component={RequestUpload}
          />
          <ProtectedRoutes
            path="/requestStart"
            exact
            component={RequestStart}
          />
          <ProtectedRoutes
            path="/disputedResponse"
            exact
            component={DisputeResponse}
          />
          <ProtectedRoutes
            path="/rejectedResponse"
            exact
            component={RejectedResponse}
          />
          <ProtectedRoutes
            path="/approvedResponse"
            exact
            component={ApprovedResponse}
          />
          <ProtectedRoutes
            path="/pendingResponse"
            exact
            component={PendingResponse}
          />
          <ProtectedRoutes path="/settings" exact component={Settings} />
          <ProtectedRoutes path="/profile" exact component={Profile} />
          <ProtectedRoutes path="/cashout/paga" exact component={CashOutPaga} />
          <ProtectedRoutes
            path="/cashout/success"
            exact
            component={CashOutSuccess}
          />
          <ProtectedRoutes
            path="/cashout/select-amount"
            exact
            component={CashOutAmount}
          />
          <ProtectedRoutes
            path="/cashout/bank-details"
            exact
            component={CashOutBank}
          />
          <ProtectedRoutes
            path="/dashboard/survey/start"
            exact
            component={SurveyStart}
          />
          <ProtectedRoutes path="/dashboard/survey" exact component={Survey} />
          <ProtectedRoutes
            path="/dashboard/survey/end"
            exact
            component={SurveyEnds}
          />
          <ProtectedRoutes
            path="/demographic-survey-start"
            exact
            component={DemographicSurveyStart}
          />
          <ProtectedRoutes
            path="/demographic-survey"
            exact
            component={DemographicSurvey}
          />
          <ProtectedRoutes
            path="/dashboard-demographic-notification"
            exact
            component={SurveyNotification}
          />
          <ProtectedRoutes path="/dashboard" exact component={Dashboard} />
          <ProtectedRoutes
            path="/dashboard-empty"
            exact
            component={DashboardEmpty}
          />
          <Route path="/orientation" exact component={Orientation} />
          <Route path="/onboarding/name" exact component={FormStepsLogic} />
          <Route path="/onboarding/code" exact component={InputCode} />
          <Route
            path="/onboarding/phone-number"
            exact
            component={InputPhoneNumber}
          />

          <Route path="/login" exact component={LogIn} />
          <Route path="/home" exact component={Home} />
          <Route path="/" exact component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
