import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIS } from "../../services/config";
import axios from "axios";
import firebase from "../../services/firebase";

const InputCode = ({ location }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [confirm] = useState(JSON.parse(localStorage.getItem("confirm")));
  const [userData, setUserData] = useState();
  const [phoneNumber] = useState(
    JSON.parse(localStorage.getItem("phoneNumber"))
  );
  let history = useHistory();

  // utility function that removes 4th element from a phonenumber
  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  useEffect(() => {
    async function getPayload() {
      const payload = {
        docRef: remove_character(
          JSON.parse(localStorage.getItem("phoneNumber")),
          4
        ),
      };
      const {
        baseUrl,
        getScout: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);
      setUserData(data);
    }
    getPayload();
  }, [phoneNumber]);

  const handleInputChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((data, idx) => (idx === index ? element.value : data))]);
    //Move to next Input Box
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // const random = JSON.parse(location.state);

    // if (Number(otp.join("")) !== random) {
    //   return toast.error("Incorrect code, please try again.", {
    //     autoClose: 5000,
    //   });
    // }

    // if (
    //   Number(otp.join("")) === random &&
    //   userData?.data?.firstName?.length > 1
    // ) {
    //   localStorage.setItem("user_data", JSON.stringify(userData?.data));
    //   history.push("/dashboard");
    //   localStorage.removeItem("random");
    // } else {
    //   localStorage.setItem("user_data", JSON.stringify({}));
    //   history.push("/onboarding/name");
    //   localStorage.removeItem("random");
    // }

    let credential = firebase.auth.PhoneAuthProvider.credential(
      confirm.verificationId,
      otp.join("")
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {
        if (userData?.data?.firstName?.length > 1) {
          localStorage.setItem("user_data", JSON.stringify(userData?.data));
          history.push("/dashboard");
          localStorage.removeItem("random");
        } else {
          localStorage.setItem("user_data", JSON.stringify({}));
          history.push("/onboarding/name");
          localStorage.removeItem("random");
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: 10000,
        });
      });
  };

  //Resend Code Function
  const handleResendClick = (e) => {
    e.preventDefault();
    let reCaptcha = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
    });
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, reCaptcha)
      .then((confirmationResult) => {
        localStorage.setItem("confirm", JSON.stringify(confirmationResult));
      })
      .catch((error) => {
        toast.error(error.message, {
          autoClose: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="section-in-base background-color-3">
        <div className="container-480">
          <div className="flex-justify-center">
            <Link to="/" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </Link>
          </div>
          <div className="margin-top-40 margin-bottom-40">
            <div className="w-form">
              <form>
                <h1 className="text-color-4 text-align-center">
                  What’s the code?
                </h1>
                <p className="text-color-2 text-align-center">
                  Enter the code sent to{" "}
                  <strong className="text-color-4">{phoneNumber}</strong>
                </p>
                <div className="grid-6-columns margin-top-10">
                  {otp.map((data, index) => {
                    return (
                      <input
                        pattern="[0-9]*"
                        inputMode="numeric"
                        type="text"
                        className="form-input light code w-input"
                        placeholder="0"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleInputChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                </div>
                <button
                  onClick={handleClick}
                  disabled={otp.includes("")}
                  type="submit"
                  className="width-full button-secondary margin-top-4 w-button"
                >
                  Verify code
                </button>
                <div id="sign-in-button"></div>

                <p className="margin-top-10 text-color-2 text-align-center">
                  Didn’t get a code?{" "}
                  <a
                    onClick={handleResendClick}
                    href="#/"
                    className="text-color-4"
                  >
                    Resend it
                  </a>
                </p>
              </form>

              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputCode;
