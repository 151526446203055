import React from "react";
import { Link } from "react-router-dom";

const Name = (props) => {
  const { handleChange, values, handleNext } = props;
  const { firstName, lastName } = values;
  return (
    <>
      <div className="section-in-base background-color-3">
        <div className="container-1140">
          <div className="flex-justify-center">
            <Link to="/" className="w-inline-block">
              <img
                src="https://assets.website-files.com/602cf98191f0281f4b6d6db3/602d079c71c9d0b8923420d7_Group%203.svg"
                loading="lazy"
                alt=""
                className="versus-logo"
              />
            </Link>
          </div>
          <div className="flex-justify-center margin-top-10">
            <div className="progress-bar active"></div>
            <div className="progress-bar"></div>
            <div className="progress-bar"></div>
            <div className="progress-bar"></div>
          </div>
          <div className="flex-justify-center">
            <div className="margin-top-40 margin-bottom-40 max-width-medium">
              <div className="w-form">
                <form>
                  <h1 className="text-color-4 text-align-center">
                    What’s your name?
                  </h1>
                  <p className="text-color-2 text-align-center">
                    We need this information to be able pay you
                  </p>
                  <div className="margin-top-10 grid-2-columns">
                    <input
                      type="text"
                      onChange={handleChange("firstName")}
                      className="form-input light margin-0 w-input"
                      maxLength="256"
                      name="First-Name"
                      placeholder="First name"
                      id="First-Name"
                      required
                    />
                    <input
                      type="text"
                      onChange={handleChange("lastName")}
                      className="form-input light margin-0 w-input"
                      maxLength="256"
                      name="Last-name"
                      placeholder="Last name"
                      id="Last-name"
                      required
                    />
                  </div>
                  <button
                    disabled={firstName.length === 0 || lastName.length === 0}
                    onClick={handleNext}
                    className="button-secondary width-full margin-top-6 w-button"
                  >
                    Save Name
                  </button>
                </form>
                <div className="background-color-transparent padding-0 w-form-done">
                  <p className="margin-top-10 text-color-2">
                    Didn’t get a code?{" "}
                    <a href="#!" className="text-color-4">
                      Resend it
                    </a>
                  </p>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-justify-center">
            <a
              href="https://doc.versus.africa/versus/terms-and-conditions-versus-scouts"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Terms
            </a>
            <a
              href="https://doc.versus.africa/versus/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="text-color-4 padding-2"
            >
              Privacy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Name;
