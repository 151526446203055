import { createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { APIS } from "../services/config";

function remove_character(str, char_pos) {
  let part1 = str?.substring(0, char_pos);
  let part2 = str?.substring(char_pos + 1, str.length);
  return part1 + part2;
}

export const actionCreator = (type, payload) => ({ type, payload });

export const getScouts = createAsyncThunk(
  "getScouts",
  async (object, { dispatch, getState, rejectWithValue }) => {
    try {
      const payload = {
        docRef: remove_character(
          JSON.parse(localStorage.getItem("phoneNumber")),
          4
        ),
      };
      const {
        baseUrl,
        getScout: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);

      if (data.status === 200) {
        dispatch(actionCreator(data, "getScout"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCampaign = createAsyncThunk(
  "getCampaign",
  async (object, { getState, rejectWithValue }) => {
    try {
      const payload = {
        phoneNumber: remove_character(
          JSON.parse(localStorage.getItem("phoneNumber")),
          4
        ),
      };
      const {
        baseUrl,
        getCampaigns: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getQuestion = createAsyncThunk(
  "getQuestion",
  async (docRef, { getState, rejectWithValue }) => {
    try {
      // const payload = {
      //   docRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
      //   scoutRef: getUser().phoneNumber,
      // };
      const {
        baseUrl,
        getQuestions: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, docRef);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const addResponse = createAsyncThunk(
  "addResponse",
  async (
    {
      authtoken,
      cRef,
      qRef,
      sRef,
      questionType,
      freeform_response,
      responseOptions,
      clientRef,
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        addResponse: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authtoken,
        cRef,
        qRef,
        sRef,
        questionType,
        freeform_response,
        responseOptions,
        clientRef,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMissingData = createAsyncThunk(
  "getMissingData",
  async (object, { getState, rejectWithValue }) => {
    try {
      const string =
        window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
        window.location.hostname === "localhost"
          ? "55ad4986-b519-4e44-ab0b-890527299af6"
          : "b8ba31ef-4570-44ff-95af-e80eddf98f82";
      const payload = {
        authString: string,
        scoutRef: remove_character(
          JSON.parse(localStorage.getItem("phoneNumber")),
          4
        ),
      };
      const {
        baseUrl,
        getMissingData: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, payload);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const setEndCampaign = createAsyncThunk(
  "setEndCampaign",
  async (
    { phoneNumber, campaignRef, authstring },
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        setEndCampaign: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        campaignRef,
        authstring,
      });
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const mediaApi = createApi({
  reducerPath: "posts",
  baseQuery: fetchBaseQuery({
    baseUrl:
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "https://us-central1-versus-dev-212614.cloudfunctions.net"
        : "https://us-central1-versus-prod.cloudfunctions.net",
  }),
  endpoints: (builder) => ({
    getMediaByDetails: builder.query({
      query: (arg) => {
        const { phoneNumber, authString, mediaType } = arg;
        return {
          url: "scout_get_multimedia_requests/",
          params: { phoneNumber, authString, mediaType },
        };
      },
    }),
    getResponsesByDetails: builder.query({
      query: (arg) => {
        const { phoneNumber, authString } = arg;
        return {
          url: "scout_get_request_responses/",
          params: { phoneNumber, authString },
        };
      },
    }),
  }),
});

export const { useGetMediaByDetailsQuery, useGetResponsesByDetailsQuery } =
  mediaApi;

export const addMultiMediaRequest = createAsyncThunk(
  "addMultiMediaRequest",
  async (
    { requestRef, scoutRef, mediaType, mediaUrls, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        addMultiMediaRequest: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        requestRef,
        scoutRef,
        mediaType,
        mediaUrls,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "addMultiMediaRequest"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBanks = createAsyncThunk(
  "getBanks",
  async ({ authstring }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        getBanks: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "getBanks"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyAccountNumber = createAsyncThunk(
  "verifyAccountNumber",
  async (
    { phoneNumber, accountNumber, bankCode, bankName, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        verifyAccountNumber: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        accountNumber,
        bankCode,
        bankName,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "verifyAccountNumber"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const convertAmount = createAsyncThunk(
  "convertAmount",
  async (
    { amount, country, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        convertAmount: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        amount,
        country,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "convertAmount"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cashOutToBank = createAsyncThunk(
  "cashOutToBank",
  async (
    { phoneNumber, amount, accountNumber, country, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        cashOutToBank: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        amount,
        accountNumber,
        country,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "cashOutToBank"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cashOutAfrica = createAsyncThunk(
  "cashOutAfrica",
  async (
    { phoneNumber, firstName, lastName, amount, country, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        cashOutAfrica: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        firstName,
        lastName,
        amount,
        country,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "cashOutAfrica"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cashOutWithPaga = createAsyncThunk(
  "cashOutWithPaga",
  async (
    { authstring, country, phoneNumber, amount },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        cashOutWithPaga: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authstring,
        country,
        phoneNumber,
        amount,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "cashOutWithPaga"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendSMS = createAsyncThunk(
  "sendSMS",
  async (
    { phoneNumber, message, authstring },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        sendSMS: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        message,
        authstring,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "sendSMS"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendWhatsappMessage = createAsyncThunk(
  "sendWhatsappMessage",
  async ({ phoneNumber, message }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        sendWhatsappMessage: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        message,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "sendWhatsappMessage"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveWebToken = createAsyncThunk(
  "saveWebToken",
  async (
    { authstring, scoutRef, webDeviceRegistrationToken },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        saveWebToken: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authstring,
        scoutRef,
        webDeviceRegistrationToken,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "saveWebToken"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const createNewSignUp = createAsyncThunk(
  "createNewSignUp",
  async (
    { phoneNumber, accountNumber, bankCode, authstring, firstName, lastName },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        createNewSignUp: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        phoneNumber,
        accountNumber,
        bankCode,
        authstring,
        firstName,
        lastName,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "createNewSignUp"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteScout = createAsyncThunk(
  "deleteScout",
  async (
    { authstring, scoutRef, reasonForDeletion },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        baseUrl,
        deleteScout: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        authstring,
        scoutRef,
        reasonForDeletion,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "deleteScout"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStates = createAsyncThunk(
  "getStates",
  async (object, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        getStates: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path);
      if (data.status === 200) {
        dispatch(actionCreator(data, "getStates"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStatesLGA = createAsyncThunk(
  "getStatesLGA",
  async ({ state_id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        getStatesLGA: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        state_id,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "getStatesLGA"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateScoutData = createAsyncThunk(
  "updateScoutData",
  async ({ state_id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        baseUrl,
        updateScoutData: { path },
      } = APIS;
      const data = await axios.post(baseUrl + path, {
        state_id,
      });
      if (data.status === 200) {
        dispatch(actionCreator(data, "updateScoutData"));
      }
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
