const countryList = [
  {
    countryName: "Nigeria",
    countryShortCode: "NG",
    countryCode: "+234",
    countryFlag: "🇳🇬",
  },
  {
    countryName: "Kenya",
    countryShortCode: "KE",
    countryCode: "+254",
    countryFlag: "🇰🇪",
  },
  {
    countryName: "Ghana",
    countryShortCode: "GH",
    countryCode: "+233",
    countryFlag: "🇬🇭",
  },
  {
    countryName: "South Africa",
    countryShortCode: "SA",
    countryCode: "+27",
    countryFlag: "🇿🇦",
  },
];
export default countryList;
