import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetMediaByDetailsQuery } from "../../actions/surveyActions";

const Requests = ({ pendingRequests }) => {
  const [toggleRequestTabs, setToggleRequestTabs] = useState(1);
  const [completed, setCompleted] = useState([]);

  function remove_character(str, char_pos) {
    let part1 = str?.substring(0, char_pos);
    let part2 = str?.substring(char_pos + 1, str.length);
    return part1 + part2;
  }

  const { data: allRequests } = useGetMediaByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString:
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
    mediaType: "",
  });

  const { data: photoRequests } = useGetMediaByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString:
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
    mediaType: "photo",
  });

  const { data: videoRequests } = useGetMediaByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString:
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
    mediaType: "video",
  });

  const { data: audioRequests } = useGetMediaByDetailsQuery({
    phoneNumber: remove_character(
      JSON.parse(localStorage.getItem("phoneNumber")),
      4
    ),
    authString:
      window.location.hostname === "scouts-web-app-c7ezmxy3cq-uc.a.run.app" ||
      window.location.hostname === "localhost"
        ? "55ad4986-b519-4e44-ab0b-890527299af6"
        : "b8ba31ef-4570-44ff-95af-e80eddf98f82",
    mediaType: "audio",
  });

  const toggleTab = async (index) => {
    setToggleRequestTabs(index);
    if (index === 1) {
    }
    if (index === 2) {
    }
    if (index === 3) {
    }
    if (index === 4) {
    }
  };

  useEffect(() => {
    let approved = pendingRequests?.responses?.filter(
      (e) => e.status === "approved"
    );
    let rejected = pendingRequests?.responses?.filter(
      (e) => e.status === "rejected"
    );
    setCompleted(approved?.length + rejected?.length);
  }, [pendingRequests?.responses]);

  return (
    <div>
      <div className="grid-3-columns">
        <div id="w-node-_050d8f6d-c27f-b44d-0d25-58000ceda8cc-464ea933">
          <div className="flex-space-between">
            <h5 className="text-color-4 text-medium">Available requests</h5>
            <p className="text-color-2">{completed} Completed</p>
          </div>
          <div
            data-duration-in="300"
            data-duration-out="100"
            className="w-tabs"
          >
            <div className="page-tabs _2 w-tab-menu" role="tablist">
              <a
                className={
                  toggleRequestTabs === 1
                    ? "tab _2 w-inline-block w-tab-link w--current"
                    : "tab _2 w-inline-block w-tab-link"
                }
                id="w-tabs-1-data-w-tab-0"
                href="#!"
                onClick={() => toggleTab(1)}
              >
                <div>All</div>
              </a>
              <a
                data-w-tab="Tab 2"
                className={
                  toggleRequestTabs === 2
                    ? "tab _2 w-inline-block w-tab-link w--current"
                    : "tab _2 w-inline-block w-tab-link"
                }
                id="w-tabs-1-data-w-tab-1"
                href="#!"
                onClick={() => toggleTab(2)}
              >
                <div>Photo</div>
              </a>
              <a
                data-w-tab="Tab 3"
                className={
                  toggleRequestTabs === 3
                    ? "tab _2 w-inline-block w-tab-link w--current"
                    : "tab _2 w-inline-block w-tab-link"
                }
                id="w-tabs-1-data-w-tab-2"
                href="#!"
                onClick={() => toggleTab(3)}
              >
                <div>Video</div>
              </a>
              <a
                data-w-tab="Tab 4"
                className={
                  toggleRequestTabs === 4
                    ? "tab _2 w-inline-block w-tab-link w--current"
                    : "tab _2 w-inline-block w-tab-link"
                }
                id="w-tabs-1-data-w-tab-3"
                href="#!"
                onClick={() => toggleTab(4)}
              >
                <div>Audio</div>
              </a>
            </div>
            <div className="tabs-content w-tab-content">
              <div
                className={
                  toggleRequestTabs === 1
                    ? "w-tab-pane w--tab-active"
                    : "w-tab-pane"
                }
                id="w-tabs-1-data-w-pane-0"
                style={{ opacity: "1", transition: "opacity 300ms ease 0s" }}
              >
                <div className="grid-2-columns">
                  {allRequests?.requests?.map((request, i) => (
                    <div
                      key={i}
                      className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between"
                    >
                      <h4 className="margin-0">{request.requestName}</h4>
                      <div className="flex-align-center margin-top-6 flex-space-between portrait-flex-column portrait-flex-align-start">
                        <div className="flex-align-center">
                          <div className="flex-align-center margin-right-4">
                            {request.mediaType === "video" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-video icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect
                                  x="1"
                                  y="5"
                                  width="15"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            ) : request.mediaType === "photo" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-camera icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                <circle cx="12" cy="13" r="4"></circle>
                              </svg>
                            ) : request.mediaType === "audio" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-mic icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            ) : null}
                          </div>
                        </div>
                        <Link
                          className="button button-small portrait-margin-top-4 w-button"
                          to={{
                            pathname: "/requestStart",
                            state: { request },
                          }}
                        >
                          Start
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right icon margin-left-1"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                id="w-tabs-1-data-w-pane-1"
                className={
                  toggleRequestTabs === 2
                    ? "w-tab-pane w--tab-active"
                    : "w-tab-pane"
                }
              >
                <div className="grid-2-columns">
                  {photoRequests?.requests?.map((request, i) => (
                    <div
                      key={i}
                      className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between"
                    >
                      <h4 className="margin-0">{request.requestName}</h4>
                      <div className="flex-align-center margin-top-6 flex-space-between portrait-flex-column portrait-flex-align-start">
                        <div className="flex-align-center">
                          <div className="flex-align-center margin-right-4">
                            {request.mediaType === "video" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-video icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect
                                  x="1"
                                  y="5"
                                  width="15"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            ) : request.mediaType === "photo" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-camera icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                <circle cx="12" cy="13" r="4"></circle>
                              </svg>
                            ) : request.mediaType === "audio" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-mic icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            ) : null}
                          </div>
                        </div>
                        <Link
                          className="button button-small portrait-margin-top-4 w-button"
                          to={{
                            pathname: "/requestStart",
                            state: { request },
                          }}
                        >
                          Start
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right icon margin-left-1"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                id="w-tabs-1-data-w-pane-2"
                className={
                  toggleRequestTabs === 3
                    ? "w-tab-pane w--tab-active"
                    : "w-tab-pane"
                }
              >
                <div className="grid-2-columns">
                  {videoRequests?.requests?.map((request, i) => (
                    <div
                      key={i}
                      className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between"
                    >
                      <h4 className="margin-0">{request.requestName}</h4>
                      <div className="flex-align-center margin-top-6 flex-space-between portrait-flex-column portrait-flex-align-start">
                        <div className="flex-align-center">
                          <div className="flex-align-center margin-right-4">
                            {request.mediaType === "video" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-video icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect
                                  x="1"
                                  y="5"
                                  width="15"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            ) : request.mediaType === "photo" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-camera icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                <circle cx="12" cy="13" r="4"></circle>
                              </svg>
                            ) : request.mediaType === "audio" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-mic icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            ) : null}
                          </div>
                        </div>
                        <Link
                          className="button button-small portrait-margin-top-4 w-button"
                          to={{
                            pathname: "/requestStart",
                            state: { request },
                          }}
                        >
                          Start
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right icon margin-left-1"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                id="w-tabs-1-data-w-pane-3"
                className={
                  toggleRequestTabs === 4
                    ? "w-tab-pane w--tab-active"
                    : "w-tab-pane"
                }
              >
                <div className="grid-2-columns">
                  {audioRequests?.requests?.map((request, i) => (
                    <div
                      key={i}
                      className="radius-large background-color-white box-shadow-large padding-6 border-1px flex-column flex-space-between"
                    >
                      <h4 className="margin-0">{request.requestName}</h4>
                      <div className="flex-align-center margin-top-6 flex-space-between portrait-flex-column portrait-flex-align-start">
                        <div className="flex-align-center">
                          <div className="flex-align-center margin-right-4">
                            {request.mediaType === "video" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-video icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                                <rect
                                  x="1"
                                  y="5"
                                  width="15"
                                  height="14"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            ) : request.mediaType === "photo" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-camera icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                <circle cx="12" cy="13" r="4"></circle>
                              </svg>
                            ) : request.mediaType === "audio" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-mic icon text-color-3 margin-right-1"
                                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                                loading="lazy"
                                alt=""
                              >
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                              </svg>
                            ) : null}
                          </div>
                        </div>
                        <Link
                          className="button button-small portrait-margin-top-4 w-button"
                          to={{
                            pathname: "/requestStart",
                            state: { request },
                          }}
                        >
                          Start
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right icon margin-left-1"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="w-node-_99b8939e-d09a-891d-5c40-2d4fc1fecf0b-464ea933">
          <div className="flex-space-between">
            <h5 className="text-color-4 text-medium portrait-text-color-headings">
              Your Responses
            </h5>
            <p className="text-color-2">
              {
                pendingRequests?.responses?.filter(
                  (x) => x.status === "pending"
                ).length
              }{" "}
              Pending
            </p>
          </div>
          {pendingRequests?.responses?.map((x) => (
            <div
              key={x.responseRef}
              className="background-color-1 radius-large flex-column"
            >
              <Link
                className="w-inline-block"
                to={{
                  pathname:
                    x?.status === "pending"
                      ? "/pendingResponse"
                      : x?.status === "rejected"
                      ? "/rejectedResponse"
                      : x?.status === "approved"
                      ? "/approvedResponse"
                      : null,
                  state: { x },
                }}
              >
                <div className="padding-6 border-bottom-1px">
                  <div className="flex-space-between">
                    <div>
                      <h5 className="margin-0 margin-right-4">
                        {x?.requestName}
                      </h5>
                      <p className="text-color-2 margin-bottom-0 margin-top-2">
                        Submitted{" "}
                        {new Intl.DateTimeFormat("en-GB", {
                          month: "long",
                          day: "2-digit",
                          year: "numeric",
                        }).format(new Date(x?.createdAt))}
                      </p>
                    </div>
                    {x?.status}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Requests;
