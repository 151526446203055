export const DemographicData = [
  {
    id: 1,
    scoutFieldUpdate: "childrenInHousehold",
    questionRef: "0e2eb846-41f9-4745-af85-6d0a9951ecdc",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question:
      "How many people, including you, are below the age of 18 in your household?",
    campaignName: "Children In Household",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Below 3",
        payload: "below_3",
      },
      {
        id: 2,
        value: "3 to 5",
        payload: "3_to_5",
      },
      {
        id: 3,
        value: "5 to 10",
        payload: "5_to_10",
      },
      {
        id: 4,
        value: "Above 10",
        payload: "above_10",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 2,
    scoutFieldUpdate: "householdMonthlyIncome",
    questionRef: "f76fe3a9-dbc0-48bc-be61-965967b571b1",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your total monthly household income? (USD)",
    campaignName: "Household Monthly Income",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Below 100",
        payload: "below_100_USD",
      },
      {
        id: 2,
        value: "100 to 500",
        payload: "100_to_500_USD",
      },
      {
        id: 3,
        value: "500 to 1000",
        payload: "500_to_1000_USD",
      },
      {
        id: 4,
        value: "Above 1000",
        payload: "above_1000_USD",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 3,
    scoutFieldUpdate: "religion",
    questionRef: "0c4c61c3-dae8-4d84-b4e7-1c1768bb2b41",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your religion?",
    campaignName: "Religion",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Atheist (do not believe in God)",
        payload: "Atheist (do not believe in God)",
      },
      {
        id: 2,
        value: "Catholic",
        payload: "Catholic",
      },
      {
        id: 3,
        value: "Hindu",
        payload: "Hindu",
      },
      {
        id: 4,
        value: "Muslim",
        payload: "Muslim",
      },
      {
        id: 5,
        value: "Protestant (Anglican Baptist)",
        payload: "Protestant (Anglican Baptist)",
      },
      {
        id: 6,
        value: "Christian Orthodox",
        payload: "Christian Orthodox",
      },
      {
        id: 7,
        value: "Buddhist",
        payload: "Buddhist",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 4,
    scoutFieldUpdate: "employment",
    questionRef: "36ecaa16-4fe2-441b-9f84-508e1dec38f9",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What's your employment status?",
    campaignName: "Employment",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Part time employed",
        payload: "Partime employed",
      },
      {
        id: 2,
        value: "A student",
        payload: "A student",
      },
      {
        id: 3,
        value: "A homemaker",
        payload: "A homemaker",
      },
      {
        id: 4,
        value: "Unable to work",
        payload: "Unable to work",
      },
      {
        id: 5,
        value: "Military",
        payload: "Military",
      },
      {
        id: 6,
        value: "Unemployed",
        payload: "Unemployed",
      },
      {
        id: 7,
        value: "Retired",
        payload: "Retired",
      },
      {
        id: 8,
        value: "Fully employed",
        payload: "Fully employed",
      },
      {
        id: 9,
        value: "Self-employed",
        payload: "Self-employed",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 5,
    scoutFieldUpdate: "industryAffiliation",
    questionRef: "89b16d1e-938f-4250-a67c-5f84785c5396",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question:
      "What are your interests or industry affiliations? Select all that apply",
    campaignName: "Industry Affiliation",
    clientRe: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Agriculture",
      },
      {
        id: 2,
        value: "Apparel",
      },
      {
        id: 3,
        value: "A homemaker",
      },
      {
        id: 4,
        value: "Banking",
      },
      {
        id: 5,
        value: "Biotechnology",
      },
      {
        id: 6,
        value: "Chemicals",
      },
      {
        id: 7,
        value: "Communications",
      },
      {
        id: 8,
        value: "Construction",
      },
      {
        id: 9,
        value: "Consulting",
      },
      {
        id: 10,
        value: "Cryptocurrency",
      },
      {
        id: 11,
        value: "Education",
      },
      {
        id: 12,
        value: "Education (Student)",
      },
      {
        id: 13,
        value: "Electronics",
      },
      {
        id: 14,
        value: "Energy",
      },
      {
        id: 15,
        value: "Engineering",
      },
      {
        id: 16,
        value: "Entertainment",
      },
      {
        id: 17,
        value: "Environmental",
      },
      {
        id: 18,
        value: "Fashion",
      },
      {
        id: 19,
        value: "Finance",
      },
      {
        id: 20,
        value: "Food & Beverage",
      },
      {
        id: 21,
        value: "Government",
      },
      {
        id: 22,
        value: "Healthcare",
      },
      {
        id: 23,
        value: "Hospitality",
      },
      {
        id: 24,
        value: "Insurance",
      },
      {
        id: 25,
        value: "Machinery",
      },
      {
        id: 26,
        value: "Manufacturing",
      },
      {
        id: 27,
        value: "Media",
      },
      {
        id: 28,
        value: "Non Profit",
      },
      {
        id: 29,
        value: "Oil & Gas",
      },
      {
        id: 30,
        value: "Real Estate",
      },
      {
        id: 31,
        value: "Recreation",
      },
      {
        id: 32,
        value: "Retail",
      },
      {
        id: 33,
        value: "Shipping",
      },
      {
        id: 34,
        value: "Sports",
      },
      {
        id: 35,
        value: "Technology (Software)",
      },
      {
        id: 36,
        value: "Technology (Other)",
      },
      {
        id: 37,
        value: "Telecommunications",
      },
      {
        id: 38,
        value: "Transportation",
      },
      {
        id: 39,
        value: "Utilities",
      },
      {
        id: 40,
        value: "Other",
      },
    ],
    unit: "multi_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 6,
    scoutFieldUpdate: "personalMonthlyIncome",
    questionRef: "6caf46da-7c68-45a2-8635-fb2cf73563a3",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your personal monthly income? (USD)",
    campaignName: "Personal Monthly Income",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Below 100",
        payload: "below_100_USD",
      },
      {
        id: 2,
        value: "100 to 500",
        payload: "100_to_500_USD",
      },
      {
        id: 3,
        value: "500 to 1000",
        payload: "500_to_1000_USD",
      },
      {
        id: 4,
        value: "Above 1000",
        payload: "above_1000_USD",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 7,
    scoutFieldUpdate: "peopleInHousehold",
    questionRef: "5d226e3a-da1b-437e-a2c0-728f2cce3a02",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "How many people, including you, live in your household?",
    campaignName: "People In Household",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Below 3",
        payload: "below_3",
      },
      {
        id: 2,
        value: "3 to 5",
        payload: "3_to_5",
      },
      {
        id: 3,
        value: "5 to 10",
        payload: "5_to_10",
      },
      {
        id: 4,
        value: "Above 10",
        payload: "above_10",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 8,
    scoutFieldUpdate: "dob",
    questionRef: "a86fe3a9-dbc0-48bc-be61-965967b571b1",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question:
      "How old are you? Please click on the box below to insert Date of Birth (choose year first)",
    campaignName: "Date of Birth",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: ["Sun Jul 22 2018"],
    unit: "date",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 9,
    scoutFieldUpdate: "tribe",
    questionRef: "4c226e3a-da1b-437e-a2c0-728f2cce3a02",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What tribe are you from?",
    campaignName: "Tribe",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Chagga",
      },
      {
        id: 2,
        value: "Mijikenda",
      },
      {
        id: 3,
        value: "Kisii",
      },
      {
        id: 4,
        value: "Iraqw",
      },
      {
        id: 5,
        value: "Hadzabe",
      },
      {
        id: 6,
        value: "Mole-Dagbon",
      },
      {
        id: 7,
        value: "Maasai",
      },
      {
        id: 8,
        value: "Kalenjin",
      },
      {
        id: 9,
        value: "Fante",
      },
      {
        id: 10,
        value: "Luhya",
      },
      {
        id: 11,
        value: "Ewe",
      },
      {
        id: 12,
        value: "Igbo",
      },
      {
        id: 13,
        value: "Sukuma",
      },
      {
        id: 14,
        value: "Luo",
      },
      {
        id: 15,
        value: "Kikuyu",
      },
      {
        id: 16,
        value: "Ashanti",
      },
      {
        id: 17,
        value: "Hausa",
      },
      {
        id: 18,
        value: "Other",
      },
      {
        id: 19,
        value: "Yoruba",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 10,
    scoutFieldUpdate: "education",
    questionRef: "9d2eb846-41f9-4745-af85-6d0a9951ecdc",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your highest education level?",
    campaignName: "Education",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Doctorate degree",
      },
      {
        id: 2,
        value: "Professional degree",
      },
      {
        id: 3,
        value: "No schooling completed",
      },
      {
        id: 4,
        value:
          "High school graduate diploma or the equivalent (for example: GED)",
      },
      {
        id: 5,
        value: "Some high school no diploma",
      },
      {
        id: 6,
        value: "Some college credit no degree",
      },
      {
        id: 7,
        value: "Nursery school to 8th grade",
      },
      {
        id: 8,
        value: "Bachelor's degree",
      },
      {
        id: 9,
        value: "Master's degree",
      },
      {
        id: 10,
        value: "Trade/technical/vocational training",
      },
      {
        id: 11,
        value: "Associate degree",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },

  {
    id: 11,
    scoutFieldUpdate: "gender",
    questionRef: "5baf46da-7c68-45a2-8635-fb2cf73563a3",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your gender?",
    campaignName: "Gender",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Female",
      },
      {
        id: 2,
        value: "Male",
      },
      {
        id: 3,
        value: "Other",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },

  {
    id: 12,
    scoutFieldUpdate: "maritalStatus",
    questionRef: "90b16d1e-938f-4250-a67c-5f84785c5396",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "What is your marital status?",
    campaignName: "Marital status",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Single never married",
        payload: "Single never married",
      },
      {
        id: 2,
        value: "Divorced",
        payload: "divorced",
      },
      {
        id: 3,
        value: "Married or domestic partnership",
        payload: "Married or domestic partnership",
      },
      {
        id: 4,
        value: "Widowed",
        payload: "Widowed",
      },
      {
        id: 5,
        value: "Separated",
        payload: "Separated",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },

  {
    id: 13,
    scoutFieldUpdate: "sportsFan",
    questionRef: "91b16d1e-938f-4250-a67c-5f84785c5387",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "Are you a sports fan?",
    campaignName: "Sports Fan",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Yes",
      },
      {
        id: 2,
        value: "No",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },

  {
    id: 14,
    scoutFieldUpdate: "soccerFan",
    questionRef: "94b16d1e-938f-4250-a67c-5f84785c5364",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "Are you a soccer/football fan?",
    campaignName: "Soccer Fan",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Yes",
      },
      {
        id: 2,
        value: "No",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
  {
    id: 15,
    scoutFieldUpdate: "favouriteTeam",
    questionRef: "94b16d1e-938f-4250-a67c-6f84785c5374",
    campaignRef: "a0ad177c-a6d0-44a4-8662-7f2851093b81",
    question: "Which among the teams is your most favourite",
    campaignName: "Favourite Team",
    clientRef: "eebbdfdb-b540-4536-a29b-5e45aada3435",
    options: [
      {
        id: 1,
        value: "Chelsea F.C",
        payload: "1",
      },
      {
        id: 2,
        value: "Liverpool F.C",
        payload: "2",
      },
      {
        id: 3,
        value: "Tottenham F.C",
        payload: "3",
      },
      {
        id: 4,
        value: "Arsenal F.C",
        payload: "4",
      },
      {
        id: 5,
        value: "Manchester United F.C",
        payload: "5",
      },
      {
        id: 6,
        value: "Manchester City F.C",
        payload: "6",
      },
      {
        id: 7,
        value: "Other",
        payload: "7",
      },
      {
        id: 8,
        value: "I am not a football fan",
        payload: "8",
      },
    ],
    unit: "single_select",
    type: "multiplechoice",
    button: ["confirm"],
  },
];
